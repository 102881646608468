import ISearch from "../types/search";

const days = {
    1: "st",
    2: "nd",
    3: "rd",
    4: "th",
    5: "th",
    6: "th",
    7: "th",
    8: "th",
    9: "th",
    10: "th",
    11: "th",
    12: "th",
    13: "th",
    14: "th",
    15: "th",
    16: "th",
    17: "th",
    18: "th",
    19: "th",
    20: "th",
    21: "st",
    22: "nd",
    23: "rd",
    24: "th",
    25: "th",
    26: "th",
    27: "th",
    28: "th",
    29: "th",
    30: "th",
    31: "st",
};
// const weekDays = [
//   "Sunday",
//   "Monday",
//   "Tuesday",
//   "Wednesday",
//   "Thursday",
//   "Friday",
//   "Saturday"
// ];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const sameDay = (d1: Date, d2: Date) => {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};

export const newDateAtMidnight = (): Date => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return createDateAsUTC(date);
};

export const formatDate = (date?: Date | string, options?: { enhancedFormatting?: boolean }) => {
    if (!date) {
        return "";
    }

    const dateParsed = typeof date === "string" ? new Date(date) : date;
    const dateParsedUTC = convertDateToUTC(dateParsed);

    const day = dateParsedUTC.getDate();
    const month = dateParsedUTC.getMonth();
    const year = dateParsedUTC.getFullYear();

    if (options?.enhancedFormatting) {
        return `${day}${days[day]} of ${months[month]} ${year}`;
    }

    return `${day}${days[day]} ${months[month]} ${year}`;
};

export const createDateAsUTC = (date: Date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
};

export const convertDateToUTC = (date: Date) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const yyyymmdd = (date: Date) => {
    if (!date) {
        return "";
    }

    const twoDigit = (n: number) => (n < 10 ? `0${n}` : n);

    return `${date.getFullYear()}-${twoDigit(date.getMonth() + 1)}-${twoDigit(date.getDate())}`;
};

export const yymmdd = (date: Date) => {
    if (!date) {
        return "";
    }

    const twoDigit = (n: number) => (n < 10 ? `0${n}` : n);

    return `${date.getFullYear().toString().substring(2)}-${twoDigit(date.getMonth() + 1)}-${twoDigit(date.getDate())}`;
};

export const ddmmyy = (date: Date) => {
    if (!date) {
        return "";
    }

    const twoDigit = (n: number) => (n < 10 ? `0${n}` : n);

    return `${twoDigit(date.getDate())}-${twoDigit(date.getMonth() + 1)}-${date.getFullYear().toString().substring(2)}`;
};

export const yyyymmddhhmm = (date: Date) => {
    if (!date) {
        return "";
    }

    const dateToUTC = convertDateToUTC(date);

    const twoDigit = (n: number) => (n < 10 ? `0${n}` : n);

    return `${dateToUTC.getFullYear()}-${twoDigit(dateToUTC.getMonth() + 1)}-${twoDigit(dateToUTC.getDate())} ${twoDigit(dateToUTC.getHours())}:${twoDigit(dateToUTC.getMinutes())}`;
};

export const addDays = (date: Date, numDays: number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return newDate;
};

export const filterDate = (propName: string, search: ISearch) => {
    const tomorrow = addDays(new Date(), 1);
    const oldestDate = new Date("2000-01-01");
    const dateName = propName;
    let toDate = "";

    if (search.toDate) {
        const { 0: year, 1: month, 2: day } = search.toDate.split("-");
        const dayPlusOne = +day + 1;
        toDate = `${year}-${month}-${dayPlusOne}`;
    }

    if (search.fromDate && toDate) {
        return {
            name: dateName,
            value: `${search.fromDate}~${toDate}`,
        };
    }

    if (search.fromDate && !toDate) {
        return {
            name: dateName,
            value: `${search.fromDate}~${yyyymmdd(tomorrow)}`,
        };
    }

    if (!search.fromDate && toDate) {
        return {
            name: dateName,
            value: `${yyyymmdd(oldestDate)}~${toDate}`,
        };
    }

    return null;
};

export const daysAgo = (date: Date) => {
    const now = new Date().getTime();
    const previous = date.getTime();

    return Math.round((now - previous) / 86400000);
};
