import * as React from "react";

// @ts-ignore
import { convertFromHTML } from "draft-convert";
import { Editor, EditorState, Modifier, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import * as _ from "lodash";

import "../styles/RichInputTextV2.css";
import { EditorButtons, EditorWrapper } from "./RichInputText/styles";

interface IProp {
    className?: string;
    onChange: (text: string) => void;
    initialValue?: string;
}

interface IState {
    input: EditorState;
    isInputFocused: boolean;
}

export class RichInputTextV2 extends React.Component<IProp, IState> {
    private debounceOnChange: (text: string) => void;

    constructor(props: IProp) {
        super(props);

        this.state = { input: EditorState.createEmpty(), isInputFocused: false };

        this.debounceOnChange = _.debounce(this.props.onChange, 1500);
    }

    setInputFocus = () => {
        this.setState({ isInputFocused: true });
    };

    removeInputFocus = () => {
        this.setState({ isInputFocused: false });
    };

    public onChange = (input: EditorState) => {
        const currentContentState = this.state.input.getCurrentContent();
        const newContentState = input.getCurrentContent();

        if (currentContentState !== newContentState) {
            this.debounceOnChange(stateToHTML(input.getCurrentContent()));
        }

        this.setState({ input });
    };

    public handleKeyCommand = (command: string, input: EditorState) => {
        const newState = RichUtils.handleKeyCommand(input, command);
        if (newState) {
            this.onChange(newState);
            return "handled";
        }
        return "not-handled";
    };

    public onMouseDownStyle = (action: string) => (e: any) => {
        e.preventDefault();

        const input = RichUtils.toggleInlineStyle(this.state.input, action);

        this.setState({ input });

        this.debounceOnChange(stateToHTML(input.getCurrentContent()));
    };

    public onClickStyle = (e: any) => {
        e.preventDefault();
    };

    public componentDidMount = () => {
        const text = _.chain(this.props.initialValue)
            .replace(/\n<p><br><\/p>/g, "<p></p>")
            .replace(/\n\n/g, "<p></p>")
            .value();

        const blocksFromHTML = convertFromHTML(text);
        const input = EditorState.createWithContent(blocksFromHTML);

        this.setState({ input });
        this.debounceOnChange(text);
    };

    public handlePastedText = (text: string) => {
        const newContent = Modifier.replaceText(this.state.input.getCurrentContent(), this.state.input.getSelection(), text);

        this.onChange(EditorState.push(this.state.input, newContent, "insert-characters"));

        return true;
    };

    public render() {
        const { className } = this.props;
        let extraProps = {};

        if (className) {
            extraProps = { ...extraProps, blockStyleFn: () => className };
        }

        return (
            <>
                <EditorButtons>
                    <button onMouseDown={this.onMouseDownStyle("UNDERLINE")} onClick={this.onClickStyle} name="UNDERLINE">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" fill="white" fill-opacity="0.01" />
                            <path
                                d="M7 7C7 6.73478 7.10536 6.48043 7.29289 6.29289C7.48043 6.10536 7.73478 6 8 6C8.26522 6 8.51957 6.10536 8.70711 6.29289C8.89464 6.48043 9 6.73478 9 7V11C9 12.884 9.93 14 12 14C14.07 14 15 12.884 15 11V7C15 6.73478 15.1054 6.48043 15.2929 6.29289C15.4804 6.10536 15.7348 6 16 6C16.2652 6 16.5196 6.10536 16.7071 6.29289C16.8946 6.48043 17 6.73478 17 7V11C17 13.916 15.263 16 12 16C8.737 16 7 13.916 7 11V7ZM7 17H17C17.2652 17 17.5196 17.1054 17.7071 17.2929C17.8946 17.4804 18 17.7348 18 18C18 18.2652 17.8946 18.5196 17.7071 18.7071C17.5196 18.8946 17.2652 19 17 19H7C6.73478 19 6.48043 18.8946 6.29289 18.7071C6.10536 18.5196 6 18.2652 6 18C6 17.7348 6.10536 17.4804 6.29289 17.2929C6.48043 17.1054 6.73478 17 7 17Z"
                                fill="#42526E"
                            />
                        </svg>
                    </button>
                    <button onMouseDown={this.onMouseDownStyle("BOLD")} onClick={this.onClickStyle} name="BOLD">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" fill="white" fill-opacity="0.01" />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8 6H12.832C13.908 6 16 6.5 16 9C16 10.333 15.667 11.167 15 11.5C16.333 11.833 17 12.833 17 14.5C17 15 17 18 13 18H8C7.73478 18 7.48043 17.8946 7.29289 17.7071C7.10536 17.5196 7 17.2652 7 17V7C7 6.73478 7.10536 6.48043 7.29289 6.29289C7.48043 6.10536 7.73478 6 8 6ZM9 16H12.5C13.5 16 14.5 15.75 14.5 14.5C14.5 13.25 13.396 13 12.5 13H9V16ZM9 11.025H12C12.504 11.025 14 11.025 14 9.5C14 7.975 12 8 12 8H9V11.025Z"
                                fill="#42526E"
                            />
                        </svg>
                    </button>
                    <button onMouseDown={this.onMouseDownStyle("ITALIC")} onClick={this.onClickStyle} name="ITALIC">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" fill="white" fill-opacity="0.01" />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M10 6H16C16.2652 6 16.5196 6.10536 16.7071 6.29289C16.8946 6.48043 17 6.73478 17 7C17 7.26522 16.8946 7.51957 16.7071 7.70711C16.5196 7.89464 16.2652 8 16 8H10C9.73478 8 9.48043 7.89464 9.29289 7.70711C9.10536 7.51957 9 7.26522 9 7C9 6.73478 9.10536 6.48043 9.29289 6.29289C9.48043 6.10536 9.73478 6 10 6ZM8 16H14C14.2652 16 14.5196 16.1054 14.7071 16.2929C14.8946 16.4804 15 16.7348 15 17C15 17.2652 14.8946 17.5196 14.7071 17.7071C14.5196 17.8946 14.2652 18 14 18H8C7.73478 18 7.48043 17.8946 7.29289 17.7071C7.10536 17.5196 7 17.2652 7 17C7 16.7348 7.10536 16.4804 7.29289 16.2929C7.48043 16.1054 7.73478 16 8 16ZM12 8H14L12 16H10L12 8Z"
                                fill="#42526E"
                            />
                        </svg>
                    </button>
                </EditorButtons>
                <EditorWrapper isFocused={this.state.isInputFocused}>
                    <Editor
                        onFocus={this.setInputFocus}
                        onBlur={this.removeInputFocus}
                        editorState={this.state.input}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={this.onChange}
                        // @ts-ignore
                        handlePastedText={this.handlePastedText}
                        {...extraProps}
                    />
                </EditorWrapper>
            </>
        );
    }
}

export default RichInputTextV2;
