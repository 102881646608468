import { IELVoice } from "src/types/eleven-labs";
import * as errors from "../types/errors";

import { FETCH_EL_VOICES_REQUEST, FETCH_EL_VOICES_SUCCESS, FETCH_EL_VOICES_FAILURE } from "src/constants/elevenlabs";

export interface IState {
    voices: IELVoice[];
    isLoading: boolean;
    error?: errors.HttpError;
}

export interface IAction {
    type: string;
    voices?: IELVoice[];
    error?: errors.HttpError;
}

export const initialState: IState = {
    voices: [],
    isLoading: false,
};

export const reducer = (state: IState = initialState, action: IAction): IState => {
    switch (action.type) {
        case FETCH_EL_VOICES_REQUEST:
            return { ...state, isLoading: true, error: undefined };

        case FETCH_EL_VOICES_SUCCESS:
            return {
                ...state,
                voices: action.voices || [],
                isLoading: false,
            };
        case FETCH_EL_VOICES_FAILURE:
            return { ...state, isLoading: false, error: action.error };

        default:
            return state;
    }
};
