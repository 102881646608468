import { styled } from "styled-components";

export const ButtonV2 = styled.button<{ isRed?: boolean; isGrey?: boolean; setMarginTop?: boolean; setSmallMarginTop?: boolean }>`
    font-size: 14px;
    font-family: Poppins, sans-serif;
    color: ${(p) => (p.disabled ? "#6B778C" : p.isGrey ? "#5d6675" : "#fff")};
    font-weight: 500;
    background: ${(p) => (p.disabled || p.isGrey ? "#DFE1E6" : p.isRed ? "#DE350B" : "#007fbc")};
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 3px;

    margin-top: ${(p) => (p.setSmallMarginTop ? "10px" : p.setMarginTop ? "24px" : "0px")};

    display: block;

    transition: 0.1s ease filter;

    &:focus {
        background: ${(p) => (p.isRed ? "#DE350B" : p.isGrey ? "#DFE1E6" : "#007fbc")} !important;
    }

    &:hover {
        filter: ${(p) => (p.disabled ? "brightness(1)" : "brightness(0.9)")};
    }

    &:active {
        filter: ${(p) => (p.disabled ? "brightness(1)" : "brightness(1.1)")};
    }
`;
