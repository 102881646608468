import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import IPronunciation from "../../types/pronunciation";
import IUser from "../../types/user";
import { Clickable, ModalConfirm, Modal } from "styleguide/build";
import { deletePronunciation, fetchPronunciations, editPronunciation } from "../../actions/pronunciation";
import ISearch from "../../types/search";
import UserRoles from "../../types/user-roles";
import PronunciationForm from "../PronunciationForm";

interface IProps {
    user?: IUser;
    pronunciation: IPronunciation;
    search?: ISearch;
    history?: any;
    deletePronunciation: typeof deletePronunciation;
    fetchPronunciations?: typeof fetchPronunciations;
    editPronunciation: typeof editPronunciation;
}

interface IState {
    editModalOpen: boolean;
}

export class PronunciationEditButtons extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { editModalOpen: false };
    }

    public delete = (pronunciation: IPronunciation) => {
        this.props.deletePronunciation(pronunciation);
    };

    public showEditModal = () => {
        this.setState({ editModalOpen: true });
    };

    public close = () => {
        this.setState({ editModalOpen: false });
    };

    // Added '| any' to type to avoid compiler error.
    public submitForm = (pronunciation: IPronunciation | any) => {
        this.props.editPronunciation(pronunciation, this.props.user);
        this.close();
    };

    public onError = () => {
        // this.props.error
    };

    public render = () => {
        const { pronunciation, user } = this.props;

        if (!user || (user.userRoleIdList !== UserRoles.EDITOR && user.userRoleIdList !== UserRoles.SUPER_USER)) {
            return null;
        }

        const params = {
            initialValues: { ...pronunciation, error: this.onError },
            onSubmit: this.submitForm,
            pronunciation,
        };

        return (
            <>
                {this.state.editModalOpen && (
                    <Modal title="Edit Pronunciation" className={css(styles.modal)} close={this.close}>
                        <PronunciationForm {...params} />
                    </Modal>
                )}
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Clickable
                        className={css(styles.icon)}
                        // tslint:disable-next-line: jsx-no-lambda
                        onClick={this.showEditModal}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" fill="white" fill-opacity="0.01" />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M15.148 6.85196C15.2426 6.75894 15.3702 6.70714 15.5028 6.70789C15.6355 6.70864 15.7625 6.76188 15.856 6.85596L18.144 9.14396C18.1908 9.19015 18.2281 9.24511 18.2536 9.30572C18.2792 9.36632 18.2925 9.43137 18.2929 9.49714C18.2933 9.5629 18.2807 9.6281 18.2558 9.68898C18.2309 9.74987 18.1943 9.80526 18.148 9.85196L11 17L8 14L15.148 6.85196ZM7 15L10 18L6.49 18.877C6.22 18.945 6.054 18.785 6.123 18.51L7 15Z"
                                fill="#42526E"
                            />
                        </svg>
                    </Clickable>
                    <ModalConfirm title="Confirmation" description="Are you sure that you want delete this pronunciation?">
                        {(confirm: any) => (
                            <Clickable className={css(styles.icon)} onClick={confirm(() => this.delete(pronunciation))}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" fill="white" fill-opacity="0.01" />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7 7H17C17.2652 7 17.5196 7.10536 17.7071 7.29289C17.8946 7.48043 18 7.73478 18 8C18 8.26522 17.8946 8.51957 17.7071 8.70711C17.5196 8.89464 17.2652 9 17 9H7C6.73478 9 6.48043 8.89464 6.29289 8.70711C6.10536 8.51957 6 8.26522 6 8C6 7.73478 6.10536 7.48043 6.29289 7.29289C6.48043 7.10536 6.73478 7 7 7ZM9.78 18C9.55707 18 9.34054 17.9255 9.16479 17.7883C8.98905 17.6512 8.86417 17.4592 8.81 17.243L7.156 10.62C7.13782 10.5465 7.13659 10.4697 7.15239 10.3957C7.1682 10.3216 7.20064 10.252 7.24726 10.1923C7.29387 10.1326 7.35345 10.0843 7.42149 10.0509C7.48953 10.0176 7.56424 10.0002 7.64 10H16.36C16.4359 10 16.5109 10.0173 16.5791 10.0507C16.6474 10.084 16.7072 10.1324 16.7539 10.1922C16.8007 10.2521 16.8332 10.3218 16.8489 10.3961C16.8647 10.4704 16.8634 10.5473 16.845 10.621L15.189 17.243C15.1348 17.4592 15.01 17.6512 14.8342 17.7883C14.6585 17.9255 14.4419 18 14.219 18H9.781H9.78ZM11 6H13C13.2652 6 13.5196 6.10536 13.7071 6.29289C13.8946 6.48043 14 6.73478 14 7H10C10 6.73478 10.1054 6.48043 10.2929 6.29289C10.4804 6.10536 10.7348 6 11 6Z"
                                        fill="#DE350B"
                                    />
                                </svg>
                            </Clickable>
                        )}
                    </ModalConfirm>
                </div>
            </>
        );
    };
}

const styles = StyleSheet.create({
    icon: {
        paddingLeft: 5,
        paddingRight: 5,
    },
    modal: {
        overflow: "initial",
    },
});

export default PronunciationEditButtons;
