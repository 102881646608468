import { styled } from "styled-components";

export const ItemHeading = styled.p<{ setMarginTop?: boolean }>`
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    color: #6b778c;
    margin-bottom: 5px;
    margin-left: 2px;

    margin-top: ${(p) => (p.setMarginTop ? "24px" : "0")};
`;
