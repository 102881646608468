import { FC, useState } from "react";

import { fetchArticleNoRedux, submitAudioToExternalPublisherArticle } from "src/actions/article";
import toast from "react-hot-toast";
import IArticle from "src/types/article";
import { Input } from "src/styles/Input";
import { ButtonV2 } from "src/styles/ButtonV2";
import { ItemHeading } from "src/styles/ItemHeading";
import { TextButton } from "src/styles/TextButton";

const ManualSubmission: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmissionFailed, setIsSubmissionFailed] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [article, setArticle] = useState<IArticle | null>(null);
    const [articleId, setArticleId] = useState("");

    const resetState = () => {
        setIsLoading(false);
        setIsSubmitting(false);
        setIsSubmissionFailed(false);
        setIsFailed(false);
        setArticle(null);
    };

    const isArticleId = () => {
        return !!articleId.trim().length;
    };

    const onArticleIdInputChange = (e: any) => {
        const value = e.target.value;

        setArticleId(value);
    };

    const getCurrentStatus = () => {
        if (isLoading) return { currentStatus: "Fetching article details...", color: "#f0a100" };
        else if (isSubmitting) return { currentStatus: "Submitting the audio...", color: "#f0a100" };
        else if (isFailed) return { currentStatus: "Failed to fetch, Check article ID.", color: "#DE350B" };
        else if (isSubmissionFailed) return { currentStatus: "Failed to submit the audio", color: "#DE350B" };

        return { currentStatus: "IDLE", color: "#007fbc" };
    };

    const onSubmitAudio = () => {
        const articleId = article?.articleID;

        if (!articleId || isSubmitting) return;

        setIsSubmitting(true);
        setIsSubmissionFailed(false);

        submitAudioToExternalPublisherArticle(articleId)
            .then(() => {
                toast.success("Audio submitted successfully.");

                resetState();
                setArticleId("");
            })
            .catch(() => {
                setIsSubmissionFailed(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const onFetchArticle = () => {
        if (!isArticleId()) return;

        const articleIdNumber = Number(articleId.trim());

        resetState();
        setIsLoading(true);

        fetchArticleNoRedux(articleIdNumber)
            .then((a: any) => (!!a ? setArticle(a) : setArticle(null)))
            .catch(() => setIsFailed(true))
            .finally(() => setIsLoading(false));
    };

    const { currentStatus, color: statusColor } = getCurrentStatus();

    return (
        <div style={{ marginBottom: "60px", fontFamily: "Poppins, sans-serif" }}>
            <ItemHeading style={{ fontSize: "16px", marginBottom: "10px", fontWeight: "500", color: "#505f79" }}>Manual Submission</ItemHeading>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "400px", marginBottom: "20px" }}>
                <Input value={articleId} type="number" placeholder="Article Id" onChange={onArticleIdInputChange} />
                <ButtonV2 style={{ width: "140px", height: "40px", marginLeft: "10px" }} disabled={!isArticleId() || isLoading || isSubmitting} onClick={onFetchArticle}>
                    Fetch
                </ButtonV2>
            </div>
            <ItemHeading style={{ marginTop: "8px", fontSize: "14px", color: "#505f79" }}>
                Status: <span style={{ fontWeight: 400, color: statusColor }}>{currentStatus}</span>
            </ItemHeading>
            {article && (
                <>
                    <ItemHeading style={{ marginTop: "8px", fontSize: "14px", color: "#505f79" }}>
                        Title: <span style={{ fontWeight: 400 }}>{article.articleName}</span>
                    </ItemHeading>
                    <ItemHeading style={{ marginTop: "8px", fontSize: "14px", color: "#505f79" }}>
                        Publisher: <span style={{ fontWeight: 400 }}>{article.newspaper?.newspaperName || "-"}</span>
                    </ItemHeading>
                    <ItemHeading style={{ marginTop: "8px", fontSize: "14px", color: "#505f79" }}>
                        Actions:{" "}
                        <TextButton style={isSubmitting ? { background: "none !important", color: "#505f79" } : {}} onClick={onSubmitAudio} disabled={isSubmitting}>
                            Submit Audio
                        </TextButton>
                    </ItemHeading>
                </>
            )}
        </div>
    );
};

export default ManualSubmission;
