import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import IPronunciation from "../../types/pronunciation";
import IUser from "../../types/user";
import { daysAgo } from "../../utils/date";
import UserRoles from "../../types/user-roles";
import { canVerifyPronuncation } from "../../utils/pronunciation-utils";
import { TextButton } from "src/styles/TextButton";
import { ButtonV2 } from "src/styles/ButtonV2";

interface IProps {
    user?: IUser;
    pronunciation: IPronunciation;
    verifyPronunciation: any;
}

export class PronunciationVerifTd extends React.Component<IProps> {
    public verifyPronunciation = (e: any) => {
        e.preventDefault();
        const { pronunciation, user } = this.props;

        if (pronunciation.verifiedAt) {
            return;
        }

        this.props.verifyPronunciation(pronunciation, user, null, true);
    };

    public render = () => {
        const { user, pronunciation } = this.props;

        if (user && (user.userRoleIdList === UserRoles.EDITOR || user.userRoleIdList === UserRoles.SUPER_USER)) {
            const canVerify = canVerifyPronuncation(pronunciation, user);

            /* tslint:disable-next-line: jsx-no-lambda*/
            return (
                <td className={css(styles.td)}>
                    {pronunciation.verifiedAt ? (
                        `${daysAgo(new Date(pronunciation.verifiedAt))} days ago`
                    ) : (
                        <TextButton onClick={this.verifyPronunciation} className={css(!canVerify && styles.unverifiableButton)}>
                            Verify
                        </TextButton>
                    )}
                </td>
            );
        }

        return <td>{pronunciation.verifiedAt ? `${daysAgo(new Date(pronunciation.verifiedAt))} days ago` : <ButtonV2 disabled={true}>Unverified</ButtonV2>}</td>;
    };
}

const styles = StyleSheet.create({
    unverifiableButton: {
        color: "#DFE1E6",
        filter: "brightness(0.8)",
    },
    td: {
        padding: 0,
        textAlign: "center",
        minWidth: "105px",
    },
});

export default PronunciationVerifTd;
