import { styled } from "styled-components";

export const DatePickerInputWrapper = styled.div`
    input {
        font-family: Poppins, sans-serif !important;
        border: 2px solid #dfe1e6 !important;
        font-size: 14px !important;
        color: #000 !important;
        height: 42px !important;
        border-radius: 3px !important;
        margin: 0 !important;

        box-sizing: border-box !important;

        padding: 0px 10px !important;

        &:focus {
            border-color: #039be5 !important;
            border-bottom-width: 2px !important;
        }

        &::placeholder {
            color: #7a869a;
            user-select: none;
        }
    }

    .react-datepicker-popper {
        z-index: 1000;
    }
`;
