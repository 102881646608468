import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Loading } from "styleguide";

import { fetchArticle } from "../actions/article";
import { fetchCategories } from "../actions/category";
import { fetchNewspaper, fetchNewspapers } from "../actions/newspaper";
import { fetchPlaylists } from "../actions/playlist";
import { fetchPronunciations, editPronunciation, deletePronunciation, verifyPronunciation } from "../actions/pronunciation";
import { fetchTemplates } from "../actions/template";
import { fetchUsers } from "../actions/user";
import ArticleScript from "../components/ArticleScript";
import { IState } from "../reducers";
import { createDefaultArticle, getCustomTemplate } from "../services/article";
import { requestPublishedCategories } from "../services/category";
import { requestPublishedPlaylists } from "../services/playlist";
import { requestNarrators } from "../services/user";
import IArticle from "../types/article";
import ICategory from "../types/category";
import * as errors from "../types/errors";
import IJournalist from "../types/journalist";
import INewspaper from "../types/newspaper";
import IPlaylist from "../types/playlist";
import IPronunciation from "../types/pronunciation";
import ITemplate from "../types/template";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";
import { correctValue } from "../utils/values";
import ISearch from "../types/search";
import { Helmet } from "react-helmet";
import ArticlePreviewText from "src/components/ArticlePreviewText";
import { formatDate } from "src/utils/date";
import TemplateType from "src/types/template-types";
import _ from "lodash";
import { ButtonV2 } from "src/styles/ButtonV2";

const ALL_PRONUNCIATIONS_LIMIT = 100000000;

interface IPropsFromState {
    article?: IArticle;
    error?: errors.HttpError;
    isLoading: boolean;
    user: IUser;
    newspapers: INewspaper[];
    categories: ICategory[];
    playlists: IPlaylist[];
    journalists: IJournalist[];
    templates: ITemplate[];
    narrators: IUser[];
    pronunciations: IPronunciation[];
    hasChanged: boolean;
    search: ISearch;
}

interface IPropsFromDispatch {
    fetchArticle: typeof fetchArticle;
    fetchNewspapers: typeof fetchNewspapers;
    fetchNewspaper: typeof fetchNewspaper;
    fetchCategories: typeof fetchCategories;
    fetchPlaylists: typeof fetchPlaylists;
    fetchUsers: typeof fetchUsers;
    fetchPronunciations: typeof fetchPronunciations;
    editPronunciation: typeof editPronunciation;
    verifyPronunciation: typeof verifyPronunciation;
    deletePronunciation: typeof deletePronunciation;
    fetchTemplates: typeof fetchTemplates;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public getJournalistsListAndNamesJoined = (): { journalists: IJournalist[]; text: string } => {
        const {
            article,
            journalists,
            user: { userRoleIdList: userRole },
        } = this.props;

        const dynamic = userRole !== UserRoles.NARRATOR;

        if (!article || !journalists) {
            return { journalists: [], text: "" };
        }

        if (!dynamic) {
            const jours = article.journalists || [];
            const journalistsLength = jours?.length || 0;

            return {
                journalists: jours,
                text: jours
                    .map((j) => j.journalistName)
                    .reduce((joined, journalist, currentIndex) => {
                        if (journalistsLength === 1 || currentIndex === journalistsLength - 1) return `${joined}${journalist}`;

                        if (journalistsLength === 2) {
                            return `${joined}${journalist} and `;
                        }

                        if (journalistsLength > 2 && currentIndex === journalistsLength - 2) {
                            return `${joined}${journalist}, and `;
                        }

                        return `${joined}${journalist}, `;
                    }, ""),
            };
        }

        const ownJournalistsId = article.journalistsId ? article.journalistsId : [];
        const journalistsFiltered = (journalists && journalists.filter((j) => !!ownJournalistsId.find((o) => +o === j.journalistId))) || [];

        const journalistsLength = journalistsFiltered.length;

        return {
            journalists: journalistsFiltered,
            text: journalistsFiltered
                .map((j) => j.journalistName)
                .reduce((joined, journalist, currentIndex) => {
                    if (journalistsLength === 1 || currentIndex === journalistsLength - 1) return `${joined}${journalist}`;

                    if (journalistsLength === 2) {
                        return `${joined}${journalist} and `;
                    }

                    if (journalistsLength > 2 && currentIndex === journalistsLength - 2) {
                        return `${joined}${journalist}, and `;
                    }

                    return `${joined}${journalist}, `;
                }, ""),
        };
    };

    public getSpecificTemplate = (type: TemplateType): string => {
        const { article } = this.props;

        if (!article) {
            return "";
        }

        switch (type) {
            case TemplateType.INTRO:
                return article.intro ? article.intro.templateText : "";
            case TemplateType.OUTRO:
                return article.outro ? article.outro.templateText : "";
            default:
                return "";
        }
    };

    public getTextTemplate = (type: TemplateType): string => {
        const {
            article,
            templates,
            user: { userRoleIdList: userRole },
        } = this.props;

        const dynamic = userRole !== UserRoles.NARRATOR;

        if (!article) {
            return "";
        }

        if (!dynamic) {
            return this.getSpecificTemplate(type);
        }

        const templateId = type === TemplateType.INTRO ? article.introId || 0 : article.outroId || 0;

        const template = templates && templates.find((t) => t.templateId === +templateId);

        return template ? template.templateText : "";
    };

    public getNewspaperName = () => {
        const {
            article,
            newspapers,
            user: { userRoleIdList: userRole },
        } = this.props;

        const dynamic = userRole !== UserRoles.NARRATOR;

        if (!article || !newspapers || !article.newspaperId) {
            return "";
        }

        const newspaperId = +article.newspaperId;

        if (!dynamic) {
            return article.newspaper ? article.newspaper.newspaperName : "";
        }

        const newspaper = newspapers && newspapers.find((n) => n.newspaperID === newspaperId);

        return newspaper ? newspaper.newspaperName : "";
    };

    public getNarratorName = () => {
        const {
            article,
            narrators,
            user: { userRoleIdList: userRole },
        } = this.props;

        const dynamic = userRole !== UserRoles.NARRATOR;

        if (!article || !narrators) {
            return "";
        }

        if (!dynamic) {
            const reader = article.articleReader;

            return reader ? `${reader.userFirstName} ${reader.userLastName}` : "";
        }

        const narratorId = article.articleAudioRecorderID || 0;
        const narrator = narrators && narrators.find((n) => n.userId === +narratorId);

        return narrator ? `${narrator.userFirstName} ${narrator.userLastName}` : "";
    };

    public getTemplate = (type: TemplateType, otherArticle?: IArticle, noManualStyling?: boolean) => {
        const article = otherArticle || this.props.article;

        if (!article) {
            return "";
        }

        let template = this.getTextTemplate(type);

        if (template === "") {
            if (type === TemplateType.INTRO) {
                if (article.manualIntro) {
                    if (!noManualStyling) {
                        template = getCustomTemplate(article.manualIntro, type);
                    } else {
                        template = article.manualIntro;
                    }
                } else {
                    template = "";
                }
            } else {
                if (article.manualOutro) {
                    if (!noManualStyling) {
                        template = getCustomTemplate(article.manualOutro, type);
                    } else {
                        template = article.manualOutro;
                    }
                } else {
                    template = "";
                }
            }
        }

        const title = article.altNameForNarration ? article.altNameForNarration : article.articleName;
        const section = article.articleSection || "";
        const subtitle = article.articleSubName || "";
        const newspaperName = this.getNewspaperName();
        const narratorName = this.getNarratorName();
        const date = formatDate(article.articleAddedDateTime, { enhancedFormatting: true });
        const viewDate = formatDate(article.articleViewDateTime, { enhancedFormatting: true });
        const publicationDate = formatDate(article.articleOriginalPublicationDateTime, { enhancedFormatting: true });

        const { text: journalistsName, journalists } = this.getJournalistsListAndNamesJoined();
        const journalistsLength = journalists.length;
        const journalistsWriteOrWrites = journalistsLength < 1 ? "" : journalistsLength > 1 ? "write" : "writes";

        return _.chain(template)
            .replace(/\n/g, "<br/>")
            .replace(/\{title\}/g, title)
            .replace(/\{section\}/g, section)
            .replace(/\{publisher\}/g, newspaperName)
            .replace(/\{narrator\}/g, narratorName)
            .replace(/\{journalists\}/g, journalistsName)
            .replace(/\{journalists_write\}/g, journalistsWriteOrWrites)
            .replace(/\{date\}/g, date)
            .replace(/\{view_date\}/g, viewDate)
            .replace(/\{publication_date\}/g, publicationDate)
            .replace(/\{subtitle\}/g, subtitle)
            .value();
    };

    public renderArticleScript = () => {
        const { article, journalists, narrators, newspapers, pronunciations, templates, history } = this.props;

        return (
            <Card>
                <ArticleScript
                    print={true}
                    article={article}
                    newspapers={newspapers}
                    journalists={journalists}
                    narrators={narrators}
                    pronunciations={pronunciations}
                    templates={templates}
                    dynamic={false}
                    user={this.props.user}
                    editPronunciation={this.props.editPronunciation}
                    verifyPronunciation={this.props.verifyPronunciation}
                    deletePronunciation={this.props.deletePronunciation}
                    fetchPronunciations={this.props.fetchPronunciations}
                    search={this.props.search}
                    history={history}
                />
            </Card>
        );
    };

    public submitPronunciationForm = (pronunciation: IPronunciation) => {
        // @ts-ignore
        return this.props.createPronunciation(pronunciation).then(() => {
            this.props.fetchPronunciations({ limit: ALL_PRONUNCIATIONS_LIMIT });
        });
    };

    public componentDidMount() {
        const { match, user } = this.props;

        this.props.fetchPronunciations({ limit: ALL_PRONUNCIATIONS_LIMIT });
        this.props.fetchTemplates();

        if (match.params.id) {
            this.props.fetchArticle(+match.params.id);
        }

        if (user.userRoleIdList === UserRoles.EDITOR || user.userRoleIdList === UserRoles.SUPER_USER) {
            this.props.fetchNewspapers();
            this.props.fetchCategories(requestPublishedCategories());
            this.props.fetchPlaylists(requestPublishedPlaylists());
            this.props.fetchUsers(requestNarrators(), UserRoles.NARRATOR);
        }
    }

    public componentDidUpdate(prevProps: IPropsFromState & IPropsFromDispatch) {
        const { user } = this.props;

        if (user.userRoleIdList !== UserRoles.EDITOR && user.userRoleIdList !== UserRoles.SUPER_USER) {
            return;
        }

        const newArticle = this.props.article ? this.props.article : { newspaperId: 0 };
        const prevArticle = prevProps.article ? prevProps.article : { newspaperId: 0 };

        if (newArticle.newspaperId !== prevArticle.newspaperId && !!newArticle.newspaperId && newArticle.newspaperId !== 0) {
            this.props.fetchNewspaper(newArticle.newspaperId);
        }
    }
    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Article View</title>
                </Helmet>
                {isLoading && <Loading />}
                <div className="col s12">
                    <ArticlePreviewText
                        getTemplate={this.getTemplate}
                        isAiGenerationType={false}
                        setWordsToHighlightInRed={() => {}}
                        pronunciations={this.props.pronunciations}
                        article={this.props.article}
                        singleColoredProns
                        openLinkOnClickAndHidePopup
                        hideWarningWords
                        hideWordPopups
                        nonScrollablePage
                        excludeAiPronunciations
                        showPhoneticsOnHover
                        title={this.props.article?.articleName}
                        showComments
                    />
                    <ButtonV2 style={{ margin: "20px auto 0px auto" }} onClick={() => window.print()}>
                        Print Article
                    </ButtonV2>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { ArticleForm },
        article: { article },
        newspaper: { newspaper },
    } = state;

    const articleValues = ArticleForm ? ArticleForm.values : {};

    const journalists = newspaper && newspaper.journalists ? newspaper.journalists : [];

    const preArticle = correctValue("articleID", articleValues, article);
    const validatedArticle = preArticle ? preArticle : createDefaultArticle();
    const hasChanged = ArticleForm && !ArticleForm.submitSucceeded ? !!ArticleForm.anyTouched : false;

    return {
        article: validatedArticle,
        categories: state.category.categories,
        error: state.article.error,
        hasChanged,
        isLoading:
            state.article.isLoading ||
            state.file.isLoading ||
            state.newspaper.isLoading ||
            state.user.isLoading ||
            state.category.isLoading ||
            state.journalist.isLoading ||
            state.pronunciation.isLoading,
        journalists,
        narrators: state.user.narrators,
        newspapers: state.newspaper.newspapers,
        playlists: state.playlist.playlists,
        pronunciations: state.pronunciation.pronunciations,
        templates: state.template.templates,
        user: state.auth.user as IUser,
        search: state.search,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            fetchArticle,
            fetchCategories,
            fetchNewspaper,
            fetchNewspapers,
            fetchPlaylists,
            fetchPronunciations,
            editPronunciation,
            verifyPronunciation,
            deletePronunciation,
            fetchTemplates,
            fetchUsers,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
