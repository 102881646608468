import { FC, useEffect, useState } from "react";
import ReactSelect from "react-select";
import { Helmet } from "react-helmet";
import {
    assignUserToExternalPublisherArticle,
    fetchExternalPublisherArticlesV2,
    removeArticleFromEpaV2,
    submitAudioToExternalPublisherArticleV2,
    updateArticleCommentsFromEpaV2,
} from "src/actions/article";
import { TextButton } from "src/styles/TextButton";
import { OuterWrapper, Table, TableWrapper, Wrapper } from "./styles";
import IArticle from "src/types/article";
import toast from "react-hot-toast";
import { ReactSelectStylesV2 } from "src/utils/react-select";
import ToggleSwitch from "src/components/ToggleSwitch";
import { Icon, Modal, ModalConfirm } from "styleguide";
import { InputTextArea } from "src/styles/Input";
import { ButtonV2 } from "src/styles/ButtonV2";
import { ItemHeading } from "src/styles/ItemHeading";
import { countAllWordsInArticleScript } from "src/utils/count-words";

const usersForAssignedTo = [
    {
        label: "Oliver Fisk",
        value: 19816,
    },
    {
        label: "Daniel Veliz",
        value: 114253,
    },
    {
        label: "John Balfe",
        value: 109603,
    },
    {
        label: "David Price",
        value: 54604,
    },
    {
        label: "Gareth Hickey",
        value: 9316,
    },
    {
        label: "David O'Donovan",
        value: 7206,
    },
];

const ExternalPublishersArticlesV2: FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isFailed, setIsFailed] = useState(false);
    const [articles, setArticles] = useState<IArticle[]>([]);
    const [loadingArticlesIds, setLoadingArticlesIds] = useState<number[]>([]);
    const [loadingRemovedArticlesIds, setLoadingRemovedArticlesIds] = useState<number[]>([]);
    const [assignToLoadingArticleIds, setAssignToLoadingArticleIds] = useState<number[]>([]);
    const [commentsLoadingArticleIds, setCommentsLoadingArticleIds] = useState<number[]>([]);

    const [isAssignedToMeChecked, setIsAssignedToMeChecked] = useState(false);
    const [isUnassignedChecked, setIsUnassignedChecked] = useState(false);

    const [articleForCommentsViewModal, setArticleForCommentsViewModal] = useState<{ article: IArticle; originalArticle: IArticle } | null>(null);

    const isSubmittingAudio = (articleId?: number) => {
        if (!articleId) return false;

        return loadingArticlesIds.some((aid) => aid === articleId);
    };

    const isRemovingAudio = (articleId?: number) => {
        if (!articleId) return false;

        return loadingRemovedArticlesIds.some((aid) => aid === articleId);
    };

    const onSubmitAudio = (articleId: number) => () => {
        setLoadingArticlesIds((prev) => [...prev, articleId]);
        submitAudioToExternalPublisherArticleV2(articleId)
            .then(() => {
                setArticles((prev) => prev.filter((item) => item.articleID !== articleId));

                toast.success("Audio submitted successfully.");
            })
            .catch(() => toast.error("Failed to submit audio."))
            .finally(() => setLoadingArticlesIds((prev) => [...prev].filter((aid) => aid !== articleId)));
    };

    const onRemoveAudio = (articleId: number) => {
        setLoadingRemovedArticlesIds((prev) => [...prev, articleId]);
        removeArticleFromEpaV2(articleId)
            .then(() => {
                setArticles((prev) => prev.filter((item) => item.articleID !== articleId));

                toast.success("Article removed successfully.");
            })
            .catch(() => toast.error("Failed to remove the article."))
            .finally(() => setLoadingRemovedArticlesIds((prev) => [...prev].filter((aid) => aid !== articleId)));
    };

    const onChangeAssignedTo = (articleId: number, userId: number) => {
        setAssignToLoadingArticleIds((prev) => [...prev, articleId]);
        assignUserToExternalPublisherArticle(articleId, userId)
            .then(() => {
                setArticles((prev) => {
                    const articleIndex = prev.findIndex((a) => a.articleID === articleId);
                    if (articleIndex === -1) return { ...prev };

                    const article = prev[articleIndex];

                    const cloned = [...prev];

                    cloned[articleIndex] = { ...article, assignedToUserId: userId };

                    return cloned;
                });
            })
            .catch(() => toast.error("Failed to assign user."))
            .finally(() => setAssignToLoadingArticleIds((prev) => [...prev].filter((aid) => aid !== articleId)));
    };

    const onChangeAssignedToMeCheck = (value: boolean) => {
        if (value) {
            setIsAssignedToMeChecked(true);
            setIsUnassignedChecked(false);
        } else {
            setIsAssignedToMeChecked(false);
        }
    };
    const onChangeUnassignedCheck = (value: boolean) => {
        if (value) {
            setIsUnassignedChecked(true);
            setIsAssignedToMeChecked(false);
        } else {
            setIsUnassignedChecked(false);
        }
    };

    const onCloseCommentsModal = () => {
        setArticleForCommentsViewModal(null);
    };

    const onAddArticleIdForCommentsViewModal = (articleId: number) => {
        const articleFound = articles.find((a) => a.articleID === articleId);
        if (articleFound) {
            setArticleForCommentsViewModal({ article: { ...articleFound }, originalArticle: { ...articleFound } });
        }
    };

    const onChangeArticleComments = (e: any) => {
        if (!articleForCommentsViewModal) return;

        if (typeof e?.target?.value === "string") {
            const { value } = e.target;
            setArticleForCommentsViewModal((prev) => prev && { ...prev, article: { ...prev.article, articleComments: value } });
        }
    };

    const onSaveArticleComments = () => {
        if (!articleForCommentsViewModal) return;

        const articleId = articleForCommentsViewModal.article.articleID;
        const commentsText = articleForCommentsViewModal.article.articleComments?.trim() || "";

        onCloseCommentsModal();

        setCommentsLoadingArticleIds((prev) => [...prev, articleId]);
        updateArticleCommentsFromEpaV2(articleId, commentsText)
            .then(() => {
                setArticles((prev) => {
                    const articleIndex = prev.findIndex((a) => a.articleID === articleId);
                    const cloned = [...prev];

                    cloned[articleIndex] = { ...prev[articleIndex], articleComments: commentsText };

                    return cloned;
                });

                toast.success("Comments updated successfully.");
            })
            .catch(() => toast.error("Failed to update the comments."))
            .finally(() => setCommentsLoadingArticleIds((prev) => [...prev].filter((aid) => aid !== articleId)));
    };

    useEffect(() => {
        fetchExternalPublisherArticlesV2()
            .then((eas) => {
                setArticles(eas.results);
            })
            .catch(() => setIsFailed(true))
            .finally(() => setIsLoading(false));
    }, []);

    const articlesFiltered = isAssignedToMeChecked
        ? articles.filter((article) => article.assignedToUserId === 62434)
        : isUnassignedChecked
        ? articles.filter((article) => !article.assignedToUserId)
        : articles;

    if (isLoading) return <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>Loading...</h4>;
    if (isFailed) return <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>Failed to fetch articles.</h4>;
    if (articles.length === 0) return <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>There is no articles available.</h4>;

    return (
        <OuterWrapper>
            {!!articleForCommentsViewModal && (
                <Modal title="View/Edit Comments" close={onCloseCommentsModal}>
                    <ItemHeading setMarginTop>Comments</ItemHeading>
                    <InputTextArea placeholder="Write comments..." onChange={onChangeArticleComments} value={articleForCommentsViewModal.article?.articleComments || ""}></InputTextArea>
                    <ButtonV2
                        onClick={onSaveArticleComments}
                        style={{ marginTop: "40px" }}
                        disabled={
                            !articleForCommentsViewModal.article.articleComments?.trim() ||
                            articleForCommentsViewModal.article.articleComments === articleForCommentsViewModal.originalArticle.articleComments
                        }
                    >
                        Save
                    </ButtonV2>
                </Modal>
            )}
            <Wrapper style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", borderRadius: "1px" }}>
                    <p style={{ fontSize: "14px", fontWeight: 500, color: "#172b4d" }}>Filter:</p>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "25px" }}>
                        <p style={{ marginRight: "10px", color: "#172b4d", fontSize: "14px" }}>Assigned to me</p>
                        <ToggleSwitch disabled={isLoading} checked={isAssignedToMeChecked} onChange={onChangeAssignedToMeCheck} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "25px" }}>
                        <p style={{ marginRight: "10px", color: "#172b4d", fontSize: "14px" }}>Unassigned</p>
                        <ToggleSwitch disabled={isLoading} checked={isUnassignedChecked} onChange={onChangeUnassignedCheck} />
                    </div>
                </div>
            </Wrapper>
            <Wrapper>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>External Publishers Articles</title>
                </Helmet>
                <TableWrapper>
                    <Table>
                        <thead>
                            <tr style={{ background: "none" }}>
                                <th>Pub. Article ID</th>
                                <th>Assigned To</th>
                                <th style={{ minWidth: "120px" }}>Publisher</th>
                                <th>Title</th>
                                <th style={{ minWidth: "150px" }}>Comments</th>
                                <th style={{ minWidth: "90px" }}>Word Count</th>
                                <th style={{ minWidth: "100px" }}>Pub. Date</th>
                                <th>Audio?</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {articlesFiltered.map((a) => {
                                const audioStatus = a?.articleExternalAudioFileName?.trim()?.length ? "Yes" : "No";
                                const dateModified = a.articleOriginalPublicationDateTime ? new Date(a.articleOriginalPublicationDateTime).toLocaleDateString() : "-";
                                const originalUrl = a.articleOriginLink;
                                const articleUrl = `/articles/${a.articleID}`;

                                const isSubmitting = isSubmittingAudio(a?.articleID);
                                const isRemoving = isRemovingAudio(a?.articleID);
                                const isAssignedToLoading = assignToLoadingArticleIds.some((articleId) => a.articleID === articleId);
                                const isCommentsUpdateLoading = commentsLoadingArticleIds.some((articleId) => a.articleID === articleId);

                                const assignedUserTransformed = !a.assignedToUserId
                                    ? null
                                    : { label: usersForAssignedTo.find((item) => item.value === a.assignedToUserId)?.label || `Unknown:${a.assignedToUserId}`, value: a.assignedToUserId };

                                return (
                                    <tr key={a.publisherArticleId}>
                                        <td>
                                            <span className="EPA_V2__publisher-article-id" onClick={() => originalUrl && window.open(originalUrl, "_blank")}>
                                                {a.publisherArticleId}
                                            </span>
                                        </td>
                                        <td>
                                            <div style={{ width: "190px", height: "46px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                                {" "}
                                                {isAssignedToLoading ? (
                                                    <span style={{ paddingLeft: "14px", color: "#7a869a" }}>Assigning...</span>
                                                ) : (
                                                    <div style={{ width: "100%" }}>
                                                        <ReactSelect
                                                            styles={{
                                                                ...ReactSelectStylesV2,
                                                            }}
                                                            name="assignedTo"
                                                            placeholder="Select Editor"
                                                            options={usersForAssignedTo}
                                                            value={assignedUserTransformed}
                                                            onChange={(u: any) => u && onChangeAssignedTo(a.articleID, u.value)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td>{a?.newspaper?.newspaperName}</td>
                                        <td style={{ paddingRight: "50px" }}>
                                            <span className="EPA_V2__article-name" onClick={() => articleUrl && window.open(articleUrl, "_blank")}>
                                                {" "}
                                                {a.articleName || "-"}
                                            </span>
                                        </td>
                                        <td>
                                            {isCommentsUpdateLoading ? (
                                                <span style={{ color: "#7a869a" }}>Updating...</span>
                                            ) : (
                                                <span className="EPA_V2__article-comments" onClick={() => onAddArticleIdForCommentsViewModal(a.articleID)}>
                                                    {a.articleComments ? `${a.articleComments?.trim()?.substring(0, 13)}...` : <span style={{ color: "#7a869a" }}>None</span>}
                                                </span>
                                            )}
                                        </td>
                                        <td style={{ textAlign: "center" }}>{countAllWordsInArticleScript(a)}</td>
                                        <td>{dateModified}</td>
                                        <td>{audioStatus}</td>
                                        <td>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                {audioStatus === "Yes" ? (
                                                    <TextButton style={{ width: "90px" }} disabled={isSubmitting || isRemoving} onClick={onSubmitAudio(a.articleID)}>
                                                        {isSubmitting ? <span style={{ color: "#7a869a" }}>Publishing...</span> : "Publish"}
                                                    </TextButton>
                                                ) : (
                                                    ""
                                                )}
                                                <ModalConfirm title="Are you sure?" description="Article will be removed from the EPA list.">
                                                    {(confirm: any) => (
                                                        <TextButton isRed disabled={isRemoving || isSubmitting} onClick={confirm(() => onRemoveAudio(a.articleID))}>
                                                            {isRemoving ? <span style={{ color: "#7a869a" }}>...</span> : <Icon icon="cancel" />}
                                                        </TextButton>
                                                    )}
                                                </ModalConfirm>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </TableWrapper>
            </Wrapper>
        </OuterWrapper>
    );
};

export default ExternalPublishersArticlesV2;
