import { styled } from "styled-components";

export const ControlsWrapper = styled.div<{ setMarginTop?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: ${(p) => (p.setMarginTop ? "24px" : "0px")};

    p {
        font-size: 14px;
        font-weight: 500;
        color: #42526e;
        margin: 0;
        padding: 0;
    }
`;

export const AssetHeading = styled.h2`
    font-size: 16px;
    font-weight: 600;
    color: #6b778c;
    border-bottom: 2px solid #dfe1e6;
    padding-bottom: 3px;
    width: 100%;
    display: block;
    margin-bottom: 19px;
`;

export const AudioPlatformHeading = styled.h2`
    font-size: 12px;
    font-weight: 600;
    color: #6b778c;
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
    line-height: auto !important;
`;

export const AudioAssetsWrapper = styled.div`
    margin-top: 24px;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 16px;
`;

export const SliderInputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
        // font-size: 14px !important;
        transform: translateY(14px);
        z-index: 1000 !important;
        color: #42526e;
        font-weight: 500 !important;
    }
`;

export const AiAudioMessage = styled.div`
    display: flex;
    width: 100%;

    padding: 14px 0px;
    margin-top: 20px;

    justify-content: center;
    align-items: center;
    background: #42526e;
    text-align: center;

    p {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }

    span {
        transform: translateY(2px);
        margin-right: 4px;
    }
`;

export const AiAudioMessageWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    button:focus {
        background-color: #fff !important;
    }
`;

export const AiVoiceSliders = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;

    & > div {
        width: 100%;

        &:not(&:last-child) {
            margin-right: 32px;
        }
    }
`;

export const TextEditorWrapper = styled.div<{ fullScreen: boolean }>`
    ${(p) =>
        !p.fullScreen
            ? ""
            : `
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        background: white;
    `}
`;

export const PlayerSpeedButton = styled.button`
    font-family: inherit;
    border: none;
    background: none;
    padding: 5px;
    margin: 0;
    cursor: pointer;
    font-size: 18px !important;
`;
