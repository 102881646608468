import styled from "styled-components";

export const EditorWrapper = styled.div<{ isFocused?: boolean; isFullScreen?: boolean }>`
    ${(p) => (!!p.isFullScreen ? "width: 80vw; max-width: 750px; margin-left: auto; margin-right: auto;" : "")}

    .DraftEditor-root {
        border: 2px solid ${(p) => (p.isFocused ? "#039be5" : "#dfe1e6")} !important;
        font-size: ${(p) => (!!p.isFullScreen ? "17px" : "14px")};
        color: ${(p) => (!!p.isFullScreen ? "#222222 !important" : "#7a869a !important")};
        border-radius: 3px !important;
        margin: 0 !important;
        transition: 0.05s ease border-color;
        padding: 10px;
        height: auto;
        overflow: auto;
    }

    .public-DraftEditor-content {
        height: auto !important;
        min-height: ${(p) => (!!p.isFullScreen ? "80vh" : "160px")};
        max-height: ${(p) => (!!p.isFullScreen ? "80vh" : "220px")};
    }
`;

export const EditorButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 8px;

    button {
        background: none;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;

        padding: 2px 6px;

        margin-right: 4px;
        cursor: pointer;

        &:hover {
            svg path {
                fill: #007fbc;
            }
        }
    }
`;
