import * as _ from "lodash";

import stopWords from "./stop-words";

export const isAlphanumeric = (str: string): boolean => {
    return /^[0-9a-zA-Z]+$/.test(str);
};

export const wordsFilteredByStopWords = (words: string[]): string[] => {
    return words.filter((w) => !stopWords.find((sw) => sw === w));
};

export const getRepeatedWords = (times: number, text?: string): string[] => {
    if (!text) {
        return [];
    }

    return _.chain(text)
        .lowerCase()
        .replace(/[,.;\\n’:' ]/, " ")
        .split(" ")
        .groupBy((w) => w)
        .filter((w) => w.length >= times)
        .map((ws) => ws[0])
        .filter((w) => !_.includes(stopWords, _.toLower(w)))
        .value();
};

export function removeHtmlFromText(text?: string) {
    if (!text) return "";

    const sanitized = new DOMParser().parseFromString(text || "", "text/html").body.textContent || "";
    return sanitized;
}

export const generateRandomString = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(23).substring(2, 5);
