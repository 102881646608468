import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ButtonFixed, Col, Icon, Loading, Table, Td, Th } from "styleguide";

import { clearSearch, doSearch } from "../actions/search";
import { fetchTags } from "../actions/tag";
import SearchForm from "../components/SearchForm";
import config from "../config";
import { IState as IReducesState } from "../reducers";
import { createDefaultSearch } from "../services/search";
import { requestTagsSearched } from "../services/tag";
import * as errors from "../types/errors";
import ISearch from "../types/search";
import ITag from "../types/tag";
import debounce from "../utils/debounce";
import { Helmet } from "react-helmet";
import ToggleSwitch from "src/components/ToggleSwitch";

interface IPropsFromState {
    tags: ITag[];
    error?: errors.HttpError;
    isLoading: boolean;
    offset: number;
    search: ISearch;
}

interface IState {
    isTrendingChecked: boolean;
    isPublishedChecked: boolean;
}

interface IPropsFromDispatch {
    fetchTags: typeof fetchTags;
    doSearch: typeof doSearch;
    clearSearch: typeof clearSearch;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>, IState> {
    constructor(props: any) {
        super(props);
        this.state = { isTrendingChecked: false, isPublishedChecked: false };
    }

    public renderListTags = () => {
        const { history, tags } = this.props;

        return (
            <Col s={12}>
                <div style={{ background: "#fff", padding: "20px", display: "flex", alignItems: "center", justifyContent: "flex-start", borderRadius: "1px" }}>
                    <p style={{ fontSize: "16px", fontWeight: 500 }}>Filter:</p>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "25px" }}>
                        <p style={{ marginRight: "10px" }}>Trending</p>
                        <ToggleSwitch
                            disabled={this.props.isLoading}
                            checked={this.state.isTrendingChecked}
                            onChange={(v) => {
                                this.setState({ isTrendingChecked: v }, () => this.callFetchTags());
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "25px" }}>
                        <p style={{ marginRight: "10px" }}>Published</p>
                        <ToggleSwitch
                            disabled={this.props.isLoading}
                            checked={this.state.isPublishedChecked}
                            onChange={(v) => {
                                this.setState({ isPublishedChecked: v }, () => this.callFetchTags());
                            }}
                        />
                    </div>
                </div>
                <Table title="Tags">
                    <thead>
                        <tr>
                            <Th>Id</Th>
                            <Th size="big">Name</Th>
                            <Th size="small">Trending</Th>
                            <Th size="medium">Trending Order</Th>
                            <Th size="small">Published</Th>
                            <Th align="right" size="small">
                                Edit
                            </Th>
                        </tr>
                    </thead>
                    <tbody>
                        {tags.map((tag) => (
                            <tr key={tag.tagID}>
                                <Td>{tag.tagID}</Td>
                                <Td>{tag.tagName}</Td>
                                <Td>{tag.tagIsTrending ? "Yes" : "No"}</Td>
                                <Td>{tag.trendingTagOrder !== null ? tag.trendingTagOrder : "-"}</Td>
                                <Td>{tag.tagIsPublished ? <Icon icon="cloud_done" className="green-text" /> : <Icon icon="cloud_off" className="grey-text" />}</Td>
                                <Td align="right">
                                    <a href={`/tags/${tag.tagID}`}>
                                        <Icon icon="edit" />
                                    </a>
                                </Td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div style={{ marginBottom: "150px" }}></div>
                <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.tag}+`)} />
            </Col>
        );
    };

    public renderSearch = () => {
        return <SearchForm onSubmit={this.submitForm} />;
    };

    public callFetchTags = () => {
        this.props.fetchTags(requestTagsSearched(this.props.search, this.state.isTrendingChecked, this.state.isPublishedChecked), true);
    };

    public submitForm = (search: ISearch | any) => {
        this.props.doSearch(search);

        this.props.fetchTags(requestTagsSearched(search, this.state.isTrendingChecked, this.state.isPublishedChecked), true);
    };

    public componentDidMount() {
        window.onscroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                debounce(() => {
                    this.props.fetchTags(requestTagsSearched(this.props.search, this.state.isTrendingChecked, this.state.isPublishedChecked), false);
                });
            }
        };
        this.props.fetchTags(requestTagsSearched(createDefaultSearch()), true);
    }

    public componentWillUnmount() {
        this.props.clearSearch();
        window.onscroll = null;
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Tags</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderSearch()}
                {this.renderListTags()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IReducesState): IPropsFromState {
    return {
        error: state.tag.error,
        isLoading: state.tag.isLoading,
        offset: state.tag.offset,
        search: state.search,
        tags: state.tag.tags,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearSearch,
            doSearch,
            fetchTags,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
