import { FC, useEffect, useRef, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";

import { IDiffExtendedItemWithGroup, IPronSelection, IQaPron, ITranscriptExtended } from "./types";

import AudioPlayer from "react-h5-audio-player";
import { ArticleQaWrapper, BottomActions, ControlsWrapper, QaPlayerSpeedButton } from "./styles";
// import ToggleSwitch from "src/components/ToggleSwitch";
// import { TextButton } from "src/styles/TextButton";
import { ButtonV2 } from "src/styles/ButtonV2";
import IArticle from "src/types/article";
import ArticlePronunciationsQaText from "./ArticlePronunciationsQaText";
import { connect } from "react-redux";
import { IState } from "src/reducers";
import IPronunciation from "src/types/pronunciation";
import { getPronunciationsFromText } from "src/utils/pronunciation-utils";
import {
    collectArticleTextForPronsQaAndAddDiffId,
    collectBeforeAndAfterWordsForSelectionItem,
    filterPronsFalsePositives,
    isAiGenerationType,
    normalizeQaPronWord,
    removeDuplicatedPronunciations,
} from "./utils";
import PronunciationsQaWords from "./PronunciationsQaWords";
import { generateRandomString } from "src/utils/string";
import ToggleSwitch from "src/components/ToggleSwitch";
import IUser from "src/types/user";
import { editPronunciation } from "src/actions/pronunciation";
import toast from "react-hot-toast";
import { Modal } from "styleguide";
import PronunciationForm from "src/components/PronunciationForm";

interface IPropsFromState {
    pronunciations: IPronunciation[];
    isLoading: boolean;
    user?: IUser;
}

interface IPropsFromDispatch {
    editPronunciationFn: typeof editPronunciation;
}

interface IProps {
    transcript: ITranscriptExtended;
    article?: IArticle;
    defaultSelections?: IPronSelection[];
    savePronunciationsSelections: (selections: IPronSelection[]) => void;
}

const PronunciationsQa: FC<IProps & IPropsFromState & IPropsFromDispatch> = ({
    transcript,
    article,
    defaultSelections,
    pronunciations,
    savePronunciationsSelections,
    isLoading,
    editPronunciationFn,
    user,
}) => {
    const [selections, setSelections] = useState<IPronSelection[]>(defaultSelections || []);

    const [prons, setProns] = useState<IQaPron[]>([]);
    const [shouldAutoPlay, setShouldAutoPlay] = useState(true);

    const [modalParams, setModalParams] = useState<{ pronunciation?: IPronunciation; initialValues: IPronunciation } | null>(null);

    const [playerSpeed, setPlayerSpeed] = useState(1);
    const playerRef = useRef() as any;
    const audioTimeUpdateListener = useRef(null) as any;

    const [isAudioManuallySeeked, setIsAudioManuallySeeked] = useState(false);

    const qaTextWrapperRef = useRef<HTMLDivElement>(null);
    const qaWordsWrapperRef = useRef<HTMLDivElement>(null);

    const qaCompletionStatus = () => {
        if (selections.length === prons.length) return true;
        return false;
    };

    const onComplete = () => {
        // downloadReport();
        savePronunciationsSelections(selections);
    };

    const onPickup = (pronItem: IQaPron) => {
        setSelections((prev) => {
            const itemLine = collectBeforeAndAfterWordsForSelectionItem(transcript.diff, pronItem.diffItems[0].id, pronItem.diffItems[pronItem.diffItems.length - 1].id);
            const item: IPronSelection = { ...pronItem, selectionType: "PICKUP", line: itemLine };
            const prevCloned = [...prev.filter((i) => i.qaPronId !== pronItem.qaPronId), item];
            return prevCloned;
        });
    };

    const onIgnore = (pronItem: IQaPron) => {
        setSelections((prev) => {
            const item: IPronSelection = { ...pronItem, selectionType: "IGNORE" };
            const prevCloned = [...prev.filter((i) => i.qaPronId !== pronItem.qaPronId), item];
            return prevCloned;
        });
    };

    const onIgnoreAll = (pronItem: IQaPron) => {
        setSelections((prev) => {
            const items: IPronSelection[] = prons.filter((p) => p.pron.pronunciationId === pronItem.pron.pronunciationId).map((p) => ({ ...p, selectionType: "IGNORE" }));
            const prevCloned = [...prev.filter((i) => i.pron.pronunciationId !== pronItem.pron.pronunciationId), ...items];
            return prevCloned;
        });
    };

    const isPronPickedUp = (pronItem: IQaPron) => {
        return selections.some((item) => item.pron.pronunciationId === pronItem.pron.pronunciationId && item.selectionType === "PICKUP");
    };

    const seekAudioToSeconds = (startTimeSeconds: number, endTimeSeconds?: number | null) => {
        const audio = playerRef.current.audio.current;

        const offset = 1.2;

        audio.currentTime = startTimeSeconds - offset;
        audio.play();

        if (audioTimeUpdateListener.current) {
            setIsAudioManuallySeeked(false);
            audio.removeEventListener("timeupdate", audioTimeUpdateListener.current);
        }

        audioTimeUpdateListener.current =
            endTimeSeconds && endTimeSeconds >= startTimeSeconds
                ? () => {
                      const currAudio = playerRef.current.audio.current;
                      if (currAudio.currentTime > endTimeSeconds + offset * 2) {
                          currAudio.pause();
                          setIsAudioManuallySeeked(false);
                      }
                  }
                : null;

        if (audioTimeUpdateListener.current) {
            setIsAudioManuallySeeked(true);
            audio.addEventListener("timeupdate", audioTimeUpdateListener.current);
        }
    };

    const onAudioPlay = () => {
        if (!isAudioManuallySeeked && !!audioTimeUpdateListener.current) {
            const currAudio = playerRef.current.audio.current;
            currAudio.removeEventListener("timeupdate", audioTimeUpdateListener.current);

            audioTimeUpdateListener.current = null;
        }
    };

    const incrementPlayerSpeed = (e?: any) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }

        const playerAudio = playerRef.current.audio.current;
        if (playerAudio && playerAudio.playbackRate < 2) {
            const newSpeed = playerAudio.playbackRate + 0.25;
            playerAudio.playbackRate = newSpeed;
            setPlayerSpeed(newSpeed);
        }
    };

    const decrementPlayerSpeed = (e?: any) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }

        const playerAudio = playerRef.current.audio.current;
        if (playerAudio && playerAudio.playbackRate > 0.25) {
            const newSpeed = playerAudio.playbackRate - 0.25;
            playerAudio.playbackRate = newSpeed;
            setPlayerSpeed(newSpeed);
        }
    };

    const isItemDisabled = (item: IQaPron) => {
        // This function find the index of the item and slice the prons using this index as `end` index and then reverse it\
        // then look for an item which is not selected, if found one, then it means there is an item behind the current which should be selected\
        // so return `true` to disable the current and activate the item which is needed to be selected.

        const itemIndex = prons.findIndex((pItem) => pItem.qaPronId === item.qaPronId);

        if (itemIndex === -1) {
            return true;
        }

        if (itemIndex === 0) {
            return false;
        }

        const pronsSliced = prons.slice(0, itemIndex).reverse();

        const isAnyItemNotSelected = pronsSliced.some((pItem) => {
            return !selections.some((slec) => slec.qaPronId === pItem.qaPronId);
        });

        if (isAnyItemNotSelected) return true;

        return false;
    };

    const getSelectionItemInfo = (item: IQaPron) => {
        const selectionItem = selections.find((slec) => slec.qaPronId === item.qaPronId);
        const isIgnored = !!selectionItem && selectionItem.selectionType === "IGNORE";
        const isPickedUp = !!selectionItem && selectionItem.selectionType === "PICKUP";
        const isDisabled = isItemDisabled(item);
        const isSelected = isPickedUp || isIgnored;
        const isActive = !isSelected && !isDisabled;

        return { selectionItem, isIgnored, isPickedUp, isDisabled: false, isSelected, isActive };
    };

    const getActiveItem = () => {
        return prons.find((pron) => getSelectionItemInfo(pron).isActive);
    };

    const updatePronInQaState = (pronunciation: IPronunciation) => {
        setProns((prev) => {
            const cloned = [...prev].map((pron) => {
                if (pron.pron.pronunciationId === pronunciation.pronunciationId) {
                    return { ...pron, pron: pronunciation };
                }

                return pron;
            });

            return cloned;
        });

        setSelections((prev) => {
            const cloned = [...prev].map((selection) => {
                if (selection.pron.pronunciationId === pronunciation.pronunciationId) {
                    return { ...selection, pron: pronunciation };
                }

                return selection;
            });

            return cloned;
        });
    };

    const onEditPronunciation = (pronunciation: IPronunciation) => {
        setModalParams({ initialValues: { ...pronunciation }, pronunciation: { ...pronunciation } });
    };

    const onCloseModal = () => {
        setModalParams(null);
    };

    const submitEditPronunciationForm = (pronunciation: IPronunciation | any) => {
        (editPronunciationFn as any)(pronunciation, user, undefined, undefined, { hideToast: true, skipReduxUpdates: true })
            .then(() => {
                updatePronInQaState(pronunciation);
                onCloseModal();
                toast.success("Pronunciation Edited.");
            })
            .catch(() => {
                toast.error("Failed to edit pronunciation.");
            });
    };

    useEffect(() => {
        const { articleText, getPronNameRegex, getPronsAndDiffIds } = collectArticleTextForPronsQaAndAddDiffId(transcript.diff);

        let pronsFound = getPronunciationsFromText(
            articleText,
            pronunciations.sort((a, b) => b.pronunciationName.length - a.pronunciationName.length),
            true,
            true,
            (word: string) => normalizeQaPronWord(word),
            getPronNameRegex,
        );

        // filtering the empty word incorrect prons
        pronsFound = pronsFound.filter((p) => !!p.word?.trim()?.length).map((p) => ({ ...p, word: p.word.trim() }));

        const pronsWithDiffIds = getPronsAndDiffIds(pronsFound);

        // transforming and attaching diff items with
        const diff = transcript.diff;
        let pronsTransformed: IQaPron[] = pronsWithDiffIds
            .map((pron) => {
                // filtering just as a fallback if for some reason the diffItem is not found.
                const diffItems = pron.diffIds
                    .map((diffId) => diff.find((diffItem) => diffItem.id === diffId.id))
                    .filter((item) => item !== undefined)
                    .sort((a, b) => a!.pos - b!.pos) as IDiffExtendedItemWithGroup[];

                const qaPronId = generateRandomString();

                return { diffItems, word: pron.actualWord, pron: pron.pron.pron, qaPronId };
            })
            .sort((a, b) => (a.diffItems?.[0]?.pos || 0) - (b.diffItems?.[0]?.pos || 0));

        pronsTransformed = filterPronsFalsePositives(pronsTransformed);

        const isAiNarration = isAiGenerationType(article);

        let pronsFiltered: IQaPron[];

        if (isAiNarration) {
            pronsFiltered = pronsTransformed.filter((p) => !!p.pron.aiPhonetic?.trim());
        } else {
            pronsFiltered = pronsTransformed.filter((p) => !!p.pron.pronunciationComment?.trim());
        }

        // removing duplicated pronunciations
        /*
        Example:
        If I create a pronunciation for ‘Latif’ and another for ‘Shams Latif', both versions appear in QA step 2 meaning I need to check one and skip past the other.
        */
        pronsFiltered = removeDuplicatedPronunciations(pronsFiltered);

        setProns(pronsFiltered);
    }, [pronunciations, transcript.diff]);

    const completionStatus = qaCompletionStatus();
    const pickupsCount = selections.filter((item) => item.selectionType === "PICKUP").length;

    if (isLoading) return <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>Loading...</h4>;

    return (
        <ArticleQaWrapper>
            {modalParams && (
                <Modal title={'Don\'t update the "Text" field on this page.'} close={onCloseModal}>
                    <PronunciationForm {...{ ...modalParams, onSubmit: submitEditPronunciationForm }} />
                </Modal>
            )}
            <div style={{ padding: "0px 40px", width: "100%", transform: "translateY(-80px)", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                <div
                    ref={qaWordsWrapperRef}
                    style={{
                        minWidth: "420px",
                        height: "100vh",
                        overflow: "scroll",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        paddingTop: "110px",
                        marginRight: "50px",
                        paddingBottom: "55vh",
                    }}
                >
                    <ControlsWrapper>
                        <div className="ARTICLE_QA-audio-player">
                            <AudioPlayer
                                customAdditionalControls={[
                                    <QaPlayerSpeedButton style={{ marginTop: "-18px" }} onClick={decrementPlayerSpeed}>
                                        _
                                    </QaPlayerSpeedButton>,
                                    <span
                                        style={{
                                            width: "35px",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            color: "#5a5e5f",
                                            margin: "0px 10px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        {playerSpeed}x
                                    </span>,
                                    <QaPlayerSpeedButton onClick={incrementPlayerSpeed}>+</QaPlayerSpeedButton>,
                                ]}
                                onPlay={onAudioPlay}
                                ref={playerRef}
                                style={{ width: "100%", marginBottom: "15px" }}
                                src={article?.audioUrl || article?.externalAudioUrl || ""}
                            />
                        </div>

                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridColumnGap: "20px", alignItems: "center", justifyItems: "flex-start" }}>
                            <ButtonV2 onClick={() => savePronunciationsSelections([])}>Skip this step</ButtonV2>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", minWidth: "185px", justifySelf: "flex-end" }}>
                                <ToggleSwitch checked={shouldAutoPlay} onChange={(v) => setShouldAutoPlay(v ? true : false)} />
                                <p style={{ marginLeft: "7px", fontSize: "14px", color: "#000000", fontWeight: "400", fontFamily: "Poppins" }}>AutoPlay</p>
                            </div>
                        </div>
                    </ControlsWrapper>
                    <PronunciationsQaWords
                        qaWordsWrapperRef={qaWordsWrapperRef}
                        prons={prons}
                        onIgnore={onIgnore}
                        onPickup={onPickup}
                        seekAudioToSeconds={seekAudioToSeconds}
                        selections={selections}
                        getSelectionItemInfo={getSelectionItemInfo}
                        onIgnoreAll={onIgnoreAll}
                        isPronPickedUp={isPronPickedUp}
                        article={article}
                        shouldAutoPlay={shouldAutoPlay}
                        getActiveItem={getActiveItem}
                        onEditPronunciation={onEditPronunciation}
                    />
                </div>

                <div ref={qaTextWrapperRef} style={{ height: "100vh", overflow: "scroll", paddingTop: "110px", paddingBottom: "57vh", width: "100%" }}>
                    <ArticlePronunciationsQaText
                        sttEngine={transcript.sttEngine}
                        transcriptId={transcript.id}
                        qaTextWrapperRef={qaTextWrapperRef}
                        getActiveItem={getActiveItem}
                        diff={transcript.diff}
                        prons={prons}
                    />
                </div>

                <BottomActions>
                    <>
                        <p>
                            {completionStatus ? (
                                pickupsCount === 0 ? (
                                    <span style={{ color: "#00875A" }}>Nice work! No issues picked up.</span>
                                ) : (
                                    <span style={{ color: "#00875A" }}>Pronunciations QA completed 🙌 ({pickupsCount} Pickups)</span>
                                )
                            ) : (
                                <span style={{ color: "#e8a019" }}>Selection required of all the pronunciations.</span>
                            )}
                        </p>

                        <ButtonV2 onClick={onComplete} disabled={!completionStatus}>
                            Next
                        </ButtonV2>
                    </>
                </BottomActions>
            </div>
        </ArticleQaWrapper>
    );
};

function mapStateToProps(state: IState): IPropsFromState {
    return {
        pronunciations: state.pronunciation.pronunciations,
        isLoading: state.pronunciation.isLoading,
        user: state.auth.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            editPronunciationFn: editPronunciation,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PronunciationsQa);
