import { FC, useCallback } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { ButtonV2 } from "src/styles/ButtonV2";
import styled from "styled-components";

interface IProps {
    onAccept: (files: File[]) => void;
    onReject: (files: FileRejection[]) => void;
    allowMultiple?: boolean;
    fileTypes?: { [key: string]: string[] };
    value?: string;
}

const FileDrop: FC<IProps> = ({ onAccept, fileTypes, allowMultiple, onReject, value }) => {
    const truncateValue = (str: string) => {
        if (str.length <= 15) return str;

        return str.substring(0, 15) + "...";
    };

    const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        onAccept(acceptedFiles);
        onReject(rejectedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: fileTypes, multiple: !!allowMultiple });

    return (
        <Wrapper {...getRootProps()}>
            <input {...getInputProps()} />
            <div>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="36" height="36" fill="white" fill-opacity="0.01" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.812 13.98L13.935 16.8795C13.6561 17.1605 13.4996 17.5403 13.4996 17.9363C13.4996 18.3322 13.6561 18.712 13.935 18.993C14.072 19.1316 14.2352 19.2417 14.415 19.3168C14.5949 19.392 14.7878 19.4307 14.9827 19.4307C15.1777 19.4307 15.3706 19.392 15.5505 19.3168C15.7303 19.2417 15.8935 19.1316 16.0305 18.993L17.9655 17.0415L19.9695 19.062C20.1064 19.2007 20.2695 19.3109 20.4493 19.3862C20.6291 19.4614 20.822 19.5003 21.0169 19.5004C21.2118 19.5005 21.4049 19.462 21.5848 19.387C21.7647 19.312 21.9279 19.202 22.065 19.0635C22.3429 18.7824 22.4989 18.4031 22.4992 18.0078C22.4995 17.6125 22.344 17.233 22.0665 16.9515L19.119 13.9815C18.9684 13.8286 18.7889 13.7071 18.5909 13.6241C18.3929 13.5412 18.1805 13.4984 17.9658 13.4982C17.7512 13.4981 17.5386 13.5406 17.3406 13.6233C17.1425 13.706 16.9628 13.8273 16.812 13.98Z"
                        fill="#42526E"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.5 15.0105L16.5015 29.9895C16.5015 30.825 17.1735 31.5015 18.0015 31.5C18.831 31.5 19.5015 30.825 19.5015 29.9895L19.5 15.009C19.5 14.1765 18.828 13.5 18 13.5C17.172 13.5 16.4985 14.1765 16.5 15.0105Z"
                        fill="#42526E"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.907 8.2215C11.5217 8.15845 11.1319 8.12685 10.7415 8.127C6.534 8.1285 3 11.43 3 15.7485C3 20.112 6.5775 24 10.65 24H14.9715V21.0105H10.65C8.1645 21.0105 5.9775 18.516 5.9775 15.7485C5.9775 13.0815 8.181 11.118 10.6335 11.118H10.6515C11.235 11.118 11.6805 11.1945 12.1065 11.343L12.3615 11.4375C13.269 11.8095 13.674 11.0685 13.674 11.0685L13.899 10.668C14.994 8.6475 17.2005 7.524 19.473 7.4895C20.9805 7.5045 22.431 8.06738 23.5541 9.07312C24.6771 10.0789 25.396 11.4588 25.5765 12.9555L25.6455 13.4655C25.6455 13.4655 25.752 14.253 26.643 14.253C26.6625 14.253 26.661 14.2605 26.6775 14.2605H27.0585C28.7625 14.2605 30.0225 15.699 30.0225 17.4975C30.0225 19.308 28.542 21.0105 26.9175 21.0105H20.9715V24H26.9175C30.1575 24 33 20.9325 33 17.4975C33 14.499 31.032 12.003 28.293 11.3865C27.2325 7.326 23.7135 4.5585 19.464 4.5C16.5015 4.5315 13.6125 5.8515 11.907 8.2215Z"
                        fill="#42526E"
                    />
                </svg>
            </div>
            <p className="FileDrop__heading">Drag drop the Image here</p>
            <p className="FileDrop__or">Or</p>
            <ButtonV2 onClick={(e: any) => e.preventDefault()}>{!!value ? truncateValue(value) : "Upload from computer"}</ButtonV2>
        </Wrapper>
    );
};

export default FileDrop;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(9, 30, 66, 0.04);
    border-radius: 3px;
    padding: 29px;
    padding-bottom: 40px;
    text-align: center;

    cursor: pointer;

    div {
        margin-bottom: 7px;
    }

    p.FileDrop__heading {
        margin: 0 !important;
        color: #42526e !important;
        font-size: 12px !important;
        font-weight: 600 !important;
        margin-bottom: 13px !important;
        text-transform: uppercase !important;
    }

    p.FileDrop__or {
        margin: 0 !important;
        font-size: 11px;
        color: #6b778c;
        font-weight: 400;
        margin-bottom: 17px !important;
    }
`;
