import { FC } from "react";
import { IPronSelection, ISelection } from "./types";
import { ButtonV2 } from "src/styles/ButtonV2";
import { mergeDiffItemsWithByGroupId } from "./utils";

interface IProps {
    transcriptionSelections: ISelection[];
    pronunciationsSelections: IPronSelection[];
    downloadReport: () => void;
}

const QaCompleted: FC<IProps> = ({ transcriptionSelections, pronunciationsSelections, downloadReport }) => {
    const transcriptSelectionsCount = mergeDiffItemsWithByGroupId(transcriptionSelections.filter((i) => i.selectionType === "PICKUP")).length;
    const pronsSelectionsCount = pronunciationsSelections.filter((i) => i.selectionType === "PICKUP").length;

    const closePage = () => {
        window.location.replace("/articles");
    };

    const onDownloadReport = () => {
        downloadReport();
        closePage();
    };

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            {transcriptSelectionsCount + pronsSelectionsCount > 0 ? (
                <>
                    <h5 style={{ marginTop: "100px" }}>Article Script Pickups: {transcriptSelectionsCount}</h5>
                    <h5>Pronunciations Pickups: {pronsSelectionsCount}</h5>
                    <ButtonV2 style={{ marginTop: "20px" }} onClick={onDownloadReport}>
                        Download Report
                    </ButtonV2>
                </>
            ) : (
                <>
                    <h5 style={{ marginTop: "100px" }}>Nice work! No issues picked up.</h5>
                    <ButtonV2 style={{ marginTop: "20px" }} onClick={closePage}>
                        Close this page
                    </ButtonV2>
                </>
            )}
        </div>
    );
};

export default QaCompleted;
