import * as _ from "lodash";
import { Dispatch } from "redux";

import { toast } from "styleguide";
import { clearFiles, populateWithFiles, uploadFiles } from "../actions/file";
import config from "../config";
import { IState } from "../reducers";
import IArticle from "../types/article";
import IFile from "../types/file";
import FileType from "../types/file-type";
import HttpMethods from "../types/http-methods";
import fetch from "../utils/fetch";

import {
    CLEAR_ARTICLE_STATE,
    CREATE_ARTICLE_FAILURE,
    CREATE_ARTICLE_REQUEST,
    CREATE_ARTICLE_SUCCESS,
    EDIT_ARTICLE_FAILURE,
    EDIT_ARTICLE_REQUEST,
    EDIT_ARTICLE_SUCCESS,
    FETCH_ARTICLE_FAILURE,
    FETCH_ARTICLE_REQUEST,
    FETCH_ARTICLE_SUCCESS,
    FETCH_ARTICLES_FAILURE,
    FETCH_ARTICLES_REQUEST,
    FETCH_ARTICLES_SUCCESS,
    MOVE_DOWN_ARTICLE_FAILURE,
    MOVE_DOWN_ARTICLE_REQUEST,
    MOVE_DOWN_ARTICLE_SUCCESS,
    MOVE_UP_ARTICLE_FAILURE,
    MOVE_UP_ARTICLE_REQUEST,
    MOVE_UP_ARTICLE_SUCCESS,
    RECONCILE_ARTICLE,
    TOGGLE_PUBLISH_ARTICLE_FAILURE,
    TOGGLE_PUBLISH_ARTICLE_REQUEST,
    TOGGLE_PUBLISH_ARTICLE_SUCCESS,
} from "../constants/article";
import { requestArticle } from "../services/article";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";
import { IExternalPublisherResponse } from "src/types/external-publishers-articles";

/*
    ASYNC ACTIONS
*/

export const moveUp = (articleId: number, playlistId: number) => (dispatch: Dispatch) => {
    dispatch({ type: MOVE_UP_ARTICLE_REQUEST });

    return fetch(`${config.api.url}${config.api.paths.article}/${articleId}/reorder/up`, {
        body: JSON.stringify({ playlistId }),
        method: HttpMethods.PUT,
    })
        .then((resp) => responseCheck(resp))
        .then(() => dispatch(moveUpSuccess()))
        .catch((error) => dispatch(moveUpFailure(error)));
};

export const moveDown = (articleId: number, playlistId: number) => (dispatch: Dispatch) => {
    dispatch({ type: MOVE_DOWN_ARTICLE_REQUEST });

    return fetch(`${config.api.url}${config.api.paths.article}/${articleId}/reorder/down`, {
        body: JSON.stringify({ playlistId }),
        method: HttpMethods.PUT,
    })
        .then((resp) => responseCheck(resp))
        .then(() => dispatch(moveDownSuccess()))
        .catch((error) => dispatch(moveDownFailure(error)));
};

export const fetchArticles = (params?: IParams, reset?: boolean, filter?: (articles: IArticle[]) => IArticle[]) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: FETCH_ARTICLES_REQUEST });

    const state = getState();
    const offset = params && params.offset ? params.offset : reset ? 0 : state.article.offset;

    const url = buildUrl(`${config.api.url}${config.api.paths.article}`, {
        ...params,
        offset,
    });

    return fetch(url)
        .then((resp) => responseCheck(resp))
        .then((articles) =>
            fetchArticlesSuccess({
                articles: filter ? filter(articles) : articles,
                dispatch,
                offset: params && params.offset ? params.offset : undefined,
                oldArticles: state.article.articles,
                reset: reset ? reset : false,
            }),
        )
        .catch((error) => fetchArticlesFailure(dispatch, error));
};

export const fetchArticle = (id: number) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: FETCH_ARTICLE_REQUEST });
    const articles = getState().article.articles;

    const url = buildUrl(`${config.api.url}${config.api.paths.article}/${id}`, requestArticle());

    return fetch(url)
        .then((resp) => responseCheck(resp))
        .then((article) => fetchArticleSuccess(dispatch, articles, article))
        .catch((error) => dispatch(fetchArticleFailure(error)));
};

export const fetchArticleNoRedux = (id: number) => {
    const url = buildUrl(`${config.api.url}${config.api.paths.article}/${id}`, requestArticle());

    return fetch(url).then((res) => {
        if (res.status !== 200) {
            throw new Error("Request Failed");
        }

        return res.json();
    });
};

export const togglePublishArticle =
    (article: IArticle) =>
    (dispatch: Dispatch, getState: () => IState): Promise<any> => {
        dispatch({ type: TOGGLE_PUBLISH_ARTICLE_REQUEST });

        const newArticle = {
            ...article,
            articleIsPublished: article.articleIsPublished === 0 ? 1 : 0,
        };
        return fetch(`${config.api.url}${config.api.paths.article}/${article.articleID}`, {
            body: JSON.stringify({ model: newArticle }),
            method: HttpMethods.PUT,
        })
            .then(responseCheck)
            .then(() => dispatch(togglePublishSuccess()))
            .then(() => fetchArticle(article.articleID)(dispatch, getState))
            .catch((error) => dispatch(togglePublishFailure(error)));
    };

export const editArticle =
    (article: IArticle) =>
    (dispatch: Dispatch, getState: () => IState): Promise<any> => {
        dispatch({ type: EDIT_ARTICLE_REQUEST });

        const url = `${config.api.url}${config.api.paths.article}/${article.articleID}`;

        return uploadFiles("article")(dispatch, getState)
            .then(() => {
                populateWithFiles(getState, (files: IFile[]) => {
                    files.forEach((f) => {
                        if (f.type === FileType.audio) {
                            if (f.id === "articleAudioFileName") {
                                article.articleAudioFileName = f.key;
                                article.articleAudioLength = `${f.duration}` || "";
                            } else {
                                article.articleExternalAudioFileName = f.key;
                                article.articleExternalAudioLength = f.duration || 0;
                            }
                        }

                        if (f.type === FileType.image) {
                            article.articleImageFileName = f.key;
                        }
                    });
                });

                return fetch(url, {
                    body: JSON.stringify({ model: article }),
                    method: HttpMethods.PUT,
                });
            })
            .then(responseCheck)
            .then(() => dispatch(editArticleSuccess()))
            .then(() => dispatch(clearFiles()))
            .then(() => article.articleID)
            .catch((error) => dispatch(editArticleFailure(error)));
    };

export const createArticle =
    (article: IArticle) =>
    (dispatch: Dispatch, getState: () => IState): Promise<any> => {
        dispatch({ type: CREATE_ARTICLE_REQUEST });

        const url = `${config.api.url}${config.api.paths.article}`;
        let articleIdCreated = 0;

        return uploadFiles("article")(dispatch, getState)
            .then(() => {
                populateWithFiles(getState, (files: IFile[]) => {
                    files.forEach((f) => {
                        if (f.type === FileType.audio) {
                            if (f.id === "articleAudioFileName") {
                                article.articleAudioFileName = f.key;
                                article.articleAudioLength = `${f.duration}` || "";
                            } else {
                                article.articleExternalAudioFileName = f.key;
                                article.articleExternalAudioLength = f.duration || 0;
                            }
                        }

                        if (f.type === FileType.image) {
                            article.articleImageFileName = f.key;
                        }
                    });
                });

                return fetch(url, {
                    body: JSON.stringify({ model: article }),
                    method: HttpMethods.POST,
                });
            })
            .then(responseCheck)
            .then((articleReturned: IArticle) => {
                articleIdCreated = articleReturned.articleID;
                return dispatch(createArticleSuccess());
            })
            .then(() => dispatch(clearFiles()))
            .then(() => articleIdCreated)
            .catch((error) => dispatch(createArticleFailure(error)));
    };

export const fetchPronunciation = (text: string, voiceId?: string, modelId?: string) =>
    fetch(`${config.api.url}${config.api.paths.elevenlabs}/text-to-speech`, {
        body: JSON.stringify({ text, voiceId, modelId }),
        method: HttpMethods.POST,
    }).then((data) => {
        if (data.status !== 200) {
            throw new Error("failed to fetch pron");
        }

        return data.blob();
    });

export const updateAiAudio = (articleId: number, platform: "internal" | "external" | "both") =>
    fetch(`${config.api.url}${config.api.paths.article}/${articleId}/latest-snapshot-audio?platform=${platform}`, {
        method: HttpMethods.PUT,
    }).then((res) => {
        if (res.status !== 200) {
            throw new Error("Request Failed");
        }
    });

export const retryAudioTranscription = (articleId: number) =>
    fetch(`${config.api.url}${config.api.paths.article}/${articleId}/transcription/regenerate`, {
        method: HttpMethods.PUT,
    }).then((res) => {
        if (res.status !== 200) {
            throw new Error("Request Failed");
        }
    });

export const fetchExternalPublisherArticles = (page: number) => {
    return fetch(`${config.api.url}${config.api.paths.externalPublishersArticles}?page=${page}`)
        .then(responseCheck)
        .then((externalArticlesResponse) => externalArticlesResponse as IExternalPublisherResponse);
};

export const fetchExternalPublisherArticlesV2 = () => {
    return fetch(`${config.api.url}${config.api.paths.externalPublishersArticles}/v2`)
        .then(responseCheck)
        .then((externalArticlesResponse) => externalArticlesResponse as { results: IArticle[] });
};

export const submitAudioToExternalPublisherArticle = (articleId: number) =>
    fetch(`${config.api.url}${config.api.paths.externalPublishersArticles}/${articleId}`, {
        method: HttpMethods.POST,
    }).then((res) => {
        if (res.status !== 200) {
            throw new Error("Request Failed");
        }
    });

export const submitAudioToExternalPublisherArticleV2 = (articleId: number) =>
    fetch(`${config.api.url}${config.api.paths.externalPublishersArticles}/v2/${articleId}`, {
        method: HttpMethods.POST,
    }).then((res) => {
        if (res.status !== 200) {
            throw new Error("Request Failed");
        }
    });

export const removeArticleFromEpaV2 = (articleId: number) =>
    fetch(`${config.api.url}${config.api.paths.externalPublishersArticles}/v2/${articleId}`, {
        method: HttpMethods.DELETE,
    }).then((res) => {
        if (res.status !== 200) {
            throw new Error("Request Failed");
        }
    });

export const updateArticleCommentsFromEpaV2 = (articleId: number, articleComments: string) =>
    fetch(`${config.api.url}${config.api.paths.externalPublishersArticles}/v2/comments`, {
        method: HttpMethods.PUT,
        body: JSON.stringify({ articleId, comments: articleComments }),
    }).then((res) => {
        if (res.status !== 200) {
            throw new Error("Request Failed");
        }
    });

export const assignUserToExternalPublisherArticle = (articleId: number, userId: number) =>
    fetch(`${config.api.url}${config.api.paths.externalPublishersArticles}/v2/assignTo`, {
        method: HttpMethods.POST,
        body: JSON.stringify({ userId, articleId }),
    }).then((res) => {
        if (res.status !== 200) {
            throw new Error("Request Failed");
        }
    });

/*
    SYNC ACTIONS
*/

export const clearArticleState = () => {
    return { type: CLEAR_ARTICLE_STATE };
};

const fetchArticlesSuccess = (params: { dispatch: Dispatch; articles: IArticle[]; oldArticles: IArticle[]; reset: boolean; offset?: number }) => {
    const newArticles = !params.reset ? [...params.oldArticles, ...params.articles] : params.articles;

    return params.dispatch({
        articles: newArticles,
        offset: params.offset ? params.offset : newArticles.length,
        type: FETCH_ARTICLES_SUCCESS,
    });
};

const fetchArticlesFailure = (dispatch: Dispatch, error: Error) => {
    toast("Error fetching articles");
    return dispatch({ type: FETCH_ARTICLES_FAILURE, error });
};

const fetchArticleSuccess = (dispatch: Dispatch, articles: IArticle[], article: IArticle) => {
    if (articles.length > 0) {
        reconcileArticle(dispatch, articles, article);
    }

    return dispatch({ type: FETCH_ARTICLE_SUCCESS, article });
};

const fetchArticleFailure = (error: Error) => {
    toast("Error fetching article");
    return { type: FETCH_ARTICLE_FAILURE, error };
};

const togglePublishSuccess = () => {
    return { type: TOGGLE_PUBLISH_ARTICLE_SUCCESS };
};

const togglePublishFailure = (error: Error) => {
    toast("Error (un)publishing article");
    return { type: TOGGLE_PUBLISH_ARTICLE_FAILURE, error };
};

const editArticleSuccess = () => {
    toast("Success editing article");
    return { type: EDIT_ARTICLE_SUCCESS };
};

const editArticleFailure = (error: Error) => {
    toast("Error editing article");
    return { type: EDIT_ARTICLE_FAILURE, error };
};

const createArticleSuccess = () => {
    toast("Success creating article");
    return { type: CREATE_ARTICLE_SUCCESS };
};

const createArticleFailure = (error: Error) => {
    toast("Error creating article");
    return { type: CREATE_ARTICLE_FAILURE, error };
};

const reconcileArticle = (dispatch: Dispatch, articles: IArticle[], article: IArticle) => {
    const index = _.findIndex(articles, { articleID: article.articleID });

    if (index !== -1) {
        const clonedArticles = [...articles];
        clonedArticles.splice(index, 1, article);

        return dispatch({ type: RECONCILE_ARTICLE, articles: clonedArticles });
    }

    return dispatch({ type: RECONCILE_ARTICLE, articles });
};

const moveUpSuccess = () => {
    return { type: MOVE_UP_ARTICLE_SUCCESS };
};

const moveUpFailure = (error: Error) => {
    toast("Error moving article");
    return { type: MOVE_UP_ARTICLE_FAILURE, error };
};

const moveDownSuccess = () => {
    return { type: MOVE_DOWN_ARTICLE_SUCCESS };
};

const moveDownFailure = (error: Error) => {
    toast("Error moving article");
    return { type: MOVE_DOWN_ARTICLE_FAILURE, error };
};
