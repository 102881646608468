import { styled } from "styled-components";

export const ContentCard = styled.div`
    padding: 24px 36px 30px 36px;
    border-radius: 5px;
    background: #fff;

    width: 100%;

    margin-bottom: 40px;
`;
