import * as React from "react";

import * as _ from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Col, Loading } from "styleguide";

import { clearTagState, createTag, editTag, fetchTag } from "../actions/tag";
import TagForm from "../components/TagForm";
import { IState } from "../reducers";
import { createDefaultTag } from "../services/tag";
import * as errors from "../types/errors";
import ITag from "../types/tag";
import { correctValue } from "../utils/values";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    error?: errors.HttpError;
    tag: ITag;
    isLoading: boolean;
}

interface IPropsFromDispatch {
    clearTagState: typeof clearTagState;
    createTag: typeof createTag;
    editTag: typeof editTag;
    fetchTag: typeof fetchTag;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public renderForm = () => {
        const { tag } = this.props;

        const params = {
            initialValues: { ...tag, error: this.props.error },
            onSubmit: this.submitForm,
            tag,
        };

        return (
            <Col s={12} m={6} mOffset={3} l={4} lOffset={4}>
                <Card title="Tag">
                    <TagForm {...params} />
                </Card>
            </Col>
        );
    };

    public submitForm = (tag: ITag | any) => {
        const { tag: oldTag, history } = this.props;

        const tagToSend = { ...tag };

        if (!tag.tagIsTrending) {
            tagToSend.trendingTagOrder = null;
        }

        if (oldTag && oldTag.tagID) {
            return this.props.editTag(tagToSend, history);
        }
        return this.props.createTag(tagToSend, history);
    };

    public componentWillUnmount() {
        this.props.clearTagState();
    }

    public componentDidMount() {
        const { match } = this.props;

        if (match.params.id) {
            this.props.fetchTag(+match.params.id);
        }
    }

    public render() {
        const title = `Tag Editor | ${this.props.tag && this.props.tag.tagName ? this.props.tag.tagName : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {this.props.isLoading && <Loading />}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { TagForm: tagForm },
        tag: { tag },
    } = state;

    const tagValues = tagForm ? tagForm.values : {};

    const preTag = correctValue("tagID", tagValues, tag);
    const validatedTag = preTag ? preTag : createDefaultTag();

    return {
        error: state.tag.error,
        isLoading: state.tag.isLoading,
        tag: validatedTag,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearTagState,
            createTag,
            editTag,
            fetchTag,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
