import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ButtonFixed, Col, Icon, Loading, Table, Td, Th } from "styleguide";

import { clearSearch, doSearch } from "../actions/search";
import { fetchUsers } from "../actions/user";
import SearchForm from "../components/SearchForm";
import config from "../config";
import { IState } from "../reducers";
import { createDefaultSearch } from "../services/search";
import { requestUsersSearched } from "../services/user";
import * as errors from "../types/errors";
import ISearch from "../types/search";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";
import debounce from "../utils/debounce";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    users: IUser[];
    error?: errors.HttpError;
    isLoading: boolean;
    offset: number;
    search: ISearch;
}

interface IPropsFromDispatch {
    fetchUsers: typeof fetchUsers;
    doSearch: typeof doSearch;
    clearSearch: typeof clearSearch;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public renderListUsers = () => {
        const { history, users } = this.props;

        return (
            <Col s={12}>
                <Table title="Users">
                    <thead>
                        <tr>
                            <Th size="small">Id</Th>
                            <Th>Login</Th>
                            <Th hide="mobile">Name</Th>
                            <Th>Role</Th>
                            <Th align="right">Actions</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.userId}>
                                <Td>{user.userId}</Td>
                                <Td>{user.userLogin}</Td>
                                <Td hide="mobile">
                                    {user.userFirstName} {user.userLastName}
                                </Td>
                                <Td>{UserRoles[user.userRoleIdList]}</Td>
                                <Td align="right">
                                    <a href={`/users/${user.userId}`}>
                                        <Icon icon="edit" />
                                    </a>
                                </Td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.user}+`)} />
            </Col>
        );
    };

    public renderSearch = () => {
        return <SearchForm onSubmit={this.submitForm} />;
    };

    public submitForm = (search: ISearch | any) => {
        this.props.doSearch(search);

        this.props.fetchUsers(requestUsersSearched(search), UserRoles.INVALID, true);
    };

    public componentDidMount() {
        window.onscroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                debounce(() => {
                    this.props.fetchUsers(requestUsersSearched(this.props.search), UserRoles.INVALID, false);
                });
            }
        };
        this.props.fetchUsers(requestUsersSearched(createDefaultSearch()), UserRoles.INVALID, true);
    }

    public componentWillUnmount() {
        this.props.clearSearch();
        window.onscroll = null;
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Users</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderSearch()}
                {this.renderListUsers()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        error: state.user.error,
        isLoading: state.user.isLoading,
        offset: state.user.offset,
        search: state.search,
        users: state.user.users,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearSearch,
            doSearch,
            fetchUsers,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
