import { FC } from "react";
import styled from "styled-components";

interface IProps {
    children: React.ReactNode;
    overflowHidden?: boolean;
    activeStatus: boolean;
    openOrClosePreviewText: (status: boolean) => void;
    isTextEditorFullScreen?: boolean;
}

const FullScreenPreview: FC<IProps> = ({ children, overflowHidden, activeStatus, openOrClosePreviewText, isTextEditorFullScreen }) => {
    const onButtonClick = () => {
        if (activeStatus) {
            openOrClosePreviewText(false);

            if (!isTextEditorFullScreen) {
                document.body.style.overflow = "auto";
            }
        } else {
            openOrClosePreviewText(true);
            document.body.style.overflow = "hidden";
        }
    };

    return (
        <Wrapper isActive={activeStatus} style={{ overflowY: overflowHidden ? "hidden" : "auto" }}>
            <Header onClick={onButtonClick} isActive={activeStatus}>
                <p>Text Preview</p>
                <button onClick={onButtonClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" fill="white" fill-opacity="0.01" />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.00042 11.0025C8.00042 10.7368 8.10519 10.4819 8.292 10.293C8.38404 10.2002 8.49354 10.1265 8.6142 10.0762C8.73486 10.0259 8.86428 10.0001 8.995 10.0001C9.12572 10.0001 9.25514 10.0259 9.3758 10.0762C9.49646 10.1265 9.60596 10.2002 9.698 10.293L12.005 12.62L14.303 10.303C14.3948 10.2098 14.5043 10.1358 14.625 10.0853C14.7456 10.0348 14.8752 10.0088 15.006 10.0088C15.1368 10.0088 15.2664 10.0348 15.387 10.0853C15.5077 10.1358 15.6172 10.2098 15.709 10.303C15.8956 10.492 16.0002 10.7469 16.0002 11.0125C16.0002 11.2781 15.8956 11.533 15.709 11.722L12.779 14.677C12.566 14.892 12.289 14.999 12.01 14.999C11.731 14.999 11.449 14.892 11.231 14.677L8.292 11.712C8.10519 11.5231 8.00042 11.2682 8.00042 11.0025Z"
                            fill="#42526E"
                        />
                    </svg>
                </button>
            </Header>
            <div>{children}</div>
        </Wrapper>
    );
};

const Wrapper = styled.div<{ isActive: boolean }>`
    position: fixed;
    top: ${(p) => (p.isActive ? "0px" : "calc(100% - 65px)")};
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background: #f4f5f7;
    z-index: 1000;
    overflow-y: ${(p) => (p.isActive ? "auto" : "hidden")};

    transition: 0.2s ease top;

    font-family: Poppins, sans-serif;
    padding: 0px 36px 100px 36px;
    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 1px 1px 0px rgba(9, 30, 66, 0.25);
`;

const Header = styled.div<{ isActive: boolean }>`
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 100;
    background: #f4f5f7;

    cursor: pointer;

    p {
        color: #6b778c;
        font-size: 22px;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    button {
        padding: 10px 0px;
        padding-left: 40px;
        margin: 0;
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        margin-top: ${(p) => (p.isActive ? "4px" : "-2px")};
        // margin-right: ${(p) => (p.isActive ? "13px" : "-2px")};
        transition: 0.15s transform;
        transform: scaleY(${(p) => (p.isActive ? "1" : "-1")});
    }
`;

export default FullScreenPreview;
