import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import AnimateHeight from "react-animate-height";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, ButtonFixed, ButtonFlat, Card, Col, InputDate, InputField, Row } from "styleguide";

import { jQuery } from "../utils/jquery";

interface IState {
    open: boolean;
}

export class SearchForm extends React.Component<InjectedFormProps, IState> {
    constructor(props: InjectedFormProps) {
        super(props);

        this.state = { open: false };
    }

    public open = () => {
        this.setState({ open: !this.state.open });
        jQuery("html, body").animate({ scrollTop: 0 }, "slow");
    };

    public submit = (e: any) => {
        this.setState({ open: false });
        this.props.handleSubmit(e);
    };

    public clear = () => {
        window.location.reload(false);
    };

    public render() {
        const { change } = this.props;
        const { open } = this.state;

        return (
            <React.Fragment>
                <ButtonFixed icon="clear" onClick={this.open} className={open ? "visible" : "hidden"} bottom={100} />
                <ButtonFixed icon="search" onClick={this.open} className={!open ? "visible" : "hidden"} bottom={100} />
                <AnimateHeight height={open ? "auto" : 0}>
                    <Col s={12}>
                        <div className={css(styles.container)}>
                            <form onSubmit={this.submit}>
                                <Card title="Search">
                                    <Row>
                                        <Col s={12}>
                                            <InputField>
                                                <label htmlFor="search-form-text">Name</label>
                                                <Field name="text" type="text" component="input" id="search-form-text" />
                                            </InputField>
                                        </Col>
                                        <Col s={6}>
                                            <InputField>
                                                <label htmlFor="fromDate">From (Created At)</label>
                                                <InputDate change={change} name="fromDate" />
                                            </InputField>
                                        </Col>
                                        <Col s={6}>
                                            <InputField>
                                                <label htmlFor="toDate">To (Created At)</label>
                                                <InputDate change={change} name="toDate" />
                                            </InputField>
                                        </Col>

                                        <Col s={6}>
                                            <div className="center">
                                                <Button text="Search" icon="search" submit={true} />
                                            </div>
                                        </Col>
                                        <Col s={6}>
                                            <div className="center">
                                                <ButtonFlat text="Clear" onClick={this.clear} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </form>
                        </div>
                    </Col>
                </AnimateHeight>
            </React.Fragment>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        margin: "auto",
        maxWidth: 390,
        width: "100%",
    },
});

export default reduxForm({
    enableReinitialize: true,
    form: "SearchForm",
})(SearchForm);
