import { GroupBase, StylesConfig } from "react-select";

export const ReactSelectStylesMulti: StylesConfig<
    {
        label: string;
        value: number;
    },
    true,
    GroupBase<{
        label: string;
        value: number;
    }>
> = {
    control: (base) => {
        return { ...base, borderRadius: "0px", borderTop: "none", borderLeft: "none", borderRight: "none", boxShadow: "none", borderColor: " #9e9e9e" };
    },
    placeholder: (base) => {
        return { ...base, color: "black" };
    },
    menu: (base) => {
        return { ...base, zIndex: 100 };
    },
};

export const ReactSelectStyles: StylesConfig<
    {
        label: string;
        value: number;
    },
    false,
    GroupBase<{
        label: string;
        value: number;
    }>
> = {
    control: (base) => {
        return { ...base, borderRadius: "0px", borderTop: "none", borderLeft: "none", borderRight: "none", boxShadow: "none", borderColor: " #9e9e9e" };
    },
    placeholder: (base) => {
        return { ...base, color: "black" };
    },
    menu: (base) => {
        return { ...base, zIndex: 100 };
    },
};

export const ReactSelectStylesV2: StylesConfig<
    {
        label: string;
        value: number;
    },
    false,
    GroupBase<{
        label: string;
        value: number;
    }>
> = {
    control: (base) => {
        return {
            ...base,
            borderRadius: "3px",
            borderWidth: "2px",
            boxShadow: "none",
            borderColor: "#dfe1e6",
            fontSize: "14px",
            color: "#000",
            ":hover": {
                borderColor: "#039be5",
            },
        };
    },
    valueContainer: (base) => {
        return { ...base, height: "42px", padding: "0px" };
    },
    input: (base) => {
        return { ...base, height: "42px", margin: "0px 10px", padding: "0px" };
    },
    singleValue: (base) => {
        return { ...base, padding: "0px", margin: "0px 10px" };
    },
    placeholder: (base) => {
        return { ...base, color: "#7a869a", margin: "0px 10px" };
    },
    menu: (base) => {
        return { ...base, zIndex: 100 };
    },
};

export const ReactSelectStylesMultiV2: StylesConfig<
    {
        label: string;
        value: number;
    },
    true,
    GroupBase<{
        label: string;
        value: number;
    }>
> = {
    control: (base) => {
        return {
            ...base,
            borderRadius: "3px",
            borderWidth: "2px",
            boxShadow: "none",
            borderColor: "#dfe1e6",
            fontSize: "14px",
            color: "#000",
            ":hover": {
                borderColor: "#039be5",
            },
        };
    },
    valueContainer: (base) => {
        return { ...base, padding: "0px", paddingLeft: "10px" };
    },
    input: (base) => {
        return { ...base, margin: "0px 10px", padding: "0px" };
    },
    singleValue: (base) => {
        return { ...base, padding: "0px", margin: "0px 10px" };
    },
    placeholder: (base) => {
        return { ...base, color: "#7a869a", margin: "0px" };
    },
    menu: (base) => {
        return { ...base, zIndex: 100 };
    },
};
