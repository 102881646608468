import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Navbar } from "styleguide";

import { logout } from "../actions/auth";
import config from "../config";
import { IState } from "../reducers";
import * as errors from "../types/errors";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";

interface IPropsFromState {
    user?: IUser;
    error?: errors.HttpError;
    path: string;
}

interface IPropsFromDispatch {
    logout: typeof logout;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public renderMenuForSuper = () => {
        const { path } = this.props;

        return (
            <>
                <li className={path === config.paths.dashboard ? "active" : ""}>
                    <Link to={config.paths.dashboard}>
                        <i className="material-icons">dashboard</i>
                        Dashboard
                    </Link>
                </li>
                <li className={path === config.paths.article ? "active" : ""}>
                    <Link to={config.paths.article}>
                        <i className="material-icons">view_list</i>
                        Articles
                    </Link>
                </li>
                <li className={path === config.paths.epa ? "active" : ""}>
                    <Link to={config.paths.epa}>
                        <i className="material-icons">dvr</i>
                        EPA
                    </Link>
                </li>
                <li className={path === config.paths.epaV2 ? "active" : ""}>
                    <Link to={config.paths.epaV2}>
                        <i className="material-icons">dvr</i>
                        EPA V2
                    </Link>
                </li>
                <li className={path.match(config.paths.template) ? "active" : ""}>
                    <Link to={config.paths.template}>
                        <i className="material-icons">extension</i>
                        Templates
                    </Link>
                </li>
                <li className={path.match(config.paths.newspaper) ? "active" : ""}>
                    <Link to={config.paths.newspaper}>
                        <i className="material-icons">developer_board</i>
                        Newspapers
                    </Link>
                </li>
                <li className={path.match(config.paths.tag) ? "active" : ""}>
                    <Link to={config.paths.tag}>
                        <i className="material-icons">class</i>
                        Tags
                    </Link>
                </li>
                <li className={path.match(config.paths.audience) ? "active" : ""}>
                    <Link to={config.paths.audience}>
                        <i className="material-icons">assignment</i>
                        Audiences
                    </Link>
                </li>
                <li className={path.match(config.paths.specialsections) ? "active" : ""}>
                    <Link to={config.paths.specialsections}>
                        <i className="material-icons">label</i>
                        Special Sections
                    </Link>
                </li>
                <li className={path.match(config.paths.editorpicks) ? "active" : ""}>
                    <Link to={config.paths.editorpicks}>
                        <i className="material-icons">label</i>
                        Editor's Picks
                    </Link>
                </li>
                <li className={path.match(config.paths.section) ? "active" : ""}>
                    <Link to={config.paths.section}>
                        <i className="material-icons">label</i>
                        Categories
                    </Link>
                </li>
                <li className={path.match(config.paths.playlist) ? "active" : ""}>
                    <Link to={config.paths.playlist}>
                        <i className="material-icons">playlist_play</i>
                        Series &amp; Playlists
                    </Link>
                </li>
                <li className={path.match(config.paths.user) ? "active" : ""}>
                    <Link to={config.paths.user}>
                        <i className="material-icons">group</i>
                        Users
                    </Link>
                </li>
                <li className={path.match(config.paths.journalist) ? "active" : ""}>
                    <Link to={config.paths.journalist}>
                        <i className="material-icons">group</i>
                        Journalists
                    </Link>
                </li>
                <li className={path.match(config.paths.pronunciation) ? "active" : ""}>
                    <Link to={config.paths.pronunciation}>
                        <i className="material-icons">announcement</i>
                        Pronunciations
                    </Link>
                </li>
                <li className={path.match(config.paths.advert) ? "active" : ""}>
                    <Link to={config.paths.advert}>
                        <i className="material-icons">shop</i>
                        Adverts
                    </Link>
                </li>
                <li className={path.match(config.paths.company) ? "active" : ""}>
                    <Link to={config.paths.company}>
                        <i className="material-icons">business</i>
                        Companies
                    </Link>
                </li>
                <li className={path.match(config.paths.setting) ? "active" : ""}>
                    <Link to={config.paths.setting}>
                        <i className="material-icons">settings</i>
                        Settings
                    </Link>
                </li>
                <li>
                    <a href="#" onClick={this.logout}>
                        <i className="material-icons">exit_to_app</i>
                        Sign Out
                    </a>
                </li>
            </>
        );
    };

    public renderMenuForEditor = () => {
        const { path } = this.props;

        return (
            <>
                <li className={path === config.paths.dashboard ? "active" : ""}>
                    <Link to={config.paths.dashboard}>
                        <i className="material-icons">dashboard</i>
                        Dashboard
                    </Link>
                </li>
                <li className={path === config.paths.article ? "active" : ""}>
                    <Link to={config.paths.article}>
                        <i className="material-icons">view_list</i>
                        Articles
                    </Link>
                </li>
                <li className={path === config.paths.epa ? "active" : ""}>
                    <Link to={config.paths.epa}>
                        <i className="material-icons">dvr</i>
                        EPA
                    </Link>
                </li>
                <li className={path.match(config.paths.template) ? "active" : ""}>
                    <Link to={config.paths.template}>
                        <i className="material-icons">extension</i>
                        Templates
                    </Link>
                </li>
                <li className={path.match(config.paths.newspaper) ? "active" : ""}>
                    <Link to={config.paths.newspaper}>
                        <i className="material-icons">developer_board</i>
                        Newspapers
                    </Link>
                </li>
                <li className={path.match(config.paths.tag) ? "active" : ""}>
                    <Link to={config.paths.tag}>
                        <i className="material-icons">class</i>
                        Tags
                    </Link>
                </li>
                <li className={path.match(config.paths.audience) ? "active" : ""}>
                    <Link to={config.paths.audience}>
                        <i className="material-icons">assignment</i>
                        Audiences
                    </Link>
                </li>
                <li className={path.match(config.paths.specialsections) ? "active" : ""}>
                    <Link to={config.paths.specialsections}>
                        <i className="material-icons">label</i>
                        Special Sections
                    </Link>
                </li>
                <li className={path.match(config.paths.editorpicks) ? "active" : ""}>
                    <Link to={config.paths.editorpicks}>
                        <i className="material-icons">label</i>
                        Editor's Picks
                    </Link>
                </li>
                <li className={path.match(config.paths.section) ? "active" : ""}>
                    <Link to={config.paths.section}>
                        <i className="material-icons">label</i>
                        Categories
                    </Link>
                </li>
                <li className={path.match(config.paths.playlist) ? "active" : ""}>
                    <Link to={config.paths.playlist}>
                        <i className="material-icons">playlist_play</i>
                        Series &amp; Playlists
                    </Link>
                </li>
                <li className={path.match(config.paths.user) ? "active" : ""}>
                    <Link to={config.paths.user}>
                        <i className="material-icons">group</i>
                        Users
                    </Link>
                </li>
                <li className={path.match(config.paths.journalist) ? "active" : ""}>
                    <Link to={config.paths.journalist}>
                        <i className="material-icons">group</i>
                        Journalists
                    </Link>
                </li>
                <li className={path.match(config.paths.pronunciation) ? "active" : ""}>
                    <Link to={config.paths.pronunciation}>
                        <i className="material-icons">announcement</i>
                        Pronunciations
                    </Link>
                </li>
                <li>
                    <a href="#" onClick={this.logout}>
                        <i className="material-icons">exit_to_app</i>
                        Sign Out
                    </a>
                </li>
            </>
        );
    };

    public renderMenuForNewspaper = () => {
        const { path } = this.props;

        return (
            <>
                <li className={path === config.paths.dashboard ? "active" : ""}>
                    <Link to={config.paths.dashboard}>
                        <i className="material-icons">dashboard</i>
                        Dashboard
                    </Link>
                </li>
                <li className={path === config.paths.article ? "active" : ""}>
                    <Link to={config.paths.article}>
                        <i className="material-icons">view_list</i>
                        Articles
                    </Link>
                </li>
                <li className={path.match(config.paths.user) ? "active" : ""}>
                    <Link to={config.paths.user}>
                        <i className="material-icons">group</i>
                        Users
                    </Link>
                </li>
                <li>
                    <a href="#" onClick={this.logout}>
                        <i className="material-icons">exit_to_app</i>
                        Sign Out
                    </a>
                </li>
            </>
        );
    };

    public renderMenuForNarrator = () => {
        const { path } = this.props;

        return (
            <>
                <li className={path === config.paths.dashboard ? "active" : ""}>
                    <Link to={config.paths.dashboard}>
                        <i className="material-icons">dashboard</i>
                        Dashboard
                    </Link>
                </li>
                <li className={path === config.paths.article ? "active" : ""}>
                    <Link to={config.paths.article}>
                        <i className="material-icons">view_list</i>
                        Articles
                    </Link>
                </li>
                <li>
                    <a href="#" onClick={this.logout}>
                        <i className="material-icons">exit_to_app</i>
                        Sign Out
                    </a>
                </li>
            </>
        );
    };

    public renderMenuForAdvertising = () => {
        const { path } = this.props;

        return (
            <>
                <li className={path.match(config.paths.advert) ? "active" : ""}>
                    <Link to={config.paths.advert}>
                        <i className="material-icons">shop</i>
                        Adverts
                    </Link>
                </li>
                {/*
                <li className={path.match(config.paths.user) ? "active" : ""}>
                    <Link to={config.paths.user}>
                        <i className="material-icons">group</i>
                        Users
                    </Link>
                </li>
                */}
                <li>
                    <a href="#" onClick={this.logout}>
                        <i className="material-icons">exit_to_app</i>
                        Sign Out
                    </a>
                </li>
            </>
        );
    };

    public logout = () => {
        this.props.logout();
        this.props.history.push(config.paths.login);
    };

    public render() {
        const { user } = this.props;

        if (!user) {
            return null;
        }

        const userRoleId = user && user.userRoleIdList;
        const userRoleName = user.userRoleIdList ? UserRoles[user.userRoleIdList] : "";

        return (
            <Navbar
                className="noprint"
                hasUser={true}
                logo="/logo.png"
                name={user.userLogin}
                description={`(${userRoleName})`}
                onClickLogo={() => window.location.replace(config.paths.dashboard)}
                renderRight={
                    <>
                        <li>v{process.env.REACT_APP_VERSION}&nbsp;&nbsp;&nbsp;&nbsp;|</li>
                        <li>
                            <a onClick={this.logout}>
                                <span className="left">Sign Out</span>
                                <i className="material-icons right">exit_to_app</i>
                            </a>
                        </li>
                    </>
                }
            >
                {userRoleId === UserRoles.SUPER_USER && this.renderMenuForSuper()}
                {userRoleId === UserRoles.NARRATOR && this.renderMenuForNarrator()}
                {userRoleId === UserRoles.NEWSPAPER_ADMIN && this.renderMenuForNewspaper()}
                {userRoleId === UserRoles.EDITOR && this.renderMenuForEditor()}
                {userRoleId === UserRoles.ADVERTISING && this.renderMenuForAdvertising()}
            </Navbar>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        error: state.auth.error,
        path: window.location.pathname,
        user: state.auth.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            logout,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
