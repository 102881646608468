import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";

import { Clickable, Icon, Table, Td, Th } from "styleguide";
import config from "../config";
import IAudience from "../types/audience";
import AudienceTypes from "../types/audience-types";
import IPlaylist from "../types/playlist";
import IArticle from "src/types/article";
import { SpecialSectionArticle, SpecialSectionItem, SpecialSectionPlaylist } from "src/types/special-section";

interface IProps {
    audiences: IAudience[];
    playlists: IPlaylist[];
    articles: IArticle[];
    name: string;
    removeItem: (itemId: number) => void;
    moveUp: (itemId: number) => void;
    moveDown: (itemId: number) => void;
}

export class SpecialSectionV2 extends React.Component<IProps> {
    public openEditPlaylist = (item: SpecialSectionItem) => {
        if (item.type === "PLAYLIST") {
            window.open(`${config.paths.playlist}/${item.playlistId}`, "_blank");
        } else {
            window.open(`${config.paths.article}/${item.articleID}`, "_blank");
        }
    };

    public getDataToRender(item: SpecialSectionItem) {
        if (item.type === "PLAYLIST") {
            return {
                key: item.playlistId,
                isPublished: item.playlistIsPublished === 1,
                order: item.specialsectionscontent!.order,
                format: "Series",
                title: item.playlistName,
                categories: item.sections && item.sections.length ? item.sections.map((s) => s.sectionName).join(", ") : <div style={{ transform: "translateY(-4px)" }}>__</div>,
            };
        }

        return {
            key: item.articleID,
            isPublished: item.articleIsPublished === 1,
            order: item.specialsectionscontent!.order,
            format: "Article",
            title: item.articleName,
            categories: item.sections && item.sections.length ? item.sections.map((s) => s.sectionName).join(", ") : <div style={{ transform: "translateY(-4px)" }}>__</div>,
        };
    }

    public render() {
        const { name, audiences, playlists, articles } = this.props;

        const regions = _.filter(audiences, (a) => a.audienceType === AudienceTypes.Region);

        const itemsOrdered: SpecialSectionItem[] = [
            ...(articles.map((article) => ({ ...article, type: "ARTICLE" })) as SpecialSectionArticle[]),
            ...(playlists.map((playlist) => ({ ...playlist, type: "PLAYLIST" })) as SpecialSectionPlaylist[]),
        ].sort((a, b) => a.specialsectionscontent!.order - b.specialsectionscontent!.order);

        return (
            <div>
                <Table title={name}>
                    <thead>
                        <tr>
                            <Th size="small">Id</Th>
                            <Th size="medium" align="center">
                                Actions
                            </Th>
                            <Th size="small" align="center">
                                Rank
                            </Th>
                            <Th size="small" align="center">
                                Format
                            </Th>
                            <Th size="big">Title</Th>
                            <Th size="medium">Categories</Th>
                            {regions.map((r) => {
                                const total = _.chain(itemsOrdered)
                                    .map((item) => (item.type === "PLAYLIST" && item.audiences ? item.audiences : []))
                                    .flatten()
                                    .filter((a) => a.audienceId === r.audienceId)
                                    .size()
                                    .value();

                                return (
                                    <Th key={r.audienceId} className={css(styles.region)} size="small" align="center">
                                        <span className={css(r.playlists && total === 0 && styles.red)}>
                                            {r.audienceName} ({total})
                                        </span>
                                    </Th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {itemsOrdered.map((item, i) => {
                            const { categories, format, isPublished, key, order, title } = this.getDataToRender(item);

                            return (
                                <tr key={key}>
                                    <Td>{key}</Td>
                                    <Td align="center">
                                        {isPublished ? (
                                            <Icon icon="cloud_done" className={`${css(styles.paddingLeft, styles.paddingRight)} green-text`} />
                                        ) : (
                                            <Icon icon="cloud_queue" className={`${css(styles.paddingLeft, styles.paddingRight)} blue-text`} />
                                        )}
                                        <Clickable onClick={() => this.props.removeItem(item.specialsectionscontent!.id)} className={css(styles.paddingLeft, styles.paddingRight)}>
                                            <Icon icon="cancel" className={css(styles.red)} />
                                        </Clickable>
                                        {i !== 0 ? (
                                            <Clickable onClick={() => this.props.moveUp(item.specialsectionscontent!.id)} className={css(styles.paddingLeft, styles.paddingRight)}>
                                                <Icon icon="arrow_upward" />
                                            </Clickable>
                                        ) : (
                                            <Icon icon="arrow_upward" className={css(styles.grey, styles.paddingLeft, styles.paddingRight)} />
                                        )}

                                        {i !== itemsOrdered.length - 1 ? (
                                            <Clickable onClick={() => this.props.moveDown(item.specialsectionscontent!.id)} className={css(styles.paddingLeft, styles.paddingRight)}>
                                                <Icon icon="arrow_downward" />
                                            </Clickable>
                                        ) : (
                                            <Icon icon="arrow_downward" className={css(styles.grey, styles.paddingLeft, styles.paddingRight)} />
                                        )}
                                        <a href={item.type === "PLAYLIST" ? `/playlists/${item.playlistId}` : `/articles/${item.articleID}`}>
                                            <Icon icon="edit" />
                                        </a>
                                    </Td>
                                    <Td align="center">{order}</Td>
                                    <Td align="center">{format}</Td>
                                    <Td>{title}</Td>
                                    <Td>{categories}</Td>
                                    {regions.map((r) => (
                                        <Td key={r.audienceId} align="center">
                                            {!!_.find(item.type === "PLAYLIST" && item.audiences ? item.audiences : [], (a) => a.audienceId === r.audienceId) ? (
                                                <Icon icon="check" />
                                            ) : (
                                                <div style={{ transform: "translateY(-4px)" }}>_</div>
                                            )}
                                        </Td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    deleteIcon: {
        color: "red",
    },
    grey: {
        color: "grey",
    },
    paddingLeft: {
        paddingLeft: 5,
    },
    paddingRight: {
        paddingRight: 5,
    },
    red: {
        color: "red",
    },
    region: {
        "@media(min-width: 1000px)": {
            width: "40px !important",
            minWidth: "40px !important",
            maxWidth: "40px !important",
        },
        width: "8% !important",
    },
});

export default SpecialSectionV2;
