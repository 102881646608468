import { FC, memo, useMemo, useState } from "react";
import { deletePronunciation, editPronunciation, verifyPronunciation } from "src/actions/pronunciation";
import IPronunciation from "src/types/pronunciation";
import styled from "styled-components";
import PronunciationEditButtons from "../pronunciation/PronunciationEditButtons";
import PronunciationVerifTd from "../pronunciation/PronunciationVerifTd";
import _ from "lodash";
import IUser from "src/types/user";
import { PronunciationsAudioData } from "../ArticleFormEditor/ArticleFormEditor";
import { ScaleLoader } from "react-spinners";

interface IProps {
    articleText: string;
    pronunciations: IPronunciation[];
    editPronunciation: typeof editPronunciation;
    verifyPronunciation: typeof verifyPronunciation;
    deletePronunciation: typeof deletePronunciation;
    user?: IUser;
    pronunciationsAudioData?: PronunciationsAudioData;
    playPronunciation?: (text: string) => void;
}

const Pronunciations: FC<IProps> = ({ pronunciations, editPronunciation, verifyPronunciation, deletePronunciation, articleText, user, pronunciationsAudioData, playPronunciation }) => {
    const [showUnverified, setShowUnverified] = useState(false);

    const usedPronunciations = useMemo(() => {
        if (!pronunciations) {
            return [];
        }

        const pronunciationsToHighlight: Array<{ pron: IPronunciation; word: string }> = [];

        // First, do an inaccurate but cheap filter of pronuncations to reduce the list size
        const pronsContained = pronunciations.filter((pron) => articleText.indexOf(pron.pronunciationName) !== -1);

        // Then do the expensive matching
        pronsContained.forEach((p) => {
            const regex = new RegExp("\\b" + _.escapeRegExp(p.pronunciationName) + "\\b", "g");
            const match = articleText.match(regex);

            if (Array.isArray(match) && match.length > 0) {
                const word = match[0];
                pronunciationsToHighlight.push({ pron: p, word });
            }
        });

        return pronunciationsToHighlight;
    }, [pronunciations, articleText]);

    const playIcon = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="white" fill-opacity="0.01" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.595 19.5219C7.162 20.3639 6 19.7019 6 18.0399V5.96289C6 4.29989 7.158 3.63489 8.595 4.47889L19.678 10.9889C20.629 11.5479 20.636 12.4489 19.678 13.0119L8.595 19.5219Z"
                fill="#42526E"
            />
        </svg>
    );

    const reloadIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="24px" height="24px">
            <g
                fill="#42526e"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
            >
                <g transform="scale(8.53333,8.53333)">
                    <path d="M15,3c-2.94691,0 -5.67058,1.08978 -7.74414,2.83594c-0.27716,0.2291 -0.40998,0.58936 -0.34789,0.94355c0.0621,0.35419 0.30956,0.64777 0.64813,0.76892c0.33857,0.12115 0.71611,0.05121 0.98882,-0.18317c1.72644,-1.45384 4.00199,-2.36523 6.45508,-2.36523c5.22661,0 9.45668,3.91362 9.95117,9h-2.95117l4,6l4,-6h-3.05078c-0.508,-6.16514 -5.65128,-11 -11.94922,-11zM4.30078,9l-4,6h2.69922c0,6.63552 5.36448,12 12,12c2.94691,0 5.67058,-1.08978 7.74414,-2.83594c0.27717,-0.2291 0.41,-0.58936 0.3479,-0.94356c-0.0621,-0.35419 -0.30957,-0.64778 -0.64814,-0.76893c-0.33857,-0.12115 -0.71612,-0.0512 -0.98883,0.18319c-1.72644,1.45384 -4.00199,2.36523 -6.45508,2.36523c-5.56448,0 -10,-4.43552 -10,-10h3.30078z"></path>
                </g>
            </g>
        </svg>
    );

    const filteredPronunciations = showUnverified ? usedPronunciations.filter(({ pron }) => !pron.verifiedAt) : usedPronunciations;

    return (
        <Wrapper>
            <Filters>
                <p>Show me</p>
                <label>
                    <input type="checkbox" checked={showUnverified} onChange={() => setShowUnverified(!showUnverified)} /> <p>Unverified (5)</p>
                </label>
            </Filters>
            <TableWrapper>
                <Table>
                    <thead>
                        <tr>
                            <th>Text</th>
                            <th>Phonetics</th>
                            <th style={{ minWidth: "100px" }}>AI Phonetics</th>
                            <th>Link</th>
                            <th>Verified</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPronunciations.map((p) => {
                            const { pron } = p;
                            const shouldPlay = !!pron.aiPhonetic && !!playPronunciation && !!pronunciationsAudioData;
                            const pronObj = pronunciationsAudioData?.find((p) => p.name === pron.aiPhonetic);
                            const isLoading = !!pronObj && pronObj.status === "loading";
                            // const isLoaded = !!pronObj && pronObj.status === "loaded"
                            const isFailed = !!pronObj && pronObj.status === "loaded" && !pronObj.data;

                            return (
                                <tr key={pron.pronunciationId}>
                                    <td style={{ minWidth: "140px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                        {shouldPlay && (
                                            <button
                                                style={{
                                                    padding: 0,
                                                    margin: 0,
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                    width: 28,
                                                    height: 24,
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    !isLoading && playPronunciation!(pron.aiPhonetic!);
                                                }}
                                            >
                                                {isLoading ? <ScaleLoader width={2} height={17} margin={1} radius={1} color="#42526e" /> : isFailed ? reloadIcon : playIcon}
                                            </button>
                                        )}
                                        <span>{pron.pronunciationName.replace("~", "")}</span>
                                    </td>
                                    <td>{pron.pronunciationComment}</td>
                                    <td>{pron.aiPhonetic}</td>
                                    <td>
                                        {pron.pronunciationLink && (
                                            <a href={pron.pronunciationLink} target="_blank" rel="noreferrer">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="24" height="24" fill="white" fill-opacity="0.01" />
                                                    <path
                                                        d="M19.005 19C19.002 19 19 19.002 19 19.002L19.005 19ZM5 19.006C5 19.002 4.998 19 4.995 19H5V19.006ZM19 19V13H21V19.002C21.0003 19.2642 20.9489 19.5238 20.8487 19.7662C20.7486 20.0085 20.6017 20.2287 20.4164 20.4143C20.2312 20.5998 20.0112 20.7471 19.769 20.8475C19.5268 20.948 19.2672 20.9998 19.005 21H4.995C4.46615 20.9997 3.95902 20.7896 3.58497 20.4157C3.21092 20.0418 3.00053 19.5348 3 19.006V4.99396C3 3.89296 3.896 2.99996 4.997 2.99996H11V4.99996H5V19H19ZM5 19.006C5 19.002 4.998 19 4.995 19H5V19.006ZM11 4.99996H5V19H19V13H21V19.002C21.0003 19.2642 20.9489 19.5238 20.8487 19.7662C20.7486 20.0085 20.6017 20.2287 20.4164 20.4143C20.2312 20.5998 20.0112 20.7471 19.769 20.8475C19.5268 20.948 19.2672 20.9998 19.005 21H4.995C4.46615 20.9997 3.95902 20.7896 3.58497 20.4157C3.21092 20.0418 3.00053 19.5348 3 19.006V4.99396C3 3.89296 3.896 2.99996 4.997 2.99996H11V4.99996ZM19 4.99996V7.99996C19 8.26518 19.1054 8.51953 19.2929 8.70707C19.4804 8.8946 19.7348 8.99996 20 8.99996C20.2652 8.99996 20.5196 8.8946 20.7071 8.70707C20.8946 8.51953 21 8.26518 21 7.99996V3.99996C21 3.73474 20.8946 3.48039 20.7071 3.29285C20.5196 3.10532 20.2652 2.99996 20 2.99996H16C15.7348 2.99996 15.4804 3.10532 15.2929 3.29285C15.1054 3.48039 15 3.73474 15 3.99996C15 4.26518 15.1054 4.51953 15.2929 4.70707C15.4804 4.8946 15.7348 4.99996 16 4.99996H19Z"
                                                        fill="#42526E"
                                                    />
                                                    <path
                                                        d="M12.707 12.707L20.707 4.70696C20.8025 4.61471 20.8787 4.50437 20.9311 4.38236C20.9835 4.26036 21.0111 4.12914 21.0123 3.99636C21.0134 3.86358 20.9881 3.7319 20.9378 3.60901C20.8875 3.48611 20.8133 3.37446 20.7194 3.28056C20.6255 3.18667 20.5138 3.11242 20.391 3.06214C20.2681 3.01186 20.1364 2.98655 20.0036 2.98771C19.8708 2.98886 19.7396 3.01645 19.6176 3.06886C19.4956 3.12127 19.3852 3.19745 19.293 3.29296L11.293 11.293C11.1108 11.4816 11.01 11.7342 11.0123 11.9964C11.0146 12.2586 11.1198 12.5094 11.3052 12.6948C11.4906 12.8802 11.7414 12.9854 12.0036 12.9876C12.2658 12.9899 12.5184 12.8891 12.707 12.707Z"
                                                        fill="#42526E"
                                                    />
                                                </svg>
                                            </a>
                                        )}
                                    </td>
                                    <PronunciationVerifTd user={user} pronunciation={pron} verifyPronunciation={verifyPronunciation} />
                                    <td style={{ padding: 0 }}>
                                        <PronunciationEditButtons user={user} pronunciation={pron} deletePronunciation={deletePronunciation} editPronunciation={editPronunciation} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </TableWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div``;

const TableWrapper = styled.div`
    max-height: 500px !important;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #dfe1e6;
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        cursor: pointer;
    }
`;

const Table = styled.table`
    tr {
        border-bottom: none;
    }
    td {
        font-size: 14px;
        color: #172b4d !important;
        padding: 8px 0px;
        padding-right: 15px;
    }
    thead {
        border-bottom: 2px solid #dfe1e6;

        th {
            font-size: 13px;
            font-weight: 600;
            color: #6b778c;
            padding: 0;
            padding-bottom: 7px;
            padding-right: 10px;
        }
    }
`;

export const Filters = styled.div`
    margin-bottom: 40px;

    &,
    label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    p {
        margin: 0;
        padding: 0;
        color: #505f79;
        font-size: 14px;
    }

    label {
        cursor: pointer;
        user-select: none;

        margin-left: 20px;

        input {
            position: initial !important;
            opacity: 1 !important;
        }

        p {
            color: #091e42;
            margin-left: 5px;
        }
    }
`;

export default memo(Pronunciations, (prev, next) => {
    if (
        prev.pronunciationsAudioData?.reduce((acc, curr) => (curr.status === "loading" ? acc + 1 : acc), 0) !==
        next.pronunciationsAudioData?.reduce((acc, curr) => (curr.status === "loading" ? acc + 1 : acc), 0)
    )
        return false;

    if (!next.articleText.length) return true;

    if (prev.articleText !== next.articleText) return false;

    if (prev.pronunciations !== next.pronunciations || prev.pronunciations.length !== next.pronunciations.length) return false;

    return true;
});
