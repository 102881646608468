import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";

import { Icon, Table, Td, Th } from "styleguide";
import config from "../config";
import IAudience from "../types/audience";
import AudienceTypes from "../types/audience-types";
import IPlaylist from "../types/playlist";

interface IProps {
    audiences: IAudience[];
}

export class AudienceList extends React.Component<IProps> {
    public openEditPlaylist = (id: number) => {
        window.open(`${config.paths.playlist}/${id}`, "_blank");
    };

    public playlistsFromAudiences = () => {
        const { audiences } = this.props;

        return _.chain(audiences)
            .map((a) => a.playlists || [])
            .flatten()
            .uniqBy((p) => p.playlistId)
            .map((playlist) => ({ ...playlist, audiences: _.filter(audiences, (a) => _.find(a.playlists, (p) => p.playlistId === playlist.playlistId)) as IAudience[] }))
            .value();
    };

    public render() {
        const { audiences } = this.props;

        const regions = _.filter(audiences, (a) => a.audienceType === AudienceTypes.Region);
        const playlists = this.playlistsFromAudiences();

        return (
            <div>
                <Table title="Audiences">
                    <thead>
                        <tr>
                            <Th size="small">Id</Th>
                            <Th size="small" align="center">
                                Actions
                            </Th>
                            <Th>Story Name</Th>
                            {regions.map((r) => {
                                const total = _.chain(playlists)
                                    .map((p) => p.audiences)
                                    .flatten()
                                    .filter((a) => a.audienceId === r.audienceId)
                                    .size()
                                    .value();

                                return (
                                    <Th key={r.audienceId} size="small" align="center">
                                        <span className={css(r.playlists && r.playlists.length === 0 && styles.red)}>
                                            {r.audienceName} ({total})
                                        </span>
                                    </Th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {playlists.map((p: IPlaylist) => (
                            <tr key={p.playlistId}>
                                <Td>{p.playlistId}</Td>
                                <Td align="center">
                                    <a href={`/playlists/${p.playlistId}`} className={css(styles.paddingLeft, styles.paddingRight)}>
                                        <Icon icon="edit" />
                                    </a>
                                </Td>
                                <Td>{p.playlistName}</Td>
                                {regions.map((r) => (
                                    <Td key={r.audienceId} align="center">
                                        {!!_.find(p.audiences, (a) => a.audienceId === r.audienceId) && <Icon icon="check" />}{" "}
                                    </Td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    deleteIcon: {
        color: "red",
    },
    disableIcon: {
        color: "grey",
    },
    paddingLeft: {
        paddingLeft: 5,
    },
    paddingRight: {
        paddingRight: 5,
    },
    red: {
        color: "red",
    },
});

export default AudienceList;
