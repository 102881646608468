import { Dispatch } from "redux";
import { toast } from "styleguide";

import config from "../config";
import fetch from "../utils/fetch";

import responseCheck from "../utils/response-check";
import { FETCH_EL_VOICES_FAILURE, FETCH_EL_VOICES_REQUEST, FETCH_EL_VOICES_SUCCESS } from "src/constants/elevenlabs";
import { IELVoice } from "src/types/eleven-labs";

export const fetchELVoices = () => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_EL_VOICES_REQUEST });

    const url = `${config.api.url}${config.api.paths.elevenlabs}/voices`;

    return fetch(url)
        .then(responseCheck)
        .then((voices) => dispatch(fetchPodcastPlatformsSuccess(voices)))
        .catch((error) => dispatch(fetchPodcastPlatformsFailure(error)));
};

const fetchPodcastPlatformsSuccess = (voices: IELVoice[]) => {
    return { type: FETCH_EL_VOICES_SUCCESS, voices };
};

const fetchPodcastPlatformsFailure = (error: Error) => {
    toast("Error fetching Eleven Lab's Voices");
    return { type: FETCH_EL_VOICES_FAILURE, error };
};
