export const downloadArticleAudioUrl = (filename?: string, audioUrl?: string) => {
    console.log(audioUrl, filename);
    if (!audioUrl || !filename) {
        return;
    }

    return fetch(`${audioUrl}`)
        .then((response) => response.blob())
        .then((blob) => {
            const u = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = u;
            a.download = `${filename}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
};

export const getAudioDurationFromUrl = (url: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let audio = document.createElement("audio");
        audio.src = url;

        audio.addEventListener("loadedmetadata", () => resolve(audio.duration), false);
        audio.addEventListener("error", () => reject(), false);
        audio.addEventListener("abort", () => reject(), false);
    });
};
