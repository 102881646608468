import ArticleTranscriptGenerationStatus from "src/types/article-transcript-generation-status";
import { generateRandomString } from "src/utils/string";
import FileSaver from "file-saver";
import { IDiffExtendedItem, IDiffExtendedItemWithGroup, ILineBreak, IPron, IQaPron, ITranscript } from "./types";
import ArticleNarrationTypes from "src/types/article-narration-types";
import IArticle from "src/types/article";
import _ from "lodash";

export const isTranscriptAvailable = (transcriptStatus?: ArticleTranscriptGenerationStatus) => {
    if (transcriptStatus === ArticleTranscriptGenerationStatus.SUCCESS) return true;

    return false;
};

export const isTranscriptFailed = (transcriptStatus?: ArticleTranscriptGenerationStatus) => {
    if (transcriptStatus === ArticleTranscriptGenerationStatus.FAILURE) return true;

    return false;
};

export const isTranscriptPending = (transcriptStatus?: ArticleTranscriptGenerationStatus) => {
    if (transcriptStatus === ArticleTranscriptGenerationStatus.PENDING) return true;

    return false;
};

export const detectGroupsAndAddIdsToDiff = (diff: IDiffExtendedItem[]): IDiffExtendedItemWithGroup[] => {
    const groupTypes = ["INSERTION", "DELETION"];

    let diffTransformed = [...diff];

    const updateDiffTransformedBasedOnThePos = (startPos: number | null, endPos: number | null) => {
        if (startPos !== null && endPos !== null && startPos !== endPos) {
            const groupId = generateRandomString();
            let groupItemPos = 0;
            for (let lStartPos = startPos; lStartPos <= endPos; lStartPos++) {
                const diffTransItem = { ...diffTransformed[lStartPos], groupId, groupItemPos };
                diffTransformed[lStartPos] = diffTransItem;

                groupItemPos += 1;
            }
        }
    };

    let startPos: number | null = null;
    let endPos: number | null = null;
    let currentType: string | null = null;

    for (let pos = 0; pos < diff.length; pos++) {
        const diffItem = diff[pos];
        currentType = diffItem.type;

        if (groupTypes.includes(diffItem.type) && (currentType === null || currentType === diffItem.type)) {
            endPos = pos;

            if (startPos === null) {
                startPos = pos;
            }

            if (pos === diff.length - 1) {
                updateDiffTransformedBasedOnThePos(startPos, endPos);
            }
        } else {
            updateDiffTransformedBasedOnThePos(startPos, endPos);

            startPos = null;
            endPos = null;
        }
    }

    return diffTransformed as IDiffExtendedItemWithGroup[];
};

const normalizeWord = (text: string) => {
    return text
        .trim()
        .split(" ")
        .filter((i) => !!i.trim().length)
        .join(" ")
        .toLowerCase()
        .replace(/[#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, "");
};

export function mergeDiffItemsWithByGroupId<T extends IDiffExtendedItemWithGroup>(diff: T[]): T[] {
    return diff.reduce((prev, curr) => {
        const groupItemIndex = prev.findIndex((item) => !!curr.groupId && item.groupId === curr.groupId);

        const prevCloned = [...prev];

        if (groupItemIndex === -1) {
            return [...prevCloned, curr];
        }

        const item = { ...prevCloned[groupItemIndex] };

        if (curr.type === "INSERTION") {
            item.hypothesis = `${item.hypothesis} ${curr.hypothesis}`;
        } else if (curr.type === "DELETION") {
            item.reference = `${item.reference} ${curr.reference}`;
        }

        prevCloned[groupItemIndex] = item;

        return prevCloned;
    }, [] as T[]);
}

export const addTimestampsToDiff = (diff: IDiffExtendedItem[], transcript: ITranscript["transcript"]) => {
    const words = transcript.map((i) => i.alternatives.map((a) => a.words.map((w) => w))).flat(2);

    const getTextByType = (item: IDiffExtendedItem | null) => {
        if (!item) return null;

        switch (item.type) {
            case "DELETION":
                return item.reference || null;
            case "INSERTION":
                return item.hypothesis || null;
            case "SUBSTITUTION":
                return item.hypothesis || null;
            case "EQUAL":
                return item.reference || null;
            default:
                return null;
        }
    };

    const getDeletionItemStartAndEndTime = (actualWord: string, nextWord: string) => {
        let wordFound = null;
        let nWordFound = null;

        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            if (normalizeWord(word.word) === normalizeWord(actualWord)) {
                const nWord = words?.[i + 1];

                if (!!nWord && normalizeWord(nWord.word) === normalizeWord(nextWord)) {
                    wordFound = word;
                    nWordFound = nWord;
                    break;
                }
            }
        }

        return wordFound && nWordFound && { st: Number(`${wordFound.endTime.seconds}.${wordFound.endTime.nanos}`), et: Number(`${nWordFound.startTime.seconds}.${nWordFound.startTime.nanos}`) };
    };

    const getItemStartAndEndTime = (actualWord: string, prevWord: string, prevWord2: string | null, prevWord3: string | null, nextWord: string, nextWord2: string | null, nextWord3: string | null) => {
        let wordFound = null;

        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            if (normalizeWord(word.word) === normalizeWord(actualWord)) {
                const pWord = words?.[i - 1];
                const pWord2 = words?.[i - 2];
                const pWord3 = words?.[i - 3];

                const nWord = words?.[i + 1];
                const nWord2 = words?.[i + 2];
                const nWord3 = words?.[i + 3];

                const isPrevMatched = normalizeWord(prevWord) === (pWord && normalizeWord(pWord.word));
                const isPrev2Matched = (prevWord2 && normalizeWord(prevWord2)) === (pWord2 && normalizeWord(pWord2.word));
                const isPrev3Matched = (prevWord3 && normalizeWord(prevWord3)) === (pWord3 && normalizeWord(pWord3.word));

                const isNextMatched = normalizeWord(nextWord) === (nWord && normalizeWord(nWord.word));
                const isNext2Matched = (nextWord2 && normalizeWord(nextWord2)) === (nWord2 && normalizeWord(nWord2.word));
                const isNext3Matched = (nextWord3 && normalizeWord(nextWord3)) === (nWord3 && normalizeWord(nWord3.word));
                // if (actualWord === "says" && prevWord2 === "games") {
                //     console.log(prevWord3, prevWord2, prevWord, " <=> ", actualWord, " <=> ", nextWord3, nextWord2, nextWord);
                //     console.log(pWord3, pWord2, pWord, " <=> ", word, " <=> ", nWord3, nWord2, nWord);
                // }

                if (
                    !(prevWord && !isPrevMatched) &&
                    !(prevWord2 && !isPrev2Matched) &&
                    !(prevWord3 && !isPrev3Matched) &&
                    !(nextWord && !isNextMatched) &&
                    !(nextWord2 && !isNext2Matched) &&
                    !(nextWord3 && !isNext3Matched)
                ) {
                    wordFound = word;
                    break;
                }
            }
        }

        return wordFound && { st: Number(`${wordFound.startTime.seconds}.${wordFound.startTime.nanos}`), et: Number(`${wordFound.endTime.seconds}.${wordFound.endTime.nanos}`) };
    };

    const diffClone = diff.map((i) => ({ ...i }));
    const diffLength = diff.length;

    let i = 0;
    while (i < diffLength) {
        const item = diff[i];
        const prevItem = i > 0 ? diff[i - 1] : null;
        let nextItem = i < diffLength - 1 ? diff[i + 1] : null;

        let updatedI: number | null = null;

        // Doing this because deletions does not exist in the transcript
        // so here finding the next non-deletion word if there are multiple deletions in a row
        if (item?.type === "DELETION" && nextItem?.type === "DELETION") {
            for (let j = i + 1; j < diffLength; j++) {
                const currItem = j < diffLength - 1 ? diff[j] : null;

                if (currItem === null || currItem.type !== "DELETION") {
                    nextItem = currItem;
                    updatedI = j;
                    break;
                }
            }
        }

        const word = getTextByType(item);
        const prevWord = getTextByType(prevItem);
        const nextWord = getTextByType(nextItem);

        let startTime = null;
        let endTime = null;

        if (!word) startTime = null;
        else if (!prevWord && item.type === "DELETION") startTime = null;
        else if (!prevWord) {
            const firstWord = words?.[0];
            if (!firstWord) {
                console.log("IF NOT PREV WORD -- FIRST WORD NOT FOUND!");
                startTime = null;
                endTime = null;
            } else {
                startTime = 0;
                endTime = Number(`${firstWord.endTime.seconds}.${firstWord.endTime.nanos}`);
            }
        } else if (!nextWord) {
            const lastWord = words?.[words.length - 1];
            if (!lastWord) {
                console.log("IF NOT NEXT WORD -- FIRST WORD NOT FOUND!");
                startTime = null;
                endTime = null;
            } else {
                startTime = words[words.length - 1].startTime.seconds;
                endTime = Number(`${lastWord.endTime.seconds}.${lastWord.endTime.nanos}`);
            }
        } else {
            if (item.type === "DELETION") {
                const stAndEt = getDeletionItemStartAndEndTime(prevWord, nextWord);

                if (!stAndEt) {
                    startTime = null;
                    endTime = null;
                } else {
                    startTime = stAndEt.st;
                    endTime = stAndEt.et;
                }
            } else {
                // const prevItem = i > 0 ? diff[i - 1] : null;
                // let nextItem = i < diffLength - 1 ? diff[i + 1] : null;

                const prevWord2 = i > 1 ? getTextByType(diff[i - 2]) : null;
                const prevWord3 = i > 2 ? getTextByType(diff[i - 3]) : null;
                const nextWord2 = i < diffLength - 2 ? getTextByType(diff[i + 2]) : null;
                const nextWord3 = i < diffLength - 3 ? getTextByType(diff[i + 3]) : null;
                const stAndEt = getItemStartAndEndTime(word, prevWord, prevWord2, prevWord3, nextWord, nextWord2, nextWord3);

                if (!stAndEt) {
                    startTime = null;
                    endTime = null;
                } else {
                    startTime = stAndEt.st;
                    endTime = stAndEt.et;
                }
            }
        }

        diffClone[i] = { ...diffClone[i], startTime, endTime };

        if (updatedI) {
            // if there are multiple deletions in a row(group of deletions) then\
            // adding the start and the end time to the skipped deletions as well based on the updatedI(see above)
            for (let j = i + 1; j < updatedI; j++) {
                diffClone[j] = { ...diffClone[j], startTime, endTime };
            }

            i = updatedI;
        } else {
            i += 1;
        }
    }

    return diffClone;
};

export const truncateTheWordsText = (text: string, maxLength?: number): string => {
    const mLength = maxLength || 34;
    if (text.length <= mLength) {
        return text;
    }

    const words = text.split(" ");

    let startWords = "";
    let endWords = "";

    for (let i = 0; i < words.length; i++) {
        const currWord = words[i];
        if ((startWords + currWord).length <= mLength / 2) {
            startWords = `${startWords} ${currWord}`;
        }
    }

    for (let i = words.length - 1; i >= 0; i--) {
        const currWord = words[i];
        if ((endWords + currWord).length <= mLength / 2) {
            endWords = `${endWords} ${currWord}`;
        }
    }

    return `${startWords} ... ${endWords}`;
};

export const qaIssuesCount = (diff: IDiffExtendedItemWithGroup[]): { insertionsCount: number; deletionsCount: number; substitutionsCount: number } => {
    return diff.reduce(
        (prev, curr) => {
            if (curr.type === "INSERTION") return { ...prev, insertionsCount: prev.insertionsCount + 1 };
            if (curr.type === "DELETION") return { ...prev, deletionsCount: prev.deletionsCount + 1 };
            if (curr.type === "SUBSTITUTION") return { ...prev, substitutionsCount: prev.substitutionsCount + 1 };

            return prev;
        },
        { insertionsCount: 0, deletionsCount: 0, substitutionsCount: 0 },
    );
};

export const jsonToCsv = (items: Array<{ [key: string]: string | number | null | undefined }>) => {
    const header = Object.keys(items[0]);
    const headerString = header.join(",");

    const replacer = (key: any, value: any) => value ?? "";

    const rowItems = items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","));

    const csv = [headerString, ...rowItems].join("\r\n");
    return csv;
};

export const exportCsvReport = (title: string, text: string) => {
    const csvData = new Blob([text], { type: "text/csv;charset=utf-8;" });

    FileSaver.saveAs(csvData, `${title}.csv`);
};

export const formatTime = (milliseconds: number) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

    return [hours.toString().padStart(2, "0"), minutes.toString().padStart(2, "0"), seconds.toString().padStart(2, "0")].join(":");
};

export const collectArticleTextForPronunciationsQa = (diff: IDiffExtendedItemWithGroup[], getDiffId?: (id: string, word: string) => string) => {
    return diff
        .reduce((joined, item) => {
            const itemType = item.type;

            if (itemType === "INSERTION" || itemType === "NOISE") return joined;

            // making it lowercase important because the matching words will be made lowercased too.
            // also normalize it too
            const referenceWord = item.reference?.toLocaleLowerCase().replaceAll("’s", "");

            if (!referenceWord) return joined;

            const diffId = getDiffId ? ` ${getDiffId(item.id, referenceWord)}` : "";

            return `${joined} ${referenceWord}${diffId}`;
        }, "")
        .trim();
};

export const collectArticleTextForPronsQaAndAddDiffId = (diff: IDiffExtendedItemWithGroup[]) => {
    /* 
        adding the diff ids to the individual words, so that we can get them back after the prons match\
        and then get diff items using the diff ids for getting the information like startTime etc.
    */

    //! CHANGE THESE VALUES WITH CARE
    //! DIFF IDs must be only chars, numbers and dashes(-). SEE REGEX BELOW.

    const DIFF_ID_PREFIX = "//DIFFID";
    const DIFF_ID_POSTFIX = "DIFFID//";

    // ${word} before and after makes the match more specific and it won't make the \b in regex invalid.
    const diffIdRegexStr = (word: string) => `${word}${DIFF_ID_PREFIX}[-A-Za-z0-9]+${DIFF_ID_POSTFIX}${word}`;
    const getDiffId = (id: string, word: string) => `${word}${DIFF_ID_PREFIX}${id}${DIFF_ID_POSTFIX}${word}`;

    const getPronNameRegex = (pronName: string, plainRegex?: boolean) => {
        const pronNameEscaped = _.escapeRegExp(pronName);
        const attachedIdsWithEachWord = pronNameEscaped
            .split(" ")
            .filter((i) => !!i.length)
            .map((word) => `${word} ${diffIdRegexStr(word)}`)
            .join(" ");

        if (plainRegex) {
            return attachedIdsWithEachWord;
        }

        return new RegExp("(?<=\\s|^|[^\\w])" + attachedIdsWithEachWord + "(?=\\s|$|[^\\w])", "g");
    };

    const getPronsAndDiffIds = (prons: IPron[]) => {
        return prons.map((pron) => {
            const { word } = pron;

            const split = word.split(" ").filter((i) => !!i.length);

            // every grouped item contains the word(0th pos) and the diff ID(1st pos)
            const itemsGrouped: string[] = split.reduce((grouped, current, i) => {
                const cloned = [...grouped];
                const index = i + 1;

                if (index % 2 === 0) {
                    const lastGroupIndex = cloned.length - 1;
                    cloned[lastGroupIndex] = [cloned[lastGroupIndex][0], current];
                } else {
                    cloned.push([current]);
                }

                return cloned;
            }, [] as any[]);

            const actualWord = itemsGrouped.map((i) => i[0]).join(" ");

            const diffIds = itemsGrouped.map((item) => {
                const itemWord = item[0];

                // Id is like this: `${itemWord}${DIFF_ID_PREFIX}${id}${DIFF_ID_POSTFIX}${itemWord}`;
                const rawId = item[1];
                const diffId = rawId.replace(`${itemWord}${DIFF_ID_PREFIX}`, "").replace(`${DIFF_ID_POSTFIX}${itemWord}`, "");

                return { word: itemWord, id: diffId };
            });

            return { pron, actualWord, diffIds };
        });
    };

    const articleTextJoined = collectArticleTextForPronunciationsQa(diff, getDiffId);

    return { articleText: articleTextJoined, getPronNameRegex, getPronsAndDiffIds };
};

export const isAiGenerationType = (article?: IArticle) => ([ArticleNarrationTypes.AI_GOLD, ArticleNarrationTypes.AI_SILVER] as any[]).includes(article?.articleNarrationType);

export const normalizeQaPronWord = (word: string) => {
    return word
        .replaceAll("-", " ")
        .trim()
        .split(" ")
        .filter((i) => !!i.length)
        .join(" ");
};

export const filterPronsFalsePositives = (prons: IQaPron[]) => {
    const ignoreList = ["for", "do"];
    return prons.filter((pron) => {
        const pronWord = pron.word;
        return !ignoreList.includes(pronWord) && pronWord.length > 1;
    });
};

export const removeDuplicatedPronunciations = (prons: IQaPron[]) => {
    /*
    - If A is present in B with more words then exclude the diffItems of B from A
    - After removing the all the possible diffItems, if no diffItem is left then remove the A 
    */

    const pronsLength = prons.length;
    const pronsIdsToRemove: string[] = [];

    for (let pronAIndex = 0; pronAIndex < pronsLength; pronAIndex++) {
        const pronA = { ...prons[pronAIndex] };
        const pronAWord = pronA.word;

        for (let pronBIndex = 0; pronBIndex < pronsLength; pronBIndex++) {
            const pronB = prons[pronBIndex];
            const pronBWord = pronB.word;

            if (pronBWord.includes(pronAWord) && pronBWord.length > pronAWord.length) {
                // console.log(pronAWord, pronBWord);
                // console.log(pronAWord, pronA.diffItems);
                pronA.diffItems = pronA.diffItems.filter((adi) => !pronB.diffItems.some((bdi) => adi.id === bdi.id));
                // console.log(pronAWord, pronA.diffItems);
            }
        }

        if (!pronA.diffItems.length) {
            pronsIdsToRemove.push(pronA.qaPronId);
        } else {
            prons[pronAIndex] = pronA;
        }
    }

    // console.log(pronsIdsToRemove, prons);

    const pronsFiltered = prons.filter((pron) => !pronsIdsToRemove.includes(pron.qaPronId));

    // console.log(pronsFiltered);

    return pronsFiltered;
};

export const findLineBreaksInTranscript = (articleText: string, diff: IDiffExtendedItemWithGroup[]): ILineBreak[] => {
    if (!articleText.length) return [];

    const normalizeText = (text: string) => {
        return text.toLocaleLowerCase().replace(/[^a-zA-Z0-9 ]/g, "");
    };

    const convertToPlain = (html: string) => {
        var tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;

        return tempDivElement.textContent || tempDivElement.innerText || "";
    };

    const BIG_DASH = "—";

    const paras = convertToPlain(articleText.replaceAll(/(<br\/>)+/g, "\n").trim())
        .split("\n")
        .map((para) =>
            normalizeText(para.replaceAll(BIG_DASH, " ").replaceAll("-", " "))
                .split(" ")
                .filter((i) => !!i.trim().length),
        )
        .filter((para) => !!para.length);

    if (!paras.length) return [];

    const diffFiltered = diff
        .filter((item) => item.type !== "INSERTION")
        .map((item) => {
            if (!item.reference?.includes("—")) return [{ ...item, reference: item.reference && normalizeText(item.reference) }];

            const split = item.reference?.split("—");
            return split.filter((i) => !!i?.trim()?.length).map((i) => ({ ...item, reference: normalizeText(i) }));
        })
        .flat(2);

    const lineBreaks = [];

    // console.log(paras, diffFiltered);

    let lastParaItemFoundIndex = -1;
    for (let paraIndex = 0; paraIndex < paras.length; paraIndex++) {
        const para = paras[paraIndex];
        const paraLength = para.length;

        // getting the last three words of a para and\
        // finding those three words in the diff one after the other
        const lastWord = para[paraLength - 1];
        const secondLastWord = para?.[paraLength - 2];
        const thirdLastWord = para?.[paraLength - 3];
        const fourthLastLastWord = para?.[paraLength - 4];

        // eslint-disable-next-line no-loop-func
        const diffItem = diffFiltered.find((item, itemIndex) => {
            if (itemIndex <= lastParaItemFoundIndex) return undefined;

            const prevItem = diffFiltered?.[itemIndex - 1];
            const prevPrevItem = diffFiltered?.[itemIndex - 2];
            const prevPrevPrevItem = diffFiltered?.[itemIndex - 3];

            const isWordMatched = item?.reference === lastWord;
            const isSecondWordMatched = secondLastWord === prevItem?.reference;
            const isThirdWordMatched = thirdLastWord === prevPrevItem?.reference;
            const isFourthWordMatched = fourthLastLastWord === prevPrevPrevItem?.reference;

            if (isWordMatched && !(secondLastWord && !isSecondWordMatched) && !(thirdLastWord && !isThirdWordMatched) && !(fourthLastLastWord && !isFourthWordMatched)) {
                lastParaItemFoundIndex = itemIndex;
                return item;
            }

            return undefined;
        });

        if (diffItem) {
            const lineBreakItem = {
                diffItem,
                paraIndex,
            };

            lineBreaks.push(lineBreakItem);
        }
    }

    return lineBreaks;
};

export const mergeDiffWithLineBreaksAsNoise = (diff: IDiffExtendedItemWithGroup[], lineBreaks: ILineBreak[]) => {
    return diff.reduce((merged, current) => {
        const isLineBreak = lineBreaks.some((lb) => lb.diffItem.id === current.id);

        let items: IDiffExtendedItemWithGroup[];

        if (isLineBreak) {
            items = [current, { ...current, groupId: undefined, groupItemPos: undefined, lineBreakDiffItem: current, type: "NOISE", pos: 0, id: generateRandomString() }];
        } else {
            items = [current];
        }

        return [...merged, ...items];
    }, [] as IDiffExtendedItemWithGroup[]);
};

export const collectBeforeAndAfterWordsForSelectionItem = (diff: IDiffExtendedItemWithGroup[], firstItemId: string, lastItemId: string) => {
    const firstItemIndex = diff.findIndex((di) => di.id === firstItemId);
    const lastItemIndex = diff.findIndex((di) => di.id === lastItemId);

    if (firstItemIndex === -1 || lastItemIndex === -1) return "";

    const numberOfWordsBefore = 20;
    const numberOfWordsAfter = 20;

    const diffLength = diff.length;

    const startPos = firstItemIndex <= numberOfWordsBefore ? 0 : firstItemIndex - numberOfWordsBefore;
    const endPos = lastItemIndex + numberOfWordsAfter >= diffLength - 1 ? diffLength - 1 : lastItemIndex + numberOfWordsAfter;

    // the reason for +1 is that it finds the excluding the end pos
    const diffSliced = diff.slice(startPos, endPos + 1);

    return diffSliced.reduce((joined, current) => `${joined} ${current.reference || ""}`, "");
};
