import { styled } from "styled-components";

export const Input = styled.input`
    font-family: Poppins, sans-serif !important;
    border: 2px solid #dfe1e6 !important;
    font-size: 14px !important;
    color: #000 !important;
    height: 42px !important;
    border-radius: 3px !important;
    margin: 0 !important;

    padding: 0px 10px !important;
    box-sizing: border-box !important;

    transition: 0.05s ease border-color;

    &:focus {
        border-color: #039be5 !important;
        border-bottom-width: 2px !important;
    }

    &::placeholder {
        color: #7a869a;
        user-select: none;
    }
`;

export const InputTextArea = styled.textarea`
    font-family: Poppins, sans-serif !important;
    border: 2px solid #dfe1e6 !important;
    font-size: 14px !important;
    color: #000 !important;
    border-radius: 3px !important;
    margin: 0 !important;

    max-width: 100%;
    min-width: 100%;
    min-height: 84px;

    padding: 10px !important;
    box-sizing: border-box !important;

    &:focus {
        outline: none !important;
        border-color: #039be5 !important;
        border-bottom-width: 2px !important;
    }

    &::placeholder {
        color: #7a869a;
        user-select: none;
    }
`;
