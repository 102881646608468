import toast from "react-hot-toast";
import { updateAiAudio } from "src/actions/article";
import IArticle from "src/types/article";
import ArticleAudioType from "src/types/article-audio-type";

export const canProjectLatestSnapshotBeFetched = (article?: IArticle) => {
    const isOnPlatformProjectId = !!article?.aiNarrationProjectId;
    const isOffPlatformProject = !!article?.externalAiNarrationProjectId;

    const currentPlatform = article?.articleAudioType;

    if (currentPlatform === ArticleAudioType.BOTH && isOnPlatformProjectId && isOffPlatformProject) return true;
    else if (currentPlatform === ArticleAudioType.INTERNAL && isOnPlatformProjectId) return true;
    else if (currentPlatform === ArticleAudioType.EXTERNAL && isOffPlatformProject) return true;

    return false;
};

export const fetchLatestAiAudioVersion = (article?: IArticle, onSuccess?: () => void, onError?: () => void) => {
    const articleId = article?.articleID;
    const platform = article?.articleAudioType === ArticleAudioType.EXTERNAL ? "external" : article?.articleAudioType === ArticleAudioType.BOTH ? "both" : "internal";

    if (!articleId) return;

    updateAiAudio(articleId, platform)
        .then(() => {
            if (onSuccess) onSuccess();
        })
        .catch(() => {
            if (onError) {
                onError();
            }
            toast.error("Unable to fetch audio, make sure you've at least one recently converted version on ElevenLabs.");
        });
};

export const truncateAudioFileName = (str: string) => {
    if (str.length <= 15) return str;

    return str.substring(0, 15) + "...";
};
