import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";
import { RouteComponentProps } from "react-router-dom";
import ReactSelect, { components } from "react-select";
import CreatableSelect from "react-select/async-creatable";

import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { InputField, ModalConfirm, Tooltipped } from "styleguide";

import toast from "react-hot-toast";
import AudioPlayer from "react-h5-audio-player";

import config from "../../config";

import { updateFile } from "../../actions/file";
import { getWarnWords, missingFieldsToPublish, missingFieldsForSaveWarning } from "../../services/article";
import IArticle from "../../types/article";
import ArticleType from "../../types/article-type";
// import ArticleValue from "../../types/article-value";
import * as errors from "../../types/errors";
// import FileType from "../../types/file-type";
import IJournalist from "../../types/journalist";
import INewspaper from "../../types/newspaper";
import IPlaylist from "../../types/playlist";
import ISection from "../../types/section";
import ITemplate from "../../types/template";
import TemplateTypes from "../../types/template-types";
import IUser from "../../types/user";
import { renderError } from "../../utils/input";
import RichInputText from "../RichInputText";
import IArticleTemplate from "../../types/article-template";
import { deleteArticleTemplate } from "../../actions/articletemplates";
import ArticleAudioType from "../../types/article-audio-type";

import RichInputTextV2 from "../RichInputTextV2";

import { IVoiceRequirement } from "src/types/voice-requirements";
import { ReactSelectStylesV2, ReactSelectStylesMultiV2 } from "src/utils/react-select";
import { IPodcastPlatform } from "src/types/podcast-platforms";
import { createTagManually, searchTags, updateTagName } from "src/actions/tag";
import { addOverwriteTag, disableSelectedTags, getTagFromOverwriteTag, sortTags } from "src/utils/tag";
import { ContentCard } from "src/styles/ContentCard";
import { ItemHeading } from "src/styles/ItemHeading";
import { Input, InputTextArea } from "src/styles/Input";
import { TextButton } from "src/styles/TextButton";
import { CardHeading } from "src/styles/CardHeading";
import RadioGroup from "../RadioGroup";
import ReactDatePicker from "react-datepicker";
import { DatePickerInputWrapper } from "src/styles/DatePickerInputWrapper";
// import { Separator } from "src/styles/Separator";
import ToggleSwitch from "../ToggleSwitch";
import { AiAudioMessage, AiAudioMessageWrapper, AssetHeading, AudioAssetsWrapper, AudioPlatformHeading, ControlsWrapper, PlayerSpeedButton, SliderInputWrapper } from "./styles";
// import NotificationSchedule from "src/types/notification-schedule";
// import PublishSchedule from "src/types/publish-schedule";
import { ButtonV2 } from "src/styles/ButtonV2";
import FileDrop from "../FileDrop";
import FileType from "src/types/file-type";
import IPronunciation from "src/types/pronunciation";
import { deletePronunciation, editPronunciation, verifyPronunciation } from "src/actions/pronunciation";
import Pronunciations from "../Pronunciations";
import ArticleNarrationTypes from "src/types/article-narration-types";
import ArticleAiNarrationStatus from "src/types/article-ai-narration-status";
import { IELVoice } from "src/types/eleven-labs";
import { fetchPronunciation, retryAudioTranscription } from "src/actions/article";
import { isTranscriptAvailable, isTranscriptFailed, isTranscriptPending } from "src/containers/ArticleQa/utils";
import ArticleTranscriptGenerationStatus from "src/types/article-transcript-generation-status";
import { canProjectLatestSnapshotBeFetched, fetchLatestAiAudioVersion } from "./utils";
// import ArticleAiNarrationStatus from "src/types/article-ai-narration-status";

const articleNarrationTypes = [
    { value: ArticleNarrationTypes.HUMAN, text: "Human narration" },
    { value: ArticleNarrationTypes.AI_SILVER, text: "Silver-tier narration" },
    { value: ArticleNarrationTypes.AI_GOLD, text: "Gold-tier narration" },
];

const articleAudioTypes = [
    { value: ArticleAudioType.INTERNAL, text: "On-Platform" },
    { value: ArticleAudioType.EXTERNAL, text: "Off-Platform" },
    { value: ArticleAudioType.BOTH, text: "Both" },
];

const articleTypes = [
    { value: ArticleType.REGULAR, text: "Regular" },
    { value: ArticleType.FLASH, text: "Flash" },
    { value: ArticleType.NUTSHELL, text: "Nutshell" },
    { value: ArticleType.STORY_INTRO, text: "Story Intro" },
    { value: ArticleType.STORY_BRIDGE, text: "Story Bridge" },
    { value: ArticleType.STORY_OUTRO, text: "Story Outro" },
];

const elevenLabsModels = [
    { value: "eleven_monolingual_v1", text: "English V1" },
    { value: "eleven_multilingual_v1", text: "Multilingual V1" },
    // { value: "eleven_english_v2", text: "English V2" },
    { value: "eleven_multilingual_v2", text: "Multilingual V2" },
    { value: "eleven_turbo_v2", text: "Turbo V2" },
    { value: "eleven_english_sts_v2", text: "English V2" },
    { value: "eleven_multilingual_sts_v2", text: "Multilingual V2" },
];

const transcriptLanguages = [
    { value: "en-US", text: "English (United States) (en-US)" },
    { value: "en-GB", text: "English (United Kingdom) (en-GB)" },
    { value: "en-AU", text: "English (Australia) (en-AU)" },
    // { value: "en-CA", text: "English (Canada) (en-CA)" },
    // { value: "en-GH", text: "English (Ghana) (en-GH)" },
    // { value: "en-HK", text: "English (Hong Kong) (en-HK)" },
    // { value: "en-IE", text: "English (Ireland) (en-IE)" },
    { value: "en-IN", text: "English (India) (en-IN)" },
    // { value: "en-KE", text: "English (Kenya) (en-KE)" },
    // { value: "en-NG", text: "English (Nigeria) (en-NG)" },
    // { value: "en-NZ", text: "English (New Zealand) (en-NZ)" },
    // { value: "en-PH", text: "English (Philippines) (en-PH)" },
    // { value: "en-PK", text: "English (Pakistan) (en-PK)" },
    // { value: "en-SG", text: "English (Singapore) (en-SG)" },
    // { value: "en-TZ", text: "English (Tanzania) (en-TZ)" },
    { value: "en-ZA", text: "English (South Africa) (en-ZA)" },
];

// const articleValues = [
//     { value: ArticleValue.SINGLE, text: "Single" },
//     { value: ArticleValue.DOUBLE, text: "Double" },
//     { value: ArticleValue.TRIPLE, text: "Triple" },
//     { value: ArticleValue.QUADRUPLE, text: "Quadruple" },
//     { value: ArticleValue.QUINTUPLE, text: "Quintuple (5)" },
//     { value: ArticleValue.SEXTUPLE, text: "Sextuple (6)" },
//     { value: ArticleValue.SEPTUPLE, text: "Septuple (7)" },
//     { value: ArticleValue.OCTUPLE, text: "Octuple (8)" },
//     { value: ArticleValue.NONUPLE, text: "Nonuple (9)" },
// ];

// const notificationScheduleTypes = [
//     { value: NotificationSchedule.AUTOMATIC, text: "Automatic" },
//     { value: NotificationSchedule.SET_DATE_TIME, text: "Set date & time" },
// ];

// const publishScheduleTypes = [
//     { value: PublishSchedule.WHEN_PUBLISHED, text: "When Published" },
//     { value: PublishSchedule.SET_DATE_TIME, text: "Schedule article release" },
// ];

// update from the backend as well.
const DISPLAY_TAGS_MAX_LENGTH = 60;

const DISPLAY_TAGS_TOTAL_LENGTH = 120;

const MaxLengthInput = (props: any) => <components.Input {...props} maxLength={DISPLAY_TAGS_MAX_LENGTH} />;

const MAX_IMAGE_FILE_SIZE = 150 * 1000; // bytes
const MAX_AUDIO_FILE_BITRATE = 100; // kbps
const MIN_IMAGE_FILE_WIDTH = 300; // pixels
const MIN_IMAGE_FILE_HEIGHT = 300; // pixels

interface IProp {
    article?: IArticle & { filterPlaylistsBySectionId: number | string | undefined };
    articleTemplates?: IArticleTemplate[];
    error?: errors.HttpError;
    isLoading: boolean;
    user: IUser;
    newspapers: INewspaper[];
    sections: ISection[];
    sectionsRaw: ISection[];
    playlists: IPlaylist[];
    templates: ITemplate[];
    narrators: IUser[];
    journalists: IJournalist[];
    PronunciationForm?: any;
    updateFile: typeof updateFile;
    createJournalist: () => void;
    fetchPlaylists: (sectionId: number) => void;
    suggestPlaylists: (params: string[]) => void;
    suggestTags: (text: string) => Promise<string[]>;
    createArticleTemplate: any;
    deleteArticleTemplate: typeof deleteArticleTemplate;
    setSelectedPlaylists: any;
    voiceRequirements: IVoiceRequirement[];
    podcastPlatforms: IPodcastPlatform[];
    elevenLabsVoices: IELVoice[];

    pronunciations: IPronunciation[];
    editPronunciation: typeof editPronunciation;
    verifyPronunciation: typeof verifyPronunciation;
    deletePronunciation: typeof deletePronunciation;

    openPreviewText: () => void;
    wordsToHighlightInRed?: string[];
    isTextEditorFullScreen?: boolean;
    onMinimizeTextEditor?: () => void;
    getTextForAudioReport: (article: IArticle) => string | undefined;
}

export type PronunciationsAudioData = { name: string; data: Blob | null; status?: "loading" | "loaded" }[];

interface IState {
    showNarratorTitleField: boolean;
    showPublisherArticleId: boolean;
    selectedArticleTemplate?: IArticleTemplate;
    tagsSearchRequestController?: AbortController;
    tagsSearchRequestSignal?: AbortSignal;
    displayTagsInput: string;
    hiddenTagsInput: string;
    pronunciationsAudio: PronunciationsAudioData;
    offPlatformPlayerSpeed: number;
    onPlatformPlayerSpeed: number;
    uploadExternalAudioManually: boolean;
    uploadInternalAudioManually: boolean;
}

class ArticleForm extends React.Component<IProp & RouteComponentProps<{}> & InjectedFormProps<{}, IProp & RouteComponentProps<{}>>, IState> {
    private searchTagsForReactSelectDebounce: (searchText: string, callback: (tags: any) => any, displayTags?: boolean) => void;
    private offPlatformPlayerRef: any;
    private onPlatformPlayerRef: any;

    constructor(props: IProp & RouteComponentProps<{}> & InjectedFormProps<{}, IProp & RouteComponentProps<{}>>) {
        super(props);
        this.offPlatformPlayerRef = React.createRef();
        this.onPlatformPlayerRef = React.createRef();

        this.state = {
            showNarratorTitleField: !!(props && props.article && props.article.altNameForNarration),
            showPublisherArticleId: !!(props && props.article && props.article.publisherArticleId),
            displayTagsInput: "",
            hiddenTagsInput: "",
            pronunciationsAudio: [],
            offPlatformPlayerSpeed: 1,
            onPlatformPlayerSpeed: 1,
            uploadExternalAudioManually: false,
            uploadInternalAudioManually: false,
        };

        this.searchTagsForReactSelectDebounce = _.debounce(this.searchTagsForReactSelect, 400);
    }

    public isShowingNarratorField = (): boolean => {
        const { article } = this.props;
        return !!(this.state.showNarratorTitleField || (article && article.altNameForNarration));
    };

    public toggleNarratorTitleField = () => {
        const { change } = this.props;

        change("altNameForNarration", "");
        this.setState({ showNarratorTitleField: !this.isShowingNarratorField() });
    };

    public isShowingPublisherArticleIdField = (): boolean => {
        const { article } = this.props;
        return !!(this.state.showPublisherArticleId || (article && article.publisherArticleId));
    };

    public togglePublisherArticleIdField = () => {
        const { change } = this.props;

        change("publisherArticleId", "");
        this.setState({
            showPublisherArticleId: !this.isShowingPublisherArticleIdField(),
        });
    };

    public blockSubmit = (): string | null => {
        const { article } = this.props;
        if (!article) {
            return null;
        }

        const fieldsMissing = missingFieldsToPublish(article);

        if (article.articleIsPublished === 1 && fieldsMissing.length > 0) {
            return "Unpublish the article or fill all the required fields to enable saving";
        }

        return null;
    };

    public isAiGenerationType = () => {
        const type = this.props.article?.articleNarrationType;

        return ([ArticleNarrationTypes.AI_GOLD, ArticleNarrationTypes.AI_SILVER] as any[]).includes(type);
    };

    public canAudioBeGenerated = (): boolean => {
        const { article, change } = this.props;

        const isOnPlatformPending = article?.aiNarrationStatus === ArticleAiNarrationStatus.PENDING && [ArticleAudioType.INTERNAL, ArticleAudioType.BOTH].includes(article.articleAudioType as any);

        const isOffPlatformPending =
            article?.externalAiNarrationStatus === ArticleAiNarrationStatus.PENDING && [ArticleAudioType.EXTERNAL, ArticleAudioType.BOTH].includes(article.articleAudioType as any);

        const currentPlatform = article?.articleAudioType;

        const isAudioGenerationAllowedForPlatform =
            currentPlatform === ArticleAudioType.BOTH
                ? !(isOnPlatformPending || isOffPlatformPending)
                : currentPlatform === ArticleAudioType.INTERNAL
                ? !isOnPlatformPending
                : currentPlatform === ArticleAudioType.EXTERNAL
                ? !isOffPlatformPending
                : false;

        if (
            !isAudioGenerationAllowedForPlatform ||
            !this.isAiGenerationType() ||
            !article?.articleText?.trim()?.length ||
            !article?.introId ||
            !article?.outroId ||
            !article.aiVoiceId ||
            !article.aiModelId
        ) {
            if (article?.startAudioGeneration) {
                change("startAudioGeneration", 0);
            }
            return false;
        }

        return true;
    };

    public isPublishDisallow = () => {
        const { article } = this.props;
        if (!article) {
            return false;
        }

        const fieldsMissing = missingFieldsToPublish(article);

        if (article.articleIsPublished === 1 && fieldsMissing.length > 0) {
            return false;
        }

        return fieldsMissing.length > 0;
    };

    public tooltipPublishMessage = () => {
        const { article } = this.props;

        if (!article) {
            return "";
        }

        const missingFields = missingFieldsToPublish(article);

        if (missingFields.length > 0) {
            return `Missing Fields: ${missingFields.join(", ")}`;
        }

        return "You can publish or unpublish";
    };

    public createArticleTemplate = (event: any) => {
        event.preventDefault();

        this.props.handleSubmit((values) => {
            return this.props.createArticleTemplate({ ...values });
        })(event);
    };

    public onSubmit = (event: any, changePage: boolean) => {
        event.preventDefault();

        this.props.handleSubmit((values) => {
            // @ts-ignore
            return this.props.onSubmit({ ...values, changePage });
        })(event);
    };

    public getSuggestions = () => {
        const { article } = this.props;
        if (!article) {
            return;
        }

        const tags = article.tagsId || [];

        this.props.suggestPlaylists(tags.map((tag) => tag.tagName));
    };

    public modalTemplateConfirmDescription = () => {
        return (
            <>
                <p>Are you sure that you want save this article? </p>
                <input type="text">Name</input>
            </>
        );
    };

    public modalConfirmDescription = () => {
        const { article } = this.props;

        if (!article) {
            return null;
        }

        const missingFields = missingFieldsForSaveWarning(article);
        const hasErrors = !!missingFields && missingFields.length > 0;

        const warnWords = getWarnWords(article);
        const hasWarnWords = !!warnWords && warnWords.length > 0;

        return (
            <>
                {hasErrors && (
                    <>
                        <p className={css(styles.danger)}>
                            <b>
                                ⚠ WARNING - CRITICAL ISSUES
                                <br />
                            </b>
                            The following critical issues are present in this article:
                        </p>
                        <ul className={css(styles.danger, styles.warningList)}>
                            {missingFields.map((f) => (
                                <li key={f}>✖ {f} is missing</li>
                            ))}
                        </ul>
                    </>
                )}

                {hasWarnWords && (
                    <>
                        <p className={css(styles.warn)}>
                            <b>
                                ⚠ PLEASE REVIEW - DANGER WORDS
                                <br />
                            </b>
                            In some contexts, the following words will hurt an article's longevity or lead to narration error. Please ensure they are being used appropriately.
                        </p>
                        <p className={css(styles.warn)}>
                            <i>In order of occurance:</i>
                            <br />
                            {warnWords &&
                                warnWords.map((word, i) => (
                                    <span key={word}>
                                        '{word}'{i === warnWords.length - 1 ? "" : ",  "}
                                    </span>
                                ))}
                        </p>
                        <br />
                    </>
                )}

                <p>
                    If you proceed, this article will be saved in a
                    <span className={`blue-text ${css(styles.modalConfirmPublishMessage)}`}>{article.articleIsPublished === 1 ? " PUBLISHED " : " UNPUBLISHED "}</span>
                    state.
                </p>
            </>
        );
    };

    public searchTagsForReactSelect = (searchText: string, callback: (tags: any) => any, displayTags?: boolean) => {
        // aborting already running request
        if (this.state.tagsSearchRequestController) {
            this.state.tagsSearchRequestController.abort();
        }

        // creating new controller
        const newController = new AbortController();
        const newSignal = newController.signal;

        this.setState((prev) => ({ ...prev, tagsSearchRequestController: newController, tagsSearchRequestSignal: newSignal }));

        const options: { signal: AbortSignal; maxLength?: number } = { signal: newSignal };

        if (displayTags) {
            options.maxLength = DISPLAY_TAGS_MAX_LENGTH;
        }

        if (!searchText.trim()) return callback([]);

        searchTags(searchText, options)
            .then((tags) =>
                callback(
                    disableSelectedTags(
                        addOverwriteTag(
                            searchText,
                            sortTags(
                                searchText,
                                tags.map((tag) => ({
                                    label: tag.tagName,
                                    value: tag.tagID,
                                })),
                            ),
                            [...(this.props.article?.displayTagsId || []), ...(this.props.article?.tagsId || [])],
                        ),
                        [...(this.props.article?.displayTagsId || []), ...(this.props.article?.tagsId || [])],
                    ),
                ),
            )
            .catch(() => {
                toast(`Error searching tags by "${searchText}"`);
                callback([]);
            });
    };

    public createTag = (tagName: string, isDisplayTag?: boolean) => {
        const tagsIdName = isDisplayTag ? "displayTagsId" : "tagsId";
        const tags = this.props.article?.[tagsIdName] || [];

        if (!tagName.trim()) return toast("Empty tags cannot be created.");

        createTagManually(tagName.trim())
            .then((tag) => {
                if (isDisplayTag) {
                    this.onChangeDisplayTags({ label: tag.tagName, value: tag.tagID });
                } else {
                    this.props.change(tagsIdName, [...tags, { tagName: tag.tagName, tagId: tag.tagID }]);
                }
            })
            .catch(() => {
                toast(`Error creating tag: ${tagName}`);
            });

        return;
    };

    public isTagInputValid = (input: string) => {
        if (input === "") return true;
        return /^[0-9A-Za-z ]+$/.test(input);
    };

    public onDisplayTagsInputChange = (input: string) => {
        if (this.isTagInputValid(input)) {
            this.setState({ displayTagsInput: input });
        }
    };

    public onHiddenTagsInputChange = (input: string) => {
        if (this.isTagInputValid(input)) {
            this.setState({ hiddenTagsInput: input });
        }
    };

    public onChangeDisplayTags = (newTags: { label: string; value: number } | { label: string; value: number }[]) => {
        const { article, change } = this.props;
        const displayTags = article?.displayTagsId || [];
        const displayTagsLength = displayTags.reduce((prev, curr) => prev + curr.tagName.length, 0) as number;

        if (Array.isArray(newTags)) {
            const newTag = newTags.find((nt) => !displayTags.some((t) => t.tagId === nt.value));

            // when tag is removed
            if (!newTag) {
                change(
                    "displayTagsId",
                    newTags.map((i) => ({ tagName: i.label, tagId: i.value })),
                );
                return;
            }

            // when tag is selected
            if (displayTagsLength + newTag.label.length <= DISPLAY_TAGS_TOTAL_LENGTH) {
                // updating the tagname on the backend to correct the tag's cast style
                this.updateTagNameBeforeSelect(newTag.label, newTag.value, "displayTagsId");
                return;
            }

            // when tag is created
        } else if (displayTagsLength + newTags.label.length <= DISPLAY_TAGS_TOTAL_LENGTH) {
            change("displayTagsId", [...displayTags, { tagName: newTags.label, tagId: newTags.value }]);
            return;
        }

        toast("Display tags limit exceeded.");
    };

    public onChangeHiddenTags = (newTags: { label: string; value: number }[]) => {
        const { article, change } = this.props;
        const displayTags = article?.tagsId || [];

        const newTag = newTags.find((nt) => !displayTags.some((t) => t.tagId === nt.value));

        // when tag is removed
        if (!newTag) {
            change(
                "tagsId",
                newTags.map((i) => ({ tagName: i.label, tagId: i.value })),
            );
            return;
        }

        // when tag is selected
        // updating the tagname on the backend to correct the tag's cast style
        this.updateTagNameBeforeSelect(newTag.label, newTag.value, "tagsId");
    };

    public updateTagNameBeforeSelect = (tagName: string, tagId: number, tagsId: "tagsId" | "displayTagsId") => {
        const { article, change } = this.props;
        const tags = article?.[tagsId] || [];

        // to overwrite the tag instead of auto-update
        const tag = getTagFromOverwriteTag(tagName, tagId.toString());

        return updateTagName(tag.tagId, tag.tagName)
            .then((tag) => {
                change(tagsId, [...tags, { tagName: tag.tagName, tagId: tag.tagID }]);
            })
            .catch(() => {
                toast(`unable to select: ${tagName}`);
            });
    };

    public changeNewspaper = (name: string, value: any) => {
        this.props.change("journalistsId", []);
        this.props.change(name, value);
    };

    public changeArticleIntro = (id: number) => {
        const { templates } = this.props;

        const template = templates && templates.find((t) => t.templateId === +id);

        this.props.change("intro", template);
    };

    public changeArticleOutro = (id: number) => {
        const { templates } = this.props;

        const template = templates && templates.find((t) => t.templateId === +id);

        this.props.change("outro", template);
    };

    public changeArticleTemplate = (id: number) => {
        const { articleTemplates, article } = this.props;

        if (!articleTemplates) {
            return;
        }

        const selectedTemplate = articleTemplates.find((a) => a.templateId === +id);
        this.setState({ selectedArticleTemplate: selectedTemplate });

        if (!selectedTemplate) {
            return;
        }

        this.props.change("newspaperId", selectedTemplate.newspaperId != null ? selectedTemplate.newspaperId : article?.newspaperId || null);
        this.props.change("articleIsNotified", selectedTemplate.articleIsNotified != null ? selectedTemplate.articleIsNotified : false);
        this.props.change("articleName", selectedTemplate.articleName != null && selectedTemplate?.articleName?.trim()?.length ? selectedTemplate.articleName : article?.articleName || "");
        this.props.change("articleComments", selectedTemplate.articleComments != null ? selectedTemplate.articleComments : "");
        this.props.change("articleText", selectedTemplate.articleText != null && selectedTemplate?.articleText?.trim()?.length ? selectedTemplate.articleText : article?.articleText || "");
        this.props.change("articlePreviewText", selectedTemplate.articlePreviewText != null ? selectedTemplate.articlePreviewText : "");
        this.props.change("altNameForNarration", selectedTemplate.altNameForNarration != null ? selectedTemplate.altNameForNarration : null);
        this.props.change("articleType", selectedTemplate.articleType != null ? selectedTemplate.articleType : null);
        this.props.change("articleValue", selectedTemplate.articleValue != null ? selectedTemplate.articleValue : null);
        this.props.change(
            "articleOriginLink",
            selectedTemplate.articleOriginLink && selectedTemplate?.articleOriginLink?.trim()?.length ? selectedTemplate.articleOriginLink : article?.articleOriginLink || "",
        );
        this.props.change("articleSection", selectedTemplate.articleSection != null ? selectedTemplate.articleSection : "");
        this.props.change("articleSubName", selectedTemplate.articleSubName != null ? selectedTemplate.articleSubName : "");
        this.props.change("articleIsPublished", selectedTemplate.articleIsPublished != null ? selectedTemplate.articleIsPublished : false);
        this.props.change("introId", selectedTemplate.introId != null ? selectedTemplate.introId : null);
        this.props.change("outroId", selectedTemplate.outroId != null ? selectedTemplate.outroId : null);
        this.props.change("tagsId", selectedTemplate.tagsId != null ? selectedTemplate.tagsId : null);
        this.props.change("displayTagsId", selectedTemplate.displayTagsId != null ? selectedTemplate.displayTagsId : null);
        this.props.change("articleQuality", selectedTemplate.articleQuality != null ? selectedTemplate.articleQuality : null);
        this.props.change("articleTimeliness", selectedTemplate.articleTimeliness != null ? selectedTemplate.articleTimeliness : null);
        this.props.change("articleComprehension", selectedTemplate.articleComprehension != null ? selectedTemplate.articleComprehension : null);
        this.props.change("articlePolarisation", selectedTemplate.articlePolarisation != null ? selectedTemplate.articlePolarisation : null);
        this.props.change("articleAudioRecorderID", selectedTemplate.articleAudioRecorderID != null ? selectedTemplate.articleAudioRecorderID : null);

        const journalistsTransformed = selectedTemplate.journalists ? selectedTemplate.journalists.map((j) => j.journalistId) : [];
        this.props.change("journalistsId", journalistsTransformed);

        const playlistIds = selectedTemplate.playlists ? _.map(selectedTemplate.playlists, (p) => `${p.playlistId}`) : [];
        this.props.change("playlistsId", playlistIds);

        // const tagName = selectedTemplate.tags ? selectedTemplate.tags.map((t) => t.tagName) : [];
        // this.props.change("tagsId", tagName);
    };

    public onChangePlaylist = (playlistsFiltered: IPlaylist[]) => (newPlaylistIds: string[], previousPlaylistIds: string[]) => {
        this.props.setSelectedPlaylists(playlistsFiltered, newPlaylistIds, previousPlaylistIds);

        const playlistIds = _.map(playlistsFiltered, (p) => `${p.playlistId}`);
        const oldPlaylistIds = _.filter(previousPlaylistIds, (p) => !_.includes(playlistIds, p));

        // REDUX-FORM DISPATCH ACTION THAT CLEANS EVERYTHING, THAT'S WHY I DELAYED, TO OVERRIDE REDUX-FORM ACTION
        // setTimeout(() => this.props.change("playlistsId", [...oldPlaylistIds, ...newPlaylistIds]), 50)
        this.props.change("playlistsId", [...oldPlaylistIds, ...newPlaylistIds]);
    };

    public onChangeSection = (newSectionId: string) => {
        // SUGGESTED PLAYLISTS ID
        if (newSectionId === "-1") {
            this.getSuggestions();
            return;
        }

        this.props.fetchPlaylists(+newSectionId);
    };

    public isCreatingArticle = () => {
        return !this.props.article?.articleID;
    };

    public autoPopulateEpaDetails = (epaData: { newspaperId: number; title: string; url: string; publisherArticleId: string | number; articleText: string }) => {
        if (!this.isCreatingArticle()) return;

        const { change } = this.props;
        const allowedPublishersIds = [82];

        const { newspaperId, title: epaTitle, url: epaUrl, publisherArticleId, articleText } = epaData;

        if (!newspaperId || !allowedPublishersIds.includes(+newspaperId) || !String(publisherArticleId)?.length) return;

        this.changeNewspaper("newspaperId", +newspaperId);
        change("articleName", epaTitle);
        change("articleOriginLink", epaUrl);
        change("publisherArticleId", publisherArticleId);
        change("articleText", articleText);
    };

    public onClickBodyEventListener(e: any) {
        const target = e?.target;
        if (target) {
            // ---------
            // fix for "tags" react select issue where it loses focus if we click on it multiple times

            const displayTagsInputEl = document.getElementById("DisplayTagsSelectInput_8d9x8");
            const hiddenTagsInputEl = document.getElementById("HiddenTagsSelectInput_8k72x9");

            if (displayTagsInputEl && displayTagsInputEl.parentNode === target) {
                displayTagsInputEl.blur();
                displayTagsInputEl.focus();
            }

            if (hiddenTagsInputEl && hiddenTagsInputEl.parentNode === target) {
                hiddenTagsInputEl.blur();
                hiddenTagsInputEl.focus();
            }

            // ---------
        }
    }

    public componentDidMount() {
        const { article, change } = this.props;
        const routerState = this.props.history?.location?.state;

        if (routerState?.epaData) {
            this.autoPopulateEpaDetails(routerState.epaData);
        }

        if (!article?.articleID) {
            change("articleIsGlobal", 1);
        }

        document.body.addEventListener("click", this.onClickBodyEventListener);
    }

    public componentWillUnmount() {
        document.body.removeEventListener("click", this.onClickBodyEventListener);
    }

    public shouldComponentUpdateList = (prevPropList: any[] = [], nextPropList: any[] = []) => {
        return _.size(_.differenceWith(nextPropList, prevPropList, _.isEqual)) > 0;
    };

    public shouldComponentUpdateListXor = (prevPropList: any[] = [], nextPropList: any[] = []) => {
        return _.xor(prevPropList, nextPropList).length !== 0;
    };

    public shouldComponentUpdate(nextProps: IProp & RouteComponentProps<{}> & InjectedFormProps<{}, IProp & RouteComponentProps<{}>>, nextState: any) {
        if (this.state.showNarratorTitleField !== nextState.showNarratorTitleField) {
            return true;
        }

        if (this.state.uploadExternalAudioManually !== nextState.uploadExternalAudioManually) {
            return true;
        }
        if (this.state.uploadInternalAudioManually !== nextState.uploadInternalAudioManually) {
            return true;
        }

        if (this.state.offPlatformPlayerSpeed !== nextState.offPlatformPlayerSpeed) {
            return true;
        }
        if (this.state.onPlatformPlayerSpeed !== nextState.onPlatformPlayerSpeed) {
            return true;
        }

        if (this.state.hiddenTagsInput !== nextState.hiddenTagsInput) {
            return true;
        }

        if (this.state.displayTagsInput !== nextState.displayTagsInput) {
            return true;
        }

        if (this.state.pronunciationsAudio !== nextState.pronunciationsAudio) {
            return true;
        }

        if (this.state.showPublisherArticleId !== nextState.showPublisherArticleId) {
            return true;
        }

        const { article, sections, journalists, playlists, narrators, newspapers, valid, articleTemplates, pronunciations, wordsToHighlightInRed } = this.props;

        if (this.shouldComponentUpdateListXor(wordsToHighlightInRed, nextProps.wordsToHighlightInRed)) {
            return true;
        }

        if (this.shouldComponentUpdateListXor(articleTemplates, nextProps.articleTemplates)) {
            return true;
        }

        if (this.shouldComponentUpdateList(sections, nextProps.sections)) {
            return true;
        }

        if (!_.isEqual(playlists, nextProps.playlists)) {
            return true;
        }

        if (this.shouldComponentUpdateList(journalists, nextProps.journalists)) {
            return true;
        }

        if (this.shouldComponentUpdateList(narrators, nextProps.narrators)) {
            return true;
        }

        if (this.shouldComponentUpdateList(newspapers, nextProps.newspapers)) {
            return true;
        }

        if (pronunciations !== nextProps.pronunciations || (pronunciations && nextProps.pronunciations && pronunciations.length !== nextProps.pronunciations.length)) {
            return true;
        }

        if (valid !== nextProps.valid) {
            return true;
        }

        if (
            article &&
            nextProps.article &&
            (article.articleAudioRecorderID !== nextProps.article.articleAudioRecorderID ||
                article.articleIsNotified !== nextProps.article.articleIsNotified ||
                article.articleName !== nextProps.article.articleName ||
                article.altNameForNarration !== nextProps.article.altNameForNarration ||
                article.articleType !== nextProps.article.articleType ||
                article.articleValue !== nextProps.article.articleValue ||
                article.articleAudioType !== nextProps.article.articleAudioType ||
                article.articleOriginLink !== nextProps.article.articleOriginLink ||
                article.sectionsId !== nextProps.article.sectionsId ||
                article.sectionsIdx !== nextProps.article.sectionsIdx ||
                article.publisherArticleId !== nextProps.article.publisherArticleId ||
                article.articleSection !== nextProps.article.articleSection ||
                article.articleSubName !== nextProps.article.articleSubName ||
                article.articleIsPublished !== nextProps.article.articleIsPublished ||
                article.articleIsPublishedExternally !== nextProps.article.articleIsPublishedExternally ||
                article.playlistsId !== nextProps.article.playlistsId ||
                article.journalistsId !== nextProps.article.journalistsId ||
                article.introId !== nextProps.article.introId ||
                article.outroId !== nextProps.article.outroId ||
                article.tagsId !== nextProps.article.tagsId ||
                article.displayTagsId !== nextProps.article.displayTagsId ||
                article.filterPlaylistsBySectionId !== nextProps.article.filterPlaylistsBySectionId ||
                article.newspaperId !== nextProps.article.newspaperId ||
                article.articleQuality !== nextProps.article.articleQuality ||
                article.articleTimeliness !== nextProps.article.articleTimeliness ||
                article.articleComprehension !== nextProps.article.articleComprehension ||
                article.articlePolarisation !== nextProps.article.articlePolarisation ||
                article.voiceRequirementIds !== nextProps.article.voiceRequirementIds ||
                article.articleImageFileName !== nextProps.article.articleImageFileName ||
                article.articleAudioFileName !== nextProps.article.articleAudioFileName ||
                article.articleExternalAudioFileName !== nextProps.article.articleExternalAudioFileName ||
                article.podcastPlatformIds !== nextProps.article.podcastPlatformIds ||
                article.articleOriginalPublicationDateTime !== nextProps.article.articleOriginalPublicationDateTime ||
                article.articleIsFeatured !== nextProps.article.articleIsFeatured ||
                article.articleIsGlobal !== nextProps.article.articleIsGlobal ||
                article.articleViewDateTime !== nextProps.article.articleViewDateTime ||
                article.articleComments !== nextProps.article.articleComments ||
                article.articleNarrationType !== nextProps.article.articleNarrationType ||
                article.startAudioGeneration !== nextProps.article.startAudioGeneration ||
                article.aiVoiceId !== nextProps.article.aiVoiceId ||
                article.aiModelId !== nextProps.article.aiModelId ||
                article.stability !== nextProps.article.stability ||
                article.similarityBoost !== nextProps.article.similarityBoost ||
                article.journalistName !== nextProps.article.journalistName ||
                article.transcriptGenerationStatus !== nextProps.article.transcriptGenerationStatus ||
                article.transcriptLanguage !== nextProps.article.transcriptLanguage)
        ) {
            return true;
        }

        return false;
    }

    public transformPlaylistsForSelect = (playlists: IPlaylist[]) => {
        return _.map(playlists, (p) => ({
            label: (p.playlistIsPublished ? "" : "[UNPUBLISHED] ") + p.playlistName,
            value: p.playlistId,
        }));
    };

    public transformVoiceRequirementsForSelect = (voiceRequirements: IVoiceRequirement[]) => {
        return _.map(voiceRequirements, (vr) => ({
            label: vr.name,
            value: vr.id,
        }));
    };

    public transformPodcastPlatformsForSelect = (podcastPlatforms: IPodcastPlatform[]) => {
        return _.map(podcastPlatforms, (pp) => ({
            label: pp.name,
            value: pp.id,
        }));
    };

    public changeCategory = (newIds: string[]) => {
        // updates the selected sub-categories based on selected parent categories
        const { article, sectionsRaw, change } = this.props;
        const sectionsIdx = article?.sectionsIdx ? article.sectionsIdx : [];
        const sectionsIdxComplete = sectionsRaw.filter((sec) => sectionsIdx.includes(sec.sectionId.toString()));
        const filteredSectionsIdx = sectionsIdxComplete.filter((sec) => sec.parentId && newIds.includes(sec.parentId.toString())).map((sec) => sec.sectionId.toString());

        change("sectionsIdx", filteredSectionsIdx);
    };

    public deleteArticleTemplate = (event: any) => {
        event.preventDefault();

        const { selectedArticleTemplate } = this.state;

        if (!selectedArticleTemplate) {
            return;
        }

        this.props.deleteArticleTemplate(selectedArticleTemplate);
        this.setState({ selectedArticleTemplate: undefined });
    };

    public onInputChange(name: string, e: any) {
        this.props.change(name, e.target.value);
    }

    public onRadioInputChange(name: string, value: string | number) {
        this.props.change(name, value);
    }

    public onDateInputChange(name: string, date: Date | null) {
        this.props.change(name, date === null ? undefined : date.toISOString().split("T")[0]);
    }

    public getSelectedDate(date: string | undefined) {
        return date === undefined ? null : new Date(date);
    }

    public renderArticleTemplates() {
        const { articleTemplates } = this.props;
        const { selectedArticleTemplate } = this.state;

        if (!articleTemplates) {
            return null;
        }

        const selectedTemplateTransformed = selectedArticleTemplate && {
            label: selectedArticleTemplate.name || "",
            value: selectedArticleTemplate.templateId!,
        };

        const articleTemplatesTransformed = articleTemplates
            .filter((a) => !!a.templateId)
            .map((a) => ({
                label: a.name || "",
                value: a.templateId!,
            }));

        return (
            <ContentCard>
                <CardHeading>ARTICLE TEMPLATE</CardHeading>

                <ItemHeading>Choose article template</ItemHeading>
                <ReactSelect
                    styles={ReactSelectStylesV2}
                    name="articleTemplate"
                    placeholder="Choose article template"
                    options={articleTemplatesTransformed}
                    value={selectedTemplateTransformed}
                    onChange={(at) => at && this.changeArticleTemplate(at.value)}
                />

                {selectedArticleTemplate && (
                    <ButtonV2
                        setMarginTop
                        isRed
                        onClick={(e: any) => {
                            e.preventDefault();
                            this.deleteArticleTemplate(e);
                        }}
                    >{`Delete "${selectedArticleTemplate.name}"`}</ButtonV2>
                )}
                <ButtonV2
                    setSmallMarginTop={!!selectedArticleTemplate}
                    setMarginTop={!selectedArticleTemplate}
                    onClick={(e: any) => {
                        e.preventDefault();
                        this.createArticleTemplate(e);
                    }}
                >
                    Save article as template
                </ButtonV2>
            </ContentCard>
        );
    }

    public renderArticleUrl() {
        const { article } = this.props;
        return (
            <ContentCard>
                <CardHeading>Links</CardHeading>
                <ItemHeading>Article URL</ItemHeading>
                <Input type="text" placeholder="Article URL" onChange={(e: any) => this.onInputChange("articleOriginLink", e)} value={article ? article.articleOriginLink : ""}></Input>

                {this.isShowingPublisherArticleIdField() && (
                    <>
                        <ItemHeading setMarginTop>Embed Player ID</ItemHeading>

                        <Input type="text" placeholder="Embed Player ID" onChange={(e: any) => this.onInputChange("publisherArticleId", e)} value={article ? article.publisherArticleId : ""} />
                    </>
                )}

                <TextButton
                    style={{ marginTop: "24px", marginLeft: "10px" }}
                    isRed={this.isShowingPublisherArticleIdField()}
                    onClick={(e: any) => {
                        e.preventDefault();
                        this.togglePublisherArticleIdField();
                    }}
                >
                    {this.isShowingPublisherArticleIdField() ? "Remove embed article ID" : "Add embed player ID"}
                </TextButton>
            </ContentCard>
        );
    }

    public renderNarrationType() {
        const { article, elevenLabsVoices, change } = this.props;

        const isAiGenerationType = this.isAiGenerationType();

        const shouldDisplayVoiceSection =
            article?.articleAudioType === ArticleAudioType.BOTH
                ? !article?.aiNarrationProjectId && !article?.externalAiNarrationProjectId
                : article?.articleAudioType === ArticleAudioType.INTERNAL
                ? !article?.aiNarrationProjectId
                : article?.articleAudioType === ArticleAudioType.EXTERNAL
                ? !article?.externalAiNarrationProjectId
                : true;

        const aiVoiceId = article?.aiVoiceId;

        const selectedAiVoiceId = [elevenLabsVoices.find((v) => v.voiceId == aiVoiceId)].map((v) => v && { label: v.name, value: v.voiceId as any })[0];

        const aiVoicesTransformed = elevenLabsVoices.map((v) => ({
            label: v.name,
            value: v.voiceId as any,
        }));

        const defaultTranscriptLanguage = [transcriptLanguages.find((lang) => lang.value === "en-US")].map((l) => l && { label: l.text, value: l.value as any })[0];

        const transcriptLanguageCode = article?.transcriptLanguage;
        const selectedTranscriptLanguage = [transcriptLanguages.find((l) => l.value === transcriptLanguageCode)].map((l) => l && { label: l.text, value: l.value as any })[0];
        const transcriptLanguagesTransformed = transcriptLanguages.map((l) => ({
            label: l.text,
            value: l.value as any,
        }));

        const aiModelId = article?.aiModelId; // ? article?.aiModelId : !article?.articleID ? "eleven_monolingual_v1" : undefined;

        const selectedAiModel = [elevenLabsModels.find((m) => m.value === aiModelId)].map((m) => m && { label: m.text, value: m.value as any })[0];

        const aiModelsTransformed = elevenLabsModels.map((m) => ({
            label: m.text,
            value: m.value as any,
        }));

        return (
            <ContentCard>
                <CardHeading>NARRATION</CardHeading>
                <ItemHeading style={{ marginBottom: "8px" }}>Choose narration</ItemHeading>
                <RadioGroup
                    options={articleNarrationTypes}
                    selected={article?.articleNarrationType === undefined ? ArticleNarrationTypes.HUMAN : article.articleNarrationType}
                    onSelect={(value) => this.onRadioInputChange("articleNarrationType", value)}
                />

                {isAiGenerationType && shouldDisplayVoiceSection && (
                    <>
                        <ItemHeading setMarginTop>Choose AI Model</ItemHeading>
                        <ReactSelect
                            styles={ReactSelectStylesV2}
                            name="aiModelId"
                            placeholder="Choose AI Model"
                            options={aiModelsTransformed}
                            value={selectedAiModel}
                            onChange={(model) => model && change("aiModelId", model.value)}
                        />
                        <ItemHeading setMarginTop style={{ marginBottom: "8px" }}>
                            Choose voice
                        </ItemHeading>
                        <ReactSelect
                            styles={ReactSelectStylesV2}
                            name="aiVoiceId"
                            placeholder="Choose voice"
                            options={aiVoicesTransformed}
                            value={selectedAiVoiceId}
                            onChange={(voice) => voice && change("aiVoiceId", voice.value)}
                        />
                        {/* <AiVoiceSliders>
                            <div>
                                <ItemHeading>Stability: {Math.round(((article?.stability || 0.9) / 1) * 100)}%</ItemHeading>
                                <Slider
                                    railStyle={{ background: "#EBECF0" }}
                                    trackStyle={{ background: "#007FBC" }}
                                    handleStyle={{
                                        background: "rgba(255, 255, 255, 1) !important",
                                        borderColor: "#19B3FF",
                                        boxShadow: "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.20)",
                                        width: "16px",
                                        height: "16px",
                                        borderWidth: "2px",
                                        marginTop: "-6px",
                                        opacity: 1,
                                    }}
                                    defaultValue={0.9}
                                    min={0}
                                    max={1}
                                    step={0.001}
                                    onChange={(v) => change("stability", v)}
                                />
                            </div>
                            <div>
                                <ItemHeading>Similarity Boost: {Math.round(((article?.similarityBoost || 0.1) / 1) * 100)}%</ItemHeading>
                                <Slider
                                    railStyle={{ background: "#EBECF0" }}
                                    trackStyle={{ background: "#007FBC" }}
                                    handleStyle={{
                                        background: "rgba(255, 255, 255, 1) !important",
                                        borderColor: "#19B3FF",
                                        boxShadow: "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.20)",
                                        width: "16px",
                                        height: "16px",
                                        borderWidth: "2px",
                                        marginTop: "-6px",
                                        opacity: 1,
                                    }}
                                    defaultValue={0.1}
                                    min={0}
                                    max={1}
                                    step={0.001}
                                    onChange={(v) => change("similarityBoost", v)}
                                />
                            </div>
                        </AiVoiceSliders> */}
                    </>
                )}

                <ItemHeading setMarginTop style={{ marginBottom: "8px" }}>
                    Transcript Language
                </ItemHeading>
                <ReactSelect
                    styles={ReactSelectStylesV2}
                    name="transcriptLanguage"
                    placeholder="Choose transcript language"
                    options={transcriptLanguagesTransformed}
                    value={selectedTranscriptLanguage || defaultTranscriptLanguage}
                    onChange={(lang) => lang && change("transcriptLanguage", lang.value)}
                />
            </ContentCard>
        );
    }

    public renderDistributionSection() {
        const { article } = this.props;

        return (
            <ContentCard>
                <CardHeading>DISTRIBUTION</CardHeading>
                <ItemHeading style={{ marginBottom: "8px" }}>Choose Platform</ItemHeading>
                <RadioGroup
                    options={articleAudioTypes}
                    selected={article?.articleAudioType === undefined ? ArticleAudioType.BOTH : article.articleAudioType}
                    onSelect={(value) => this.onRadioInputChange("articleAudioType", value)}
                />
                <ItemHeading setMarginTop style={{ marginBottom: "8px" }}>
                    Publication date
                </ItemHeading>
                <DatePickerInputWrapper>
                    <ReactDatePicker
                        showPopperArrow={false}
                        placeholderText="Select a date"
                        dateFormat="yyyy-MM-dd"
                        onChange={(date) => this.onDateInputChange("articleOriginalPublicationDateTime", date)}
                        selected={this.getSelectedDate(article?.articleOriginalPublicationDateTime)}
                    />
                </DatePickerInputWrapper>

                <ItemHeading setMarginTop style={{ marginBottom: "8px" }}>
                    View date
                </ItemHeading>
                <DatePickerInputWrapper>
                    <ReactDatePicker
                        showPopperArrow={false}
                        placeholderText="Select a date"
                        dateFormat="yyyy-MM-dd"
                        onChange={(date) => this.onDateInputChange("articleViewDateTime", date)}
                        selected={this.getSelectedDate(article?.articleViewDateTime)}
                    />
                </DatePickerInputWrapper>

                {/* <ItemHeading setMarginTop style={{ marginBottom: "8px" }}>
                    Publish Schedule
                </ItemHeading>
                <RadioGroup options={publishScheduleTypes} selected={PublishSchedule.WHEN_PUBLISHED} onSelect={(value) => console.log(value)} /> */}

                {/* <ItemHeading setMarginTop style={{ marginBottom: "8px" }}>
                    Set schedule
                </ItemHeading>
                <DatePickerInputWrapper>
                    <ReactDatePicker showPopperArrow={false} placeholderText="Select a date" dateFormat="yyyy-MM-dd" onChange={(date) => console.log(date)} selected={null} />
                </DatePickerInputWrapper> */}

                {/* <Separator /> */}

                {/* <ControlsWrapper>
                    <p>Send Notifications</p>
                    <ToggleSwitch checked onChange={(v) => console.log(v)} />
                </ControlsWrapper>

                <ItemHeading setMarginTop>Notification header</ItemHeading>
                <Input type="text" placeholder="Notification header" />

                <ItemHeading setMarginTop>Notification subtext</ItemHeading>
                <Input type="text" placeholder="Notification subtext" />

                <ItemHeading setMarginTop style={{ marginBottom: "8px" }}>
                    Notification Schedule
                </ItemHeading>
                <RadioGroup options={notificationScheduleTypes} selected={NotificationSchedule.AUTOMATIC} onSelect={(value) => console.log(value)} />

                <ItemHeading setMarginTop style={{ marginBottom: "8px" }}>
                    Set schedule
                </ItemHeading>
                <DatePickerInputWrapper>
                    <ReactDatePicker showPopperArrow={false} placeholderText="Select a date" dateFormat="yyyy-MM-dd" onChange={(date) => console.log(date)} selected={null} />
                </DatePickerInputWrapper> */}
            </ContentCard>
        );
    }

    public transformJournalistsForSelect = (journalists: IJournalist[]) => {
        return _.map(journalists, (j) => ({
            label: j.journalistName,
            value: j.journalistId,
        }));
    };

    public createJournalist = (e: any) => {
        e.preventDefault();
        this.props.change("journalistName", "");
        this.props.createJournalist();
    };

    public renderArticleInformation() {
        const { article, newspapers, journalists, change } = this.props;

        const newspaperId = article?.newspaperId;

        const selectedNewspaper = [newspapers.find((n) => n.newspaperID == newspaperId)].map((n) => n && { label: n.newspaperName, value: n.newspaperID })[0];

        const publishedNewspapersTransformed = newspapers
            .filter((n) => !!n.newspaperIsPublished)
            .map((n) => ({
                label: n.newspaperName,
                value: n.newspaperID,
            }));

        const unPublishedNewspapersTransformed = newspapers
            .filter((n) => !n.newspaperIsPublished)
            .map((n) => ({
                label: n.newspaperName,
                value: n.newspaperID,
            }));

        const newspapersGroup = [
            {
                label: "Published",
                options: publishedNewspapersTransformed,
            },
            {
                label: "UnPublished",
                options: unPublishedNewspapersTransformed,
            },
        ];

        const journalistsIds = article?.journalistsId || [];
        const journalistsTransformed = this.transformJournalistsForSelect(journalists);
        const selectedJournalists = this.transformJournalistsForSelect(journalists.filter((j) => journalistsIds.includes(j.journalistId.toString())));

        return (
            <ContentCard>
                <CardHeading>ARTICLE INFORMATION</CardHeading>

                <ItemHeading>Headline</ItemHeading>
                <Input type="text" placeholder="Headline" onChange={(e: any) => this.onInputChange("articleName", e)} value={article ? article.articleName : ""}></Input>

                {this.isShowingNarratorField() && (
                    <>
                        <ItemHeading setMarginTop>Narration title</ItemHeading>

                        <Input type="text" placeholder="Narration title" onChange={(e: any) => this.onInputChange("altNameForNarration", e)} value={article ? article.altNameForNarration : ""} />
                    </>
                )}

                <div style={{ display: "flex", marginTop: "14px" }}>
                    <TextButton
                        style={{ marginLeft: "auto", marginRight: "8px" }}
                        isRed={this.isShowingNarratorField()}
                        onClick={(e: any) => {
                            e.preventDefault();
                            this.toggleNarratorTitleField();
                        }}
                    >
                        {this.isShowingNarratorField() ? "Remove narration title" : "Create a separate narration title"}
                    </TextButton>
                </div>

                <ItemHeading setMarginTop>Choose publication</ItemHeading>
                <ReactSelect
                    styles={ReactSelectStylesV2}
                    name="newspaperId"
                    placeholder="Choose publication"
                    options={newspapersGroup}
                    value={selectedNewspaper}
                    onChange={(newspaper) => newspaper && this.changeNewspaper("newspaperId", newspaper.value)}
                />

                <ItemHeading setMarginTop>Publication section</ItemHeading>
                <Input type="text" placeholder="Publication section" onChange={(e: any) => this.onInputChange("articleSection", e)} value={article ? article.articleSection : ""}></Input>

                <ItemHeading setMarginTop>Subtitle</ItemHeading>
                <Input type="text" placeholder="Subtitle" onChange={(e: any) => this.onInputChange("articleSubName", e)} value={article ? article.articleSubName : ""}></Input>

                <ItemHeading setMarginTop>Journalist</ItemHeading>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "100%", marginRight: "20px" }}>
                        <ReactSelect
                            isMulti
                            styles={ReactSelectStylesMultiV2}
                            value={selectedJournalists}
                            name="journalists"
                            placeholder="Select journalists"
                            isClearable
                            options={journalistsTransformed}
                            onChange={(j) =>
                                change(
                                    "journalistsId",
                                    Array.from(j).map((i) => i.value.toString()),
                                )
                            }
                        />
                    </div>

                    <Input
                        style={{ width: "400px" }}
                        type="text"
                        placeholder="Journalist Name"
                        onChange={(e: any) => this.onInputChange("journalistName", e)}
                        value={article ? article.journalistName : ""}
                    ></Input>
                    <TextButton style={{ marginLeft: "20px" }} onClick={this.createJournalist}>
                        Add
                    </TextButton>
                </div>
            </ContentCard>
        );
    }

    public renderArticleMetadata() {
        const { article, sectionsRaw, podcastPlatforms, change } = this.props;
        const { displayTagsInput, hiddenTagsInput } = this.state;

        const sectionsId = article?.sectionsId || [];
        const sectionsIdx = article?.sectionsIdx || [];

        const selectedSections = sectionsId.length ? sectionsRaw.filter((s) => sectionsId.includes(s.sectionId.toString())).map((s) => ({ label: s.sectionName, value: s.sectionId })) : [];
        const selectedSubSections = sectionsIdx.length ? sectionsRaw.filter((s) => sectionsIdx.includes(s.sectionId.toString())).map((s) => ({ label: s.sectionName, value: s.sectionId })) : [];

        const parentSectionsTransformed = sectionsRaw
            .filter((s) => s.parentId == null)
            .map((s) => ({
                label: s.sectionName,
                value: s.sectionId,
            }));

        const subSectionsTransformed = sectionsRaw
            .filter((s) => s.parentId && sectionsId && sectionsId.includes(s.parentId.toString()))
            .map((s) => ({
                label: s.sectionName,
                value: s.sectionId,
            }));

        const tags = article?.tagsId || [];
        const displayTags = article?.displayTagsId || [];

        const displayTagsTransformed = displayTags.map((t) => ({
            label: t.tagName,
            value: t.tagId,
        }));

        const tagsTransformed = tags.map((t) => ({
            label: t.tagName,
            value: t.tagId,
        }));

        const podcastPlatformIds = article?.podcastPlatformIds || [];
        const podcastPlatformsTransformed = this.transformPodcastPlatformsForSelect(podcastPlatforms);
        const selectedPodcastPlatforms = this.transformPodcastPlatformsForSelect(podcastPlatforms.filter((pp) => podcastPlatformIds.includes(pp.id.toString())));

        return (
            <ContentCard>
                <CardHeading>METADATA</CardHeading>

                <ItemHeading>Podcast platforms</ItemHeading>
                <ReactSelect
                    isMulti
                    styles={ReactSelectStylesMultiV2}
                    value={selectedPodcastPlatforms}
                    name="podcastplatforms"
                    placeholder="Select podcast platforms"
                    isClearable
                    options={podcastPlatformsTransformed}
                    onChange={(pp) =>
                        change(
                            "podcastPlatformIds",
                            Array.from(pp).map((i) => i.value.toString()),
                        )
                    }
                />

                <ItemHeading setMarginTop>Categories</ItemHeading>
                <ReactSelect
                    isMulti
                    styles={ReactSelectStylesMultiV2}
                    value={selectedSections}
                    name="sections"
                    placeholder="Select categories"
                    isClearable
                    options={parentSectionsTransformed}
                    onChange={(s) => {
                        const newIds = Array.from(s).map((i) => i.value.toString());
                        change("sectionsId", newIds);
                        this.changeCategory(newIds);
                    }}
                />

                <ItemHeading setMarginTop>Sub-categories</ItemHeading>
                <ReactSelect
                    isMulti
                    styles={ReactSelectStylesMultiV2}
                    value={selectedSubSections}
                    name="sub-sections"
                    placeholder="Select sub-categories"
                    isClearable
                    options={subSectionsTransformed}
                    onChange={(s) =>
                        change(
                            "sectionsIdx",
                            Array.from(s).map((i) => i.value.toString()),
                        )
                    }
                />

                <ItemHeading setMarginTop>Display tags (Shown on article card)</ItemHeading>

                <div>
                    <label className="active" style={{ color: "#7A869A" }}>
                        * Max limit for a tag is 60 and the total limit for all the tags collectively is 120.
                    </label>
                    <br />
                    <label className="active" style={{ color: "#7A869A", marginBottom: "6px", display: "block" }}>
                        * Only letters and numbers are allowed.
                    </label>
                    <CreatableSelect
                        isMulti
                        value={displayTagsTransformed}
                        loadOptions={(inputValue, callback) => this.searchTagsForReactSelectDebounce(inputValue, callback, true)}
                        styles={ReactSelectStylesMultiV2}
                        name="displayTagsId"
                        placeholder="Select display tags"
                        isClearable={false}
                        onCreateOption={(t) => this.createTag(t, true)}
                        components={{ Input: MaxLengthInput, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        inputValue={displayTagsInput}
                        onInputChange={this.onDisplayTagsInputChange}
                        onChange={(v) => this.onChangeDisplayTags(Array.from(v))}
                        isOptionDisabled={(option: any) => option.disabled}
                        openMenuOnClick={false}
                        inputId="DisplayTagsSelectInput_8d9x8"
                    />
                </div>

                <ItemHeading setMarginTop>Hidden tags (Used in search & recommendations)</ItemHeading>
                <div>
                    <label className="active" style={{ color: "#7A869A" }}>
                        * Max limit for a tag is 60.
                    </label>
                    <br />
                    <label className="active" style={{ color: "#7A869A", marginBottom: "6px", display: "block" }}>
                        * Only letters and numbers are allowed.
                    </label>
                    <CreatableSelect
                        isMulti
                        value={tagsTransformed}
                        loadOptions={this.searchTagsForReactSelectDebounce}
                        styles={ReactSelectStylesMultiV2}
                        name="tagsId"
                        placeholder="Select hidden tags"
                        isClearable={false}
                        onCreateOption={this.createTag}
                        inputValue={hiddenTagsInput}
                        onInputChange={this.onHiddenTagsInputChange}
                        onChange={(v) => this.onChangeHiddenTags(Array.from(v))}
                        isOptionDisabled={(option: any) => option.disabled}
                        openMenuOnClick={false}
                        inputId="HiddenTagsSelectInput_8k72x9"
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    />
                </div>
            </ContentCard>
        );
    }

    public renderNarratorInformation() {
        const { article, narrators, voiceRequirements, change } = this.props;

        const narratorId = article?.articleAudioRecorderID;

        const selectedNarrator = [narrators.find((n) => n.userId == narratorId)].map((n) => n && { label: `${n.userFirstName} ${n.userLastName}`, value: n.userId })[0];

        const narratorsTransformed = narrators.map((n) => ({
            label: `${n.userFirstName} ${n.userLastName}`,
            value: n.userId,
        }));

        const articleType = article?.articleType as number | undefined;
        const selectedArticleType = [articleTypes.find((at) => at.value == articleType)].map(
            (at) =>
                at && {
                    label: at.text,
                    value: at.value,
                },
        )[0];

        const articleTypesTransformed = articleTypes.map((at) => ({
            label: at.text,
            value: at.value,
        }));

        // const selectedArticleValue = [articleValues.find((av) => av.value == articleValue)].map(
        //     (av) =>
        //         av && {
        //             label: av.text,
        //             value: av.value,
        //         },
        // )[0];

        // const articleValuesTransformed = articleValues.map((av) => ({
        //     label: av.text,
        //     value: av.value,
        // }));

        const voiceRequirementIds = article?.voiceRequirementIds || [];
        const voiceRequirementsTransformed = this.transformVoiceRequirementsForSelect(voiceRequirements);
        const selectedVoiceRequirements = this.transformVoiceRequirementsForSelect(voiceRequirements.filter((vr) => voiceRequirementIds.includes(vr.id.toString())));

        return (
            <ContentCard>
                <CardHeading>NARRATOR INFORMATION</CardHeading>

                {/* <ControlsWrapper>
                    <div>
                        <p>Do not publish</p>
                        <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "2px" }}>
                            Turning this on will disable the publish button for narrators and block publishing.
                        </label>
                    </div>
                    <ToggleSwitch checked onChange={(v) => console.log(v)} />
                </ControlsWrapper> */}

                <ItemHeading setMarginTop>Narrator</ItemHeading>
                <ReactSelect
                    styles={ReactSelectStylesV2}
                    name="narratorId"
                    placeholder="Choose narrator"
                    options={narratorsTransformed}
                    value={selectedNarrator}
                    onChange={(narrator) => narrator && change("articleAudioRecorderID", narrator.value)}
                />

                <ItemHeading setMarginTop>Article type</ItemHeading>
                <ReactSelect
                    styles={ReactSelectStylesV2}
                    name="articleType"
                    placeholder="Choose article type"
                    options={articleTypesTransformed}
                    value={selectedArticleType}
                    onChange={(at) => at && change("articleType", at.value)}
                />

                <ItemHeading setMarginTop>Number of units</ItemHeading>
                <Input type="number" placeholder="Number of units" onChange={(e: any) => this.onInputChange("articleValue", e)} value={article ? article.articleValue : ""}></Input>

                {/* <ReactSelect
                    styles={ReactSelectStylesV2}
                    name="articleValue"
                    placeholder="Choose number of units"
                    options={articleValuesTransformed}
                    value={selectedArticleValue}
                    onChange={(av) => av && change("articleValue", av.value)}
                />
 */}
                <ItemHeading setMarginTop>Voice requirements</ItemHeading>

                <ReactSelect
                    isMulti
                    styles={ReactSelectStylesMultiV2}
                    value={selectedVoiceRequirements}
                    name="voiceRequirements"
                    placeholder="Select voice requirements"
                    isClearable
                    options={voiceRequirementsTransformed}
                    onChange={(vr) =>
                        change(
                            "voiceRequirementIds",
                            Array.from(vr).map((i) => i.value.toString()),
                        )
                    }
                />

                <ItemHeading setMarginTop>Comments</ItemHeading>
                <InputTextArea placeholder="Write comments..." onChange={(e: any) => this.onInputChange("articleComments", e)} value={article ? article.articleComments : ""}></InputTextArea>
            </ContentCard>
        );
    }

    public renderPlaylists() {
        const { article, change, sections, playlists } = this.props;

        if (article?.articleAudioType === ArticleAudioType.EXTERNAL) return null;

        const sectionsTransformed = sections.map((c) => ({
            label: c.sectionName,
            value: c.sectionId,
        }));

        const playlistsId = article?.playlistsId || [];
        const playlistsTransformed = this.transformPlaylistsForSelect(playlists);
        const selectedPlaylists = this.transformPlaylistsForSelect(playlists.filter((p) => playlistsId.includes(p.playlistId.toString())));

        return (
            <ContentCard>
                <CardHeading>PLAYLISTS</CardHeading>

                <ItemHeading>Category</ItemHeading>

                <ReactSelect
                    styles={ReactSelectStylesV2}
                    name="playlistSections"
                    placeholder="Filter by category"
                    options={sectionsTransformed}
                    onChange={(section) => section && this.onChangeSection(section.value.toString())}
                />

                <ItemHeading setMarginTop>Playlists</ItemHeading>
                <ReactSelect
                    isMulti
                    styles={ReactSelectStylesMultiV2}
                    value={selectedPlaylists}
                    name="playlists"
                    placeholder="Select playlists"
                    isClearable
                    options={playlistsTransformed}
                    onChange={(p) =>
                        change(
                            "playlistsId",
                            Array.from(p).map((i) => i.value.toString()),
                        )
                    }
                />
            </ContentCard>
        );
    }

    public renderControls() {
        const { article, change } = this.props;

        return (
            <ContentCard>
                <CardHeading>CONTROLS</CardHeading>
                <ControlsWrapper>
                    <div>
                        <p>Global</p>
                        <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "2px" }}>
                            Turning this field off means only listeners in the country of the publication will be recommended this article. Suitable if an article is of regional or national interest
                            only.
                        </label>
                    </div>
                    <ToggleSwitch checked={!!article?.articleIsGlobal} onChange={(v) => change("articleIsGlobal", v ? 1 : 0)} />
                </ControlsWrapper>
                <ControlsWrapper setMarginTop>
                    <div>
                        <p>Featured</p>
                        <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "2px" }}>
                            When turned On, the article card in the apps will stand out more. Suitable for feature and long-form articles.
                        </label>
                    </div>
                    <ToggleSwitch checked={!!article?.articleIsFeatured} onChange={(v) => change("articleIsFeatured", v ? 1 : 0)} />
                </ControlsWrapper>

                <ControlsWrapper setMarginTop>
                    <div>
                        <p>Published</p>
                        <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "2px" }}>
                            Controls whether the article appears on the apps. Cannot be toggled on if key fields are missing.
                        </label>
                    </div>
                    <Tooltipped tooltip={this.tooltipPublishMessage()}>
                        <ToggleSwitch checked={!!article?.articleIsPublished} onChange={(v) => change("articleIsPublished", v ? 1 : 0)} disabled={this.isPublishDisallow()} />
                    </Tooltipped>
                </ControlsWrapper>

                <SliderInputWrapper>
                    <InputField>
                        <h5 className={css(styles.subtitle, styles.rangeSubtitle)}>Quality</h5>
                        <p className={`${css(styles.rangeField)} range-field`}>
                            <Field name="articleQuality" type="range" component="input" min={1} max={5} className={css(styles.range)} />
                            <div className={css(styles.rangeValue)}>{article && article.articleQuality}</div>
                        </p>
                    </InputField>

                    <InputField>
                        <h5 className={css(styles.subtitle, styles.rangeSubtitle)}>Timeliness</h5>
                        <p className={`${css(styles.rangeField)} range-field`}>
                            <Field name="articleTimeliness" type="range" component="input" min={1} max={5} className={css(styles.range)} />
                            <div className={css(styles.rangeValue)}>{article && article.articleTimeliness}</div>
                        </p>
                    </InputField>

                    <InputField>
                        <h5 className={css(styles.subtitle, styles.rangeSubtitle)}>Comprehension</h5>
                        <p className={`${css(styles.rangeField)} range-field`}>
                            <Field name="articleComprehension" type="range" component="input" min={1} max={5} className={css(styles.range)} />
                            <div className={css(styles.rangeValue)}>{article && article.articleComprehension}</div>
                        </p>
                    </InputField>

                    <InputField>
                        <h5 className={css(styles.subtitle, styles.rangeSubtitle)}>Polarisation</h5>
                        <p className={`${css(styles.rangeField)} range-field`}>
                            <Field name="articlePolarisation" type="range" component="input" min={-5} max={5} className={css(styles.range)} />
                            <div className={css(styles.rangeValue)}>{article && article.articlePolarisation}</div>
                        </p>
                    </InputField>
                </SliderInputWrapper>
            </ContentCard>
        );
    }

    public renderPublishersControls() {
        const { article, change } = this.props;

        if (![1, 2].includes(article?.articleAudioType || 0)) return null;
        return (
            <ContentCard>
                <CardHeading>PUBLISHERS</CardHeading>
                <ControlsWrapper>
                    <div>
                        <p>Published Externally</p>
                        <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "2px" }}>
                            Controls whether to make the article available for external publishers.
                        </label>
                        <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "2px" }}>
                            <b>Currently a beta feature, toggle with caution. Make sure all the required fields are populated.</b>
                        </label>
                    </div>

                    <ToggleSwitch checked={!!article?.articleIsPublishedExternally} onChange={(v) => change("articleIsPublishedExternally", v ? 1 : 0)} />
                </ControlsWrapper>
            </ContentCard>
        );
    }

    public renderTextEditor() {
        const { article, change } = this.props;

        return (
            <ContentCard style={{ zIndex: 1000 }}>
                <CardHeading>TEXT EDITOR</CardHeading>

                <RichInputText
                    wordsToHighlightInRed={this.props.wordsToHighlightInRed}
                    isTextEditorFullScreen={this.props.isTextEditorFullScreen}
                    onMinimizeTextEditor={this.props.onMinimizeTextEditor}
                    // tslint:disable-next-line: jsx-no-lambda
                    onChange={(text: string) => {
                        change("articleText", text);
                    }}
                    initialValue={article ? article.articleText : ""}
                />
                <br />
                <ControlsWrapper>
                    <div>
                        <p>Text Preview</p>{" "}
                        <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "2px" }}>
                            Review and add pronunciations
                        </label>
                    </div>
                    <TextButton
                        onClick={(e: any) => {
                            e.preventDefault();
                            this.props.openPreviewText();
                        }}
                    >
                        Open Text Preview
                    </TextButton>
                </ControlsWrapper>
            </ContentCard>
        );
    }

    public renderPreviewTextEditor() {
        const { article, change } = this.props;

        return (
            <ContentCard>
                <CardHeading>PREVIEW TEXT</CardHeading>
                <RichInputText
                    limit={1000}
                    // tslint:disable-next-line: jsx-no-lambda
                    onChange={(text: string) => change("articlePreviewText", text !== "<p>&nbsp;</p>" && text !== "<p><br></p>" ? text : "")}
                    initialValue={article ? article.articlePreviewText : ""}
                    allowBullets
                />
            </ContentCard>
        );
    }

    public renderTemplates() {
        const { article, change, templates } = this.props;

        const introId = article?.introId;
        const outroId = article?.outroId;

        const selectedIntro = [templates.find((t) => t.templateId === introId)]
            .filter((t) => !!t)
            .map((t) => ({
                label: t!.templateName,
                value: t!.templateId,
            }))?.[0];

        const selectedOutro = [templates.find((t) => t.templateId === outroId)]
            .filter((t) => !!t)
            .map((t) => ({
                label: t!.templateName,
                value: t!.templateId,
            }))?.[0];

        const introsTransformed = templates
            .filter((t) => t.templateTypeId === TemplateTypes.INTRO)
            .map((t) => ({
                label: t.templateName,
                value: t.templateId,
            }));

        const outrosTransformed = templates
            .filter((t) => t.templateTypeId === TemplateTypes.OUTRO)
            .map((t) => ({
                label: t.templateName,
                value: t.templateId,
            }));

        const showManualIntro = article?.intro?.templateName === "BLANK";
        const showManualOutro = article?.outro?.templateName === "BLANK";

        return (
            <ContentCard>
                <CardHeading>SCRIPT TEMPLATE</CardHeading>
                <ItemHeading>Intro</ItemHeading>
                <ReactSelect
                    styles={ReactSelectStylesV2}
                    name="introId"
                    placeholder="Choose Intro"
                    options={introsTransformed}
                    value={selectedIntro}
                    onChange={(i) => {
                        if (i) {
                            change("introId", i.value);
                            this.changeArticleIntro(i.value);
                        }
                    }}
                />

                {showManualIntro && (
                    <>
                        <div style={{ marginTop: "24px" }} />
                        <RichInputTextV2
                            // tslint:disable-next-line: jsx-no-lambda
                            onChange={(text: string) => {
                                change("manualIntro", text !== "<p>&nbsp;</p>" && text !== "<p><br></p>" ? text : "");
                            }}
                            initialValue={article ? article.manualIntro : ""}
                        />
                    </>
                )}

                <ItemHeading setMarginTop>Outro</ItemHeading>
                <ReactSelect
                    styles={ReactSelectStylesV2}
                    name="outroId"
                    placeholder="Choose Outro"
                    options={outrosTransformed}
                    value={selectedOutro}
                    onChange={(o) => {
                        if (o) {
                            change("outroId", o.value);
                            this.changeArticleOutro(o.value);
                        }
                    }}
                />

                {showManualOutro && (
                    <>
                        <div style={{ marginTop: "24px" }} />
                        <RichInputTextV2
                            // tslint:disable-next-line: jsx-no-lambda
                            onChange={(text: string) => {
                                change("manualOutro", text !== "<p>&nbsp;</p>" && text !== "<p><br></p>" ? text : "");
                            }}
                            initialValue={article ? article.manualOutro : ""}
                        />
                    </>
                )}
            </ContentCard>
        );
    }

    public async getImageFileDimensions(file: File): Promise<{ width: number; height: number }> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.onload = () => resolve({ width: img.width, height: img.height });

                img.src = (reader as any).result;
            };
            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        });
    }

    public checkImageSizeAndDimensions = async (file: File) => {
        if (file.size > MAX_IMAGE_FILE_SIZE) {
            toast.error("The image size exceeds the maximum permitted size of 150KB.");
            return null;
        }

        let dimensions;

        try {
            dimensions = await this.getImageFileDimensions(file);
        } catch {
            toast.error("Unable to read file dimensions");
            return null;
        }

        if (dimensions.width < MIN_IMAGE_FILE_WIDTH || dimensions.height < MIN_IMAGE_FILE_HEIGHT) {
            toast.error("The image height or width is less than the minimum permitted dimension of 300 pixels.");
            return null;
        }

        return file;
    };

    public getAudioFileBitrateInKbps = async (file: File): Promise<{ duration: number; bitrate: number }> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const media = new Audio((reader as any).result);
                media.onloadedmetadata = () => resolve({ duration: media.duration, bitrate: Math.floor(((file.size / media.duration) * 8) / 1000) });
            };

            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        });
    };

    public checkAudioBitRateAndGetDuration = async (file: File) => {
        let bitrate, duration;
        try {
            const res = await this.getAudioFileBitrateInKbps(file);
            bitrate = res.bitrate;
            duration = res.duration;
        } catch {
            toast.error("Unable to read file bitrate and duration.");
            return;
        }

        if (bitrate > MAX_AUDIO_FILE_BITRATE) {
            toast.error("The audio bit rate (Kbps) exceeds the maximum permitted bit rate of 100 Kbps.");
            return;
        }

        return { file, duration };
    };

    public changeImageFile = async (files: File[]) => {
        if (!files.length) {
            return;
        }

        const file = files[0];

        const validatedFile = await this.checkImageSizeAndDimensions(file);

        if (!validatedFile) return;

        this.props.updateFile("articleImageFileName", validatedFile as any, FileType.image);
        this.props.change("articleImageFileName", validatedFile.name);

        return;
    };

    public changeAudioFile = async (id: string, files: File[]) => {
        if (!files.length) {
            return;
        }

        const file = files[0];

        const res = await this.checkAudioBitRateAndGetDuration(file);

        if (!res) return;

        const { file: validatedFile, duration } = res;

        this.props.updateFile(id, validatedFile as any, FileType.audio, duration);
        this.props.change(id, file.name);

        return;
    };

    public getAiAudioMessage = (platform: "on-platform" | "off-platform") => {
        const { article } = this.props;

        const status = platform === "on-platform" ? article?.aiNarrationStatus : article?.externalAiNarrationStatus;

        if (status === ArticleAiNarrationStatus.FAILURE) {
            return {
                message: <p>Failed to generate the audio.</p>,
                background: "#DE350B",
                status,
            };
        } else if (status === ArticleAiNarrationStatus.PENDING) {
            return {
                message: <p>Audio is being generated.</p>,
                background: "#f0a100",
                status,
            };
        } else if (status === ArticleAiNarrationStatus.SUCCESS) {
            return {
                message: <p>Successfully generated the audio.</p>,
                background: "#198754",
                status,
            };
        }

        return {
            message: (
                <>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <rect width="24" height="24" transform="translate(0.5)" fill="white" fill-opacity="0.01" />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.5 22C6.977 22 2.5 17.523 2.5 12C2.5 6.477 6.977 2 12.5 2C18.023 2 22.5 6.477 22.5 12C22.5 17.523 18.023 22 12.5 22ZM13.5 8C13.5 8.55228 13.0523 9 12.5 9C11.9477 9 11.5 8.55228 11.5 8C11.5 7.44772 11.9477 7 12.5 7C13.0523 7 13.5 7.44772 13.5 8ZM12.5 10C13.0523 10 13.5 10.4477 13.5 11V16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16V11C11.5 10.4477 11.9477 10 12.5 10Z"
                                fill="white"
                            />
                        </svg>
                    </span>
                    <p>Not yet generated</p>
                </>
            ),
            background: "#42526E",
            status,
        };
    };

    public setPronunciationAudioData = (name: string, data?: Blob | null, status?: "loading" | "loaded") => {
        this.setState((prev) => {
            const cloned = [...prev.pronunciationsAudio];
            const pronIndex = cloned.findIndex((p) => p.name === name);

            const newAudioData = { name: name } as any;

            if (data) newAudioData.data = data;
            if (status) newAudioData.status = status;

            if (pronIndex === -1) {
                return { ...prev, pronunciationsAudio: [...cloned, newAudioData] };
            }

            cloned[pronIndex] = newAudioData;

            return { ...prev, pronunciationsAudio: cloned };
        });
    };

    public playPronunciation = (data: Blob) => {
        const audio = new Audio(window.URL.createObjectURL(data));
        audio.load();
        audio.play();
    };

    public loadAndPlayPronunciation = async (text: string) => {
        const { pronunciationsAudio } = this.state;
        const pron = pronunciationsAudio.find((i) => i.name === text && !!i.data);
        if (pron) {
            this.playPronunciation(pron.data as any);
            return;
        }

        this.setPronunciationAudioData(text, null, "loading");

        try {
            const data = await fetchPronunciation(text, this.props.article?.aiVoiceId);

            this.setPronunciationAudioData(text, data, "loaded");
            this.playPronunciation(data);
        } catch (e) {
            this.setPronunciationAudioData(text, null, "loaded");
        }
    };

    public renderPronunciations() {
        const { user, pronunciations, editPronunciation, deletePronunciation, verifyPronunciation, article } = this.props;

        return (
            <ContentCard>
                <CardHeading>PRONUNCIATION CHECK</CardHeading>
                <Pronunciations
                    articleText={article?.articleText || ""}
                    user={user}
                    editPronunciation={editPronunciation}
                    deletePronunciation={deletePronunciation}
                    verifyPronunciation={verifyPronunciation}
                    pronunciations={pronunciations}
                    pronunciationsAudioData={this.state.pronunciationsAudio}
                    playPronunciation={this.loadAndPlayPronunciation}
                />
            </ContentCard>
        );
    }

    public incrementOnPlayerSpeed = (e?: any) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }

        const onPlatformAudio = this.onPlatformPlayerRef.current.audio.current;
        if (onPlatformAudio && onPlatformAudio.playbackRate < 2) {
            const newSpeed = onPlatformAudio.playbackRate + 0.25;
            onPlatformAudio.playbackRate = newSpeed;
            this.setState((prev) => ({ ...prev, onPlatformPlayerSpeed: newSpeed }));
        }
    };

    public decrementOnPlayerSpeed = (e?: any) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }

        const onPlatformAudio = this.onPlatformPlayerRef.current.audio.current;
        if (onPlatformAudio && onPlatformAudio.playbackRate > 0.25) {
            const newSpeed = onPlatformAudio.playbackRate - 0.25;
            onPlatformAudio.playbackRate = newSpeed;
            this.setState((prev) => ({ ...prev, onPlatformPlayerSpeed: newSpeed }));
        }
    };

    public incrementOffPlayerSpeed = (e?: any) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }

        const offPlatformAudio = this.offPlatformPlayerRef.current.audio.current;
        if (offPlatformAudio && offPlatformAudio.playbackRate < 2) {
            const newSpeed = offPlatformAudio.playbackRate + 0.25;
            offPlatformAudio.playbackRate = newSpeed;
            this.setState((prev) => ({ ...prev, offPlatformPlayerSpeed: newSpeed }));
        }
    };

    public decrementOffPlayerSpeed = (e?: any) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }

        const offPlatformAudio = this.offPlatformPlayerRef.current.audio.current;
        if (offPlatformAudio && offPlatformAudio.playbackRate > 0.25) {
            const newSpeed = offPlatformAudio.playbackRate - 0.25;
            offPlatformAudio.playbackRate = newSpeed;
            this.setState((prev) => ({ ...prev, offPlatformPlayerSpeed: newSpeed }));
        }
    };

    onClickUploadNewExternalAudioFile = (e?: any) => {
        e.preventDefault();

        this.setState((prev) => ({ ...prev, uploadExternalAudioManually: true }));
    };
    onClickCancelUploadNewExternalAudioFile = (e?: any) => {
        e.preventDefault();

        this.setState((prev) => ({ ...prev, uploadExternalAudioManually: false }));
    };
    onClickUploadNewInternalAudioFile = (e?: any) => {
        e.preventDefault();

        this.setState((prev) => ({ ...prev, uploadInternalAudioManually: true }));
    };
    onClickCancelUploadNewInternalAudioFile = (e?: any) => {
        e.preventDefault();

        this.setState((prev) => ({ ...prev, uploadInternalAudioManually: false }));
    };

    public renderAssets() {
        const { article } = this.props;

        // @ts-ignore
        const articleAudioType = article ? +article.articleAudioType?.valueOf() : 3;

        const showInternalAudioField = articleAudioType === 0 || articleAudioType === 2;
        const showExternalAudioField = articleAudioType === 1 || articleAudioType === 2;

        const isAiGenerated = this.isAiGenerationType();
        const { message, background, status } = this.getAiAudioMessage("on-platform");
        const { message: offMessage, background: offBackground, status: offStatus } = this.getAiAudioMessage("off-platform");

        const { uploadExternalAudioManually, uploadInternalAudioManually } = this.state;

        return (
            <ContentCard>
                <CardHeading>ASSETS</CardHeading>
                <AssetHeading>Image</AssetHeading>
                <FileDrop
                    fileTypes={{ "image/jpeg": [], "image/png": [] }}
                    onReject={(files) => {
                        if (files.length) {
                            toast.error("Only JPEG and PNG files are allowed.");
                        }
                    }}
                    value={article?.articleImageFileName}
                    onAccept={this.changeImageFile}
                />

                <AssetHeading style={{ marginTop: "44px" }}>Audio</AssetHeading>

                <AudioAssetsWrapper>
                    {showInternalAudioField &&
                        (isAiGenerated && !([null, undefined].includes(status as any) && !!article?.audioUrl) && !uploadInternalAudioManually ? (
                            <AiAudioMessageWrapper>
                                <div
                                    style={{
                                        borderBottom: "2px solid #dfe1e6",
                                        paddingBottom: "7px",
                                        marginBottom: "3px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    <AudioPlatformHeading>ON-PLATFORM</AudioPlatformHeading>
                                    {status === ArticleAiNarrationStatus.SUCCESS && !!article?.audioUrl && (
                                        <TextButton style={{ width: "200px" }} onClick={this.onClickUploadNewInternalAudioFile}>
                                            Upload new file
                                        </TextButton>
                                    )}
                                </div>
                                {status === ArticleAiNarrationStatus.SUCCESS && !!article?.audioUrl ? (
                                    <>
                                        <AudioPlayer
                                            src={article?.audioUrl}
                                            ref={this.onPlatformPlayerRef}
                                            customAdditionalControls={[
                                                <PlayerSpeedButton style={{ marginTop: "-14px" }} onClick={this.decrementOnPlayerSpeed}>
                                                    _
                                                </PlayerSpeedButton>,
                                                <span style={{ width: "35px", fontSize: "15px", margin: "0px 10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    {this.state.onPlatformPlayerSpeed}x
                                                </span>,
                                                <PlayerSpeedButton onClick={this.incrementOnPlayerSpeed}>+</PlayerSpeedButton>,
                                            ]}
                                        />
                                        <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "10px" }}>
                                            Last Updated:{" "}
                                            {article?.articleAudioAddedDateTime
                                                ? new Intl.DateTimeFormat("en-US", {
                                                      year: "numeric",
                                                      month: "numeric",
                                                      day: "numeric",
                                                      hour: "numeric",
                                                      minute: "numeric",
                                                      second: "numeric",
                                                      hour12: true,
                                                  }).format(new Date(article.articleAudioAddedDateTime))
                                                : "null"}
                                        </label>
                                    </>
                                ) : (
                                    <AiAudioMessage style={{ backgroundColor: background }}>{message}</AiAudioMessage>
                                )}
                            </AiAudioMessageWrapper>
                        ) : (
                            <div>
                                <div
                                    style={{
                                        borderBottom: "2px solid #dfe1e6",
                                        paddingBottom: "7px",
                                        marginBottom: "3px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    <AudioPlatformHeading>ON-PLATFORM</AudioPlatformHeading>
                                    {uploadInternalAudioManually && (
                                        <TextButton style={{ width: "200px" }} onClick={this.onClickCancelUploadNewInternalAudioFile}>
                                            Cancel upload
                                        </TextButton>
                                    )}
                                </div>
                                <FileDrop
                                    fileTypes={{ "audio/mpeg": [] }}
                                    onAccept={(files) => this.changeAudioFile("articleAudioFileName", files)}
                                    value={article?.articleAudioFileName}
                                    onReject={(files) => {
                                        if (files.length) {
                                            toast.error("Only audio files are allowed.");
                                        }
                                    }}
                                />

                                <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "10px" }}>
                                    Last Updated:{" "}
                                    {article?.articleAudioAddedDateTime
                                        ? new Intl.DateTimeFormat("en-US", {
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                              hour: "numeric",
                                              minute: "numeric",
                                              second: "numeric",
                                              hour12: true,
                                          }).format(new Date(article.articleAudioAddedDateTime))
                                        : "null"}
                                </label>
                            </div>
                        ))}
                    {showExternalAudioField &&
                        (isAiGenerated && !([null, undefined].includes(offStatus as any) && !!article?.externalAudioUrl) && !uploadExternalAudioManually ? (
                            <AiAudioMessageWrapper>
                                <div
                                    style={{
                                        borderBottom: "2px solid #dfe1e6",
                                        paddingBottom: "7px",
                                        marginBottom: "3px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    <AudioPlatformHeading>OFF-PLATFORM</AudioPlatformHeading>
                                    {offStatus === ArticleAiNarrationStatus.SUCCESS && !!article?.externalAudioUrl && (
                                        <TextButton style={{ width: "190px" }} onClick={this.onClickUploadNewExternalAudioFile}>
                                            Upload new file
                                        </TextButton>
                                    )}
                                </div>
                                {offStatus === ArticleAiNarrationStatus.SUCCESS && !!article?.externalAudioUrl ? (
                                    <>
                                        <AudioPlayer
                                            ref={this.offPlatformPlayerRef}
                                            customAdditionalControls={[
                                                <PlayerSpeedButton style={{ marginTop: "-14px" }} onClick={this.decrementOffPlayerSpeed}>
                                                    _
                                                </PlayerSpeedButton>,
                                                <span style={{ width: "35px", fontSize: "15px", margin: "0px 10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    {this.state.offPlatformPlayerSpeed}x
                                                </span>,
                                                <PlayerSpeedButton onClick={this.incrementOffPlayerSpeed}>+</PlayerSpeedButton>,
                                            ]}
                                            src={article?.externalAudioUrl}
                                        />
                                        <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "10px" }}>
                                            Last Updated:{" "}
                                            {article?.articleExternalAudioAddedDateTime
                                                ? new Intl.DateTimeFormat("en-US", {
                                                      year: "numeric",
                                                      month: "long",
                                                      day: "numeric",
                                                      hour: "numeric",
                                                      minute: "numeric",
                                                      second: "numeric",
                                                      hour12: true,
                                                  }).format(new Date(article.articleExternalAudioAddedDateTime))
                                                : "null"}
                                        </label>
                                    </>
                                ) : (
                                    <AiAudioMessage style={{ backgroundColor: offBackground }}>{offMessage}</AiAudioMessage>
                                )}
                            </AiAudioMessageWrapper>
                        ) : (
                            <div>
                                <div
                                    style={{
                                        borderBottom: "2px solid #dfe1e6",
                                        paddingBottom: "7px",
                                        marginBottom: "3px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    <AudioPlatformHeading>OFF-PLATFORM</AudioPlatformHeading>
                                    {uploadExternalAudioManually && (
                                        <TextButton style={{ width: "200px" }} onClick={this.onClickCancelUploadNewExternalAudioFile}>
                                            Cancel upload
                                        </TextButton>
                                    )}
                                </div>
                                <FileDrop
                                    fileTypes={{ "audio/mpeg": [] }}
                                    onAccept={(files) => this.changeAudioFile("articleExternalAudioFileName", files)}
                                    value={article?.articleExternalAudioFileName}
                                    onReject={(files) => {
                                        if (files.length) {
                                            toast.error("Only audio files are allowed.");
                                        }
                                    }}
                                />
                                <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "10px" }}>
                                    Last Updated:{" "}
                                    {article?.articleExternalAudioAddedDateTime
                                        ? new Intl.DateTimeFormat("en-US", {
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                              hour: "numeric",
                                              minute: "numeric",
                                              second: "numeric",
                                              hour12: true,
                                          }).format(new Date(article.articleExternalAudioAddedDateTime))
                                        : "null"}
                                </label>
                            </div>
                        ))}
                </AudioAssetsWrapper>
                {isAiGenerated && (
                    <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "30px" }}>
                        Scroll to the bottom to Save and Generate/Re-generate the AI audio.
                    </label>
                )}
            </ContentCard>
        );
    }

    onClickRegenerateAudioReport = (e?: any) => {
        const { article, change } = this.props;

        if (e) {
            e.preventDefault();
        }

        if (!article?.articleID) return;

        retryAudioTranscription(article.articleID)
            .then(() => {
                change("transcriptGenerationStatus", ArticleTranscriptGenerationStatus.PENDING);
            })
            .catch(() => {
                toast("Failed to start Re-generation.");
            });
    };

    public renderAudioReport() {
        const { article } = this.props;

        if (!article?.articleID) return null;

        const status = article?.transcriptGenerationStatus;
        const isFailed = isTranscriptFailed(status);
        const isPending = isTranscriptPending(status);
        const isAvailable = isTranscriptAvailable(status);
        const isIdle = [undefined, null].includes(article?.transcriptGenerationStatus as any);

        return (
            <ContentCard>
                <CardHeading>Audio Report</CardHeading>
                {isFailed && (
                    <>
                        <p
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                margin: 0,
                                fontSize: "14px",
                                fontWeight: "500",
                                background: "rgba(9, 30, 66, 0.04)",
                                padding: "20px 10px",
                                borderRadius: "3px",
                            }}
                        >
                            <span>
                                Generation Status: <span style={{ color: "#DE350B" }}>FAILED</span>
                            </span>
                        </p>

                        <TextButton onClick={this.onClickRegenerateAudioReport} isRed style={{ marginTop: "30px", marginLeft: "10px" }}>
                            Re-generate
                        </TextButton>
                    </>
                )}
                {isPending && (
                    <p
                        style={{
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                            background: "rgba(9, 30, 66, 0.04)",
                            padding: "20px 10px",
                            borderRadius: "3px",
                        }}
                    >
                        Generation Status: <span style={{ color: "#f0a100" }}>PENDING</span>
                    </p>
                )}

                {isAvailable && (
                    <>
                        <p
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                margin: 0,
                                fontSize: "14px",
                                fontWeight: "500",
                                background: "rgba(9, 30, 66, 0.04)",
                                padding: "20px 10px",
                                borderRadius: "3px",
                            }}
                        >
                            <span>
                                Generation Status: <span style={{ color: "#198754" }}>SUCCESS</span>
                            </span>

                            <TextButton
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    window.open(`/articles/${article.articleID}/qa`, "_blank");
                                }}
                            >
                                Open Report
                            </TextButton>
                        </p>

                        <ModalConfirm title="Confirmation" description="Current report will be replaced with new QA report.">
                            {(confirm: any) => (
                                <TextButton
                                    onClick={confirm((e: any) => {
                                        e.preventDefault();
                                        this.onClickRegenerateAudioReport();
                                    })}
                                    isRed
                                    style={{ marginTop: "30px", marginLeft: "10px" }}
                                >
                                    Re-generate
                                </TextButton>
                            )}
                        </ModalConfirm>
                    </>
                )}

                {isIdle && (
                    <p
                        style={{
                            margin: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                            background: "rgba(9, 30, 66, 0.04)",
                            padding: "20px 10px",
                            borderRadius: "3px",
                        }}
                    >
                        Generation Status: <span style={{ color: "#007fbc" }}>IDLE</span>
                    </p>
                )}
            </ContentCard>
        );
    }

    public onFetchLatestAiAudioVersion = () => {
        const { article, history } = this.props;
        fetchLatestAiAudioVersion(article, () => history.push(config.paths.article));
    };

    public renderSubmitButton(confirm: any) {
        const { valid, change, article } = this.props;

        const submitBlocked = this.blockSubmit();
        const canAudioBeGenerated = this.canAudioBeGenerated();
        const isAiGenerationType = this.isAiGenerationType();
        const canLatestSnapshotBeFetched = canProjectLatestSnapshotBeFetched(article);

        const isOnPlatformPending = article?.aiNarrationStatus === ArticleAiNarrationStatus.PENDING && [ArticleAudioType.INTERNAL, ArticleAudioType.BOTH].includes(article.articleAudioType as any);

        const isOffPlatformPending =
            article?.externalAiNarrationStatus === ArticleAiNarrationStatus.PENDING && [ArticleAudioType.EXTERNAL, ArticleAudioType.BOTH].includes(article.articleAudioType as any);

        const isBothPlatformPending = article?.externalAiNarrationStatus === ArticleAiNarrationStatus.PENDING && article?.aiNarrationStatus === ArticleAiNarrationStatus.PENDING;

        const pendingPlatformName = isBothPlatformPending ? "BOTH" : isOnPlatformPending ? "ON-PLATFORM" : isOffPlatformPending ? "OFF-PLATFORM" : null;

        const thisPlatformAudioCanBeGenerated = isBothPlatformPending ? "" : isOnPlatformPending ? "OFF-PLATFORM" : isOffPlatformPending ? "ON-PLATFORM" : "";

        return (
            <ContentCard>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <Field name="error" type="hidden" component={renderError} />

                    {isAiGenerationType && (
                        <ControlsWrapper style={{ width: "100%", marginBottom: "20px" }}>
                            <div>
                                <p>{!canLatestSnapshotBeFetched ? "Start Audio Generation" : "Fetch Latest Version"}</p>
                                <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "2px" }}>
                                    {" "}
                                    {!canLatestSnapshotBeFetched
                                        ? "AI Voice, AI Model, Article Text and Script Templates required to enable this option."
                                        : "Make sure you've generated a new version on ElevenLabs Site."}{" "}
                                </label>
                                {!!pendingPlatformName && (
                                    <label className="active" style={{ color: "#DE350B", display: "block", marginTop: "2px", fontWeight: "600" }}>
                                        Platform: "{pendingPlatformName}" is in pending state. Audio generation can't be started.
                                    </label>
                                )}
                                {!!thisPlatformAudioCanBeGenerated && (
                                    <label className="active" style={{ color: "#198754", display: "block", marginTop: "2px", fontWeight: "600" }}>
                                        But "{thisPlatformAudioCanBeGenerated}" audio can be generated. Select "{thisPlatformAudioCanBeGenerated}" and start audio generation.
                                        <br />
                                        This will not affect the audio generation of "{pendingPlatformName}".
                                    </label>
                                )}
                                <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "30px" }}>
                                    ** AI audio generation takes several minutes and will process in the background.
                                </label>
                                <label className="active" style={{ color: "#7A869A", display: "block", marginTop: "2px" }}>
                                    ** Upon pressing 'Save and Generate Audio' you will be returned to the Articles page.
                                </label>
                            </div>
                            {!canLatestSnapshotBeFetched ? (
                                <ToggleSwitch disabled={!canAudioBeGenerated} checked={!!article?.startAudioGeneration} onChange={(v) => change("startAudioGeneration", v ? 1 : 0)} />
                            ) : (
                                <ModalConfirm title="Are you sure?" description="You are going to fetch a new audio file and replace the current audio file.">
                                    {(confirm: any) => (
                                        <ButtonV2
                                            disabled={!canAudioBeGenerated}
                                            onClick={confirm((e: any) => {
                                                e.preventDefault();
                                                this.onFetchLatestAiAudioVersion();
                                            })}
                                        >
                                            Fetch Latest Audio
                                        </ButtonV2>
                                    )}
                                </ModalConfirm>
                            )}
                        </ControlsWrapper>
                    )}

                    <ButtonV2
                        style={{ margin: "16px 0px" }}
                        onClick={confirm((e: any) => {
                            e.preventDefault();
                            this.onSubmit(e, true);
                        })}
                        disabled={!valid || !!submitBlocked}
                    >
                        Save and {canAudioBeGenerated && article?.startAudioGeneration ? "Generate Audio" : "Return"}
                    </ButtonV2>

                    {!!submitBlocked && (
                        <div className={`center ${css(styles.submitBlocked)}`}>
                            <p>{submitBlocked}</p>
                        </div>
                    )}
                </div>
            </ContentCard>
        );
    }

    public render() {
        return (
            <ModalConfirm title="Are you sure you want to save?" description={this.modalConfirmDescription()}>
                {(confirm: any) => (
                    <form>
                        <div style={{ paddingTop: "30px" }} />
                        {this.renderArticleTemplates()}
                        {this.renderNarrationType()}
                        {this.renderArticleUrl()}
                        {this.renderDistributionSection()}
                        {this.renderArticleInformation()}
                        {this.renderTextEditor()}
                        {this.renderArticleMetadata()}
                        {this.renderPreviewTextEditor()}
                        {this.renderTemplates()}
                        {this.renderNarratorInformation()}
                        {this.renderPlaylists()}
                        {this.renderPronunciations()}
                        {this.renderAssets()}
                        {this.renderAudioReport()}
                        {this.renderPublishersControls()}
                        {this.renderControls()}
                        {this.renderSubmitButton(confirm)}
                        <div style={{ paddingBottom: "300px" }} />
                    </form>
                )}
            </ModalConfirm>
        );
    }
}

const styles = StyleSheet.create({
    articleComments: {
        minHeight: 60,
        maxHeight: 500,
        overflowY: "auto",
        resize: "vertical",
    },
    articleText: {
        maxHeight: 600,
        minHeight: 300,
        overflowY: "auto",
        resize: "vertical",
    },
    fixedBtn: {
        bottom: 50,
        right: 50,
    },
    inputNewJournalist: {
        marginBottom: 0,
        marginTop: -10,
    },
    modalConfirmPublishMessage: {
        fontWeight: 900,
    },
    paddingBottom: {
        paddingBottom: 30,
    },
    range: {
        paddingTop: 40,
        paddingBottom: 40,
    },
    rangeField: {
        textAlign: "center",
    },
    rangeValue: {
        color: "#9e9e9e",
        fontSize: 12,
        marginTop: -40,
    },
    submitBlocked: {
        color: "red",
        fontSize: 12,
    },
    subtitle: {
        fontSize: 14,
        fontWeight: 300,
        textAlign: "center",
    },
    rangeSubtitle: {
        marginBottom: -30,
    },
    danger: {
        color: "red",
    },
    warn: {
        color: "#FF8C00",
    },
    warningList: {
        marginBottom: 40,
    },
});

export default reduxForm<{}, IProp & RouteComponentProps<{}>>({
    enableReinitialize: true,
    form: "ArticleForm",
})(ArticleForm);
