import { useState } from "react";
import { Helmet } from "react-helmet";
import QaMain from "./QaMain";

const ArticleQa = () => {
    const [title, setTitle] = useState("Article QA");
    return (
        <>
            {/* 
            // @ts-ignore */}
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <QaMain setPageTitle={(title: string) => setTitle(title)} />
        </>
    );
};

export default ArticleQa;
