import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ArticleForm from "../containers/ArticleForm";
import ArticleList from "../containers/ArticleList";
import ArticleView from "../containers/ArticleView";
import AudienceList from "../containers/AudienceList";
import CompanyForm from "../containers/CompanyForm";
import CompanyList from "../containers/CompanyList";
import EditorDashboard from "../containers/EditorDashboard";
import JournalistForm from "../containers/JournalistForm";
import JournalistList from "../containers/JournalistList";
import LoginForm from "../containers/LoginForm";
import Menu from "../containers/Menu";
import NarratorDashboard from "../containers/NarratorDashboard";
import NewspaperDashboard from "../containers/NewspaperDashboard";
import NewspaperForm from "../containers/NewspaperForm";
import NewspaperList from "../containers/NewspaperList";
import NotFound from "../containers/NotFound";
import PlaylistForm from "../containers/PlaylistForm";
import PlaylistList from "../containers/PlaylistList";
import PronunciationForm from "../containers/PronunciationForm";
import PronunciationList from "../containers/PronunciationList";
import SectionForm from "../containers/SectionForm";
import SectionList from "../containers/SectionList";
import SpecialSectionsV2 from "../containers/SpecialSectionsV2";
import TagForm from "../containers/TagForm";
import TagList from "../containers/TagList";
import TemplateForm from "../containers/TemplateForm";
import TemplateList from "../containers/TemplateList";
import UserForm from "../containers/UserForm";
import UserList from "../containers/UserList";
import { IState } from "../reducers";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";
import AdvertEditorContainer from "../domaindriven/adverts/containers/AdvertEditorContainer";
import AdvertListContainer from "../domaindriven/adverts/containers/AdvertListContainer";
import SettingsListContainer from "../domaindriven/settings/views/containers/SettingsListContainer";
import SettingsFormContainer from "src/domaindriven/settings/views/containers/SettingsFormContainer";
import SpecialSections from "src/containers/SpecialSections";
import { Toaster } from "react-hot-toast";
import ArticleQa from "src/containers/ArticleQa";
import ExternalPublishersArticles from "src/containers/ExternalPublishersArticles";
import ExternalPublishersArticlesV2 from "src/containers/ExternalPublishersArticlesV2";

interface IProps {
    user?: IUser;
}

export class Routes extends React.Component<IProps> {
    public renderRoutesForLoggedOut = () => {
        return (
            <Switch>
                <Route exact={true} path="/" component={LoginForm} />
                <Route component={NotFound} />
            </Switch>
        );
    };

    public renderRoutesForSuper = () => {
        return (
            <Switch>
                <Route exact={true} path="/" component={EditorDashboard} />
                <Route exact={true} path="/articles" component={ArticleList} />
                <Route path="/articles+" component={ArticleForm} />
                <Route path="/articles/:id/view" component={ArticleView} />
                <Route path="/articles/:id/qa" component={ArticleQa} />
                <Route path="/articles/:id" component={ArticleForm} />
                <Route exact={true} path="/audiences" component={AudienceList} />
                <Route exact={true} path="/specialsections" component={SpecialSections} />
                <Route exact={true} path="/editorpicks" component={SpecialSectionsV2} />
                <Route exact={true} path="/categories" component={SectionList} />
                <Route path="/categories+" component={SectionForm} />
                <Route path="/categories/:id" component={SectionForm} />
                <Route exact={true} path="/playlists" component={PlaylistList} />
                <Route path="/playlists+" component={PlaylistForm} />
                <Route path="/playlists/:id" component={PlaylistForm} />
                <Route exact={true} path="/tags" component={TagList} />
                <Route path="/tags+" component={TagForm} />
                <Route path="/tags/:id" component={TagForm} />
                <Route exact={true} path="/templates" component={TemplateList} />
                <Route path="/templates+" component={TemplateForm} />
                <Route path="/templates/:id" component={TemplateForm} />
                <Route exact={true} path="/newspapers" component={NewspaperList} />
                <Route path="/newspapers+" component={NewspaperForm} />
                <Route path="/newspapers/:id" component={NewspaperForm} />
                <Route exact={true} path="/users" component={UserList} />
                <Route path="/users+" component={UserForm} />
                <Route path="/users/:id" component={UserForm} />
                <Route exact={true} path="/journalists" component={JournalistList} />
                <Route path="/journalists+" component={JournalistForm} />
                <Route path="/journalists/:id" component={JournalistForm} />
                <Route exact={true} path="/adverts" component={AdvertListContainer} />
                <Route path="/adverts+" component={AdvertEditorContainer} />
                <Route path="/adverts/:id" component={AdvertEditorContainer} />
                <Route exact={true} path="/companies" component={CompanyList} />
                <Route path="/companies+" component={CompanyForm} />
                <Route path="/companies/:id" component={CompanyForm} />
                <Route exact={true} path="/pronunciations" component={PronunciationList} />
                <Route path="/pronunciations+" component={PronunciationForm} />
                <Route path="/pronunciations/:id" component={PronunciationForm} />
                <Route exact={true} path="/settings" component={SettingsListContainer} />
                <Route path="/settings/:id" component={SettingsFormContainer} />
                <Route path="/epa" component={ExternalPublishersArticles} />
                <Route path="/epa-v2" component={ExternalPublishersArticlesV2} />
                <Route component={NotFound} />
            </Switch>
        );
    };

    public renderRoutesForNarrator = () => {
        return (
            <Switch>
                <Route exact={true} path="/" component={NarratorDashboard} />
                <Route exact={true} path="/articles" component={ArticleList} />
                <Route path="/articles/:id/view" component={ArticleView} />
                <Route path="/articles/:id" component={ArticleForm} />
                <Route component={NotFound} />
            </Switch>
        );
    };

    public renderRoutesForNewspaper = () => {
        return (
            <Switch>
                <Route exact={true} path="/" component={NewspaperDashboard} />
                <Route exact={true} path="/articles" component={ArticleList} />
                <Route exact={true} path="/users" component={UserList} />
                <Route path="/users+" component={UserForm} />
                <Route path="/users/:id" component={UserForm} />
                <Route component={NotFound} />
            </Switch>
        );
    };

    public renderRoutesForAdvertiser = () => {
        return (
            <Switch>
                <Route exact={true} path="/" component={AdvertListContainer} />
                <Route exact={true} path="/adverts" component={AdvertListContainer} />
                <Route path="/adverts+" component={AdvertEditorContainer} />
                <Route path="/adverts/:id" component={AdvertEditorContainer} />
                <Route exact={true} path="/users" component={UserList} />
                <Route path="/users+" component={UserForm} />
                <Route path="/users/:id" component={UserForm} />
            </Switch>
        );
    };

    public renderRoutesForEditor = () => {
        return (
            <Switch>
                <Route exact={true} path="/" component={EditorDashboard} />
                <Route exact={true} path="/articles" component={ArticleList} />
                <Route path="/articles+" component={ArticleForm} />
                <Route path="/articles/:id/view" component={ArticleView} />
                <Route path="/articles/:id/qa" component={ArticleQa} />
                <Route path="/articles/:id" component={ArticleForm} />
                <Route exact={true} path="/sections" component={SectionList} />
                <Route path="/sections+" component={SectionForm} />
                <Route path="/sections/:id" component={SectionForm} />
                <Route exact={true} path="/playlists" component={PlaylistList} />
                <Route path="/playlists+" component={PlaylistForm} />
                <Route path="/playlists/:id" component={PlaylistForm} />
                <Route exact={true} path="/templates" component={TemplateList} />
                <Route path="/templates+" component={TemplateForm} />
                <Route path="/templates/:id" component={TemplateForm} />
                <Route exact={true} path="/tags" component={TagList} />
                <Route path="/tags+" component={TagForm} />
                <Route path="/tags/:id" component={TagForm} />
                <Route exact={true} path="/newspapers" component={NewspaperList} />
                <Route path="/newspapers+" component={NewspaperForm} />
                <Route path="/newspapers/:id" component={NewspaperForm} />
                <Route exact={true} path="/users" component={UserList} />
                <Route path="/users+" component={UserForm} />
                <Route path="/users/:id" component={UserForm} />
                <Route exact={true} path="/journalists" component={JournalistList} />
                <Route path="/journalists+" component={JournalistForm} />
                <Route path="/journalists/:id" component={JournalistForm} />
                <Route exact={true} path="/pronunciations" component={PronunciationList} />
                <Route path="/pronunciations+" component={PronunciationForm} />
                <Route path="/pronunciations/:id" component={PronunciationForm} />
                <Route path="/epa" component={ExternalPublishersArticles} />
                <Route path="/epa-v2" component={ExternalPublishersArticlesV2} />
                <Route component={NotFound} />
            </Switch>
        );
    };

    public componentDidCatch(error: any, errorInfo: any) {
        if (process.env.NODE_ENV !== "development") {
            console.error(error);
            console.error(errorInfo);
            // @ts-ignore
            window.Raven.captureException(error, { extra: errorInfo });
        }
    }

    public render() {
        const { user } = this.props;
        let routes = null;

        if (!user) {
            routes = this.renderRoutesForLoggedOut();
        } else {
            routes = (
                <React.Fragment>
                    {user.userRoleIdList === UserRoles.SUPER_USER && this.renderRoutesForSuper()}
                    {user.userRoleIdList === UserRoles.NARRATOR && this.renderRoutesForNarrator()}
                    {user.userRoleIdList === UserRoles.NEWSPAPER_ADMIN && this.renderRoutesForNewspaper()}
                    {user.userRoleIdList === UserRoles.EDITOR && this.renderRoutesForEditor()}
                    {user.userRoleIdList === UserRoles.ADVERTISING && this.renderRoutesForAdvertiser()}
                </React.Fragment>
            );
        }

        return (
            // @ts-ignore
            <Router>
                <React.Fragment>
                    <Menu />
                    <main className={css(user && styles.main)}>
                        <div className="row">
                            <Route component={initializeRoute} />
                            {routes}
                        </div>
                        <Toaster />
                    </main>
                </React.Fragment>
            </Router>
        );
    }
}

const initializeRoute = () => {
    window.scrollTo(0, 0);
    return null;
};

function mapStateToProps(state: IState) {
    return {
        user: state.auth.user,
    };
}

const styles = StyleSheet.create({
    main: {
        paddingTop: 15,
    },
});

export default connect(mapStateToProps, {})(Routes);
