import { uniqueId } from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { fetchPronunciation } from "src/actions/article";
import { createPronunciation, editPronunciation } from "src/actions/pronunciation";
import IPronunciation from "src/types/pronunciation";

interface IPronAudioChecklistItem {
    pron: IPronunciation;
    originalPron: IPronunciation;
    lastFetchedAiPhonetic: string | null;
    audio: Blob | null;
    status?: "loading" | "loaded";
}

interface IPronAudioTextChecklistItem {
    id: string;
    pronunciationName: string;
    aiPhonetic: string;
    lastFetchedAiPhonetic: string | null;
    audio: Blob | null;
    status?: "loading" | "loaded";
}

interface IProps {
    editPronunciation: typeof editPronunciation;
    createPronunciation: typeof createPronunciation;
    onCreatePronunciation: (callback: () => void) => void;
    voiceId?: string;
    modelId?: string;
}

type IPronAudioChecklist = IPronAudioChecklistItem[];
type IPronAudioTextChecklist = IPronAudioTextChecklistItem[];

export default function usePronunciationChecklist({ editPronunciation, createPronunciation, onCreatePronunciation, voiceId, modelId }: IProps) {
    const [checklist, setChecklist] = useState<IPronAudioChecklist>([]);
    const [textChecklist, setTextChecklist] = useState<IPronAudioTextChecklist>([]);

    const addToChecklist = (pron: IPronunciation) => {
        if (checklist.some((item) => item.originalPron.pronunciationId === pron.pronunciationId)) {
            console.log("CAN'T ADD PRON TO CHECKLIST, PRON ALREADY PRESENT IN THE CHECKLIST.");
            return;
        }

        const pronTransformed: IPronunciation = { ...pron, aiPhonetic: !!pron.aiPhonetic?.trim()?.length ? pron.aiPhonetic.trim() : pron.pronunciationName.trim() };

        startFetchingAudio(pronTransformed);

        const checklistItem: IPronAudioChecklistItem = {
            pron: pronTransformed,
            originalPron: pron,
            audio: null,
            status: "loading",
            lastFetchedAiPhonetic: null,
        };

        setChecklist((prev) => [...prev, checklistItem]);
    };

    const setOnAudioFetched = (pronId: number, audioData: Blob | null, lastFetchedAiPhonetic?: string) => {
        setChecklist((prev) => {
            const checklistItemIndex = prev.findIndex((item) => item.originalPron.pronunciationId === pronId);

            if (checklistItemIndex === -1) {
                console.log("CAN'T UPDATE PRON AUDIO, DIDN'T FIND IN THE CHECKLIST.");
                return prev;
            }

            const origItem = prev[checklistItemIndex];

            const checklistItem: IPronAudioChecklistItem = { ...origItem, audio: audioData, status: "loaded", lastFetchedAiPhonetic: lastFetchedAiPhonetic || null };

            const prevCloned = [...prev];

            prevCloned[checklistItemIndex] = checklistItem;

            return prevCloned;
        });
    };

    const startFetchingAudio = async (pron: IPronunciation, shouldPlay?: boolean) => {
        try {
            const aiPhoneticToFetch = pron.aiPhonetic?.trim();

            if (!aiPhoneticToFetch) {
                toast.error("AI Phonetic can't be empty to fetch the audio.");
                console.log("CAN'T START AUDIO FETCHING, aiPhonetic IS EMPTY.");

                return;
            }

            const pronIndex = checklist.findIndex((item) => item.originalPron.pronunciationId === pron.pronunciationId);

            if (pronIndex !== -1) {
                // changing the status to loading
                setChecklist((prev) => {
                    const checklistItem: IPronAudioChecklistItem = { ...prev[pronIndex], status: "loading" };

                    const prevCloned = [...prev];

                    prevCloned[pronIndex] = checklistItem;

                    return prevCloned;
                });
            }

            const data = await fetchPronunciation(aiPhoneticToFetch!, voiceId, modelId);

            setOnAudioFetched(pron.pronunciationId, data, aiPhoneticToFetch);

            if (shouldPlay) {
                playAudioData(data);
            }
        } catch (e) {
            setOnAudioFetched(pron.pronunciationId, null);
            toast.error("Failed to load pronunciation audio.");
        }
    };

    const playPron = (pronId: number) => {
        const pron = checklist.find((item) => item.originalPron.pronunciationId === pronId);

        if (!pron) {
            console.log("UNABLE TO PLAY PRONUNCIATION, DIDN'T FIND IN THE CHECKLIST.");
            return;
        }

        const audioData = pron.audio;
        const isAiPhoneticDiffThanLastFetched = pron.pron.aiPhonetic !== pron.lastFetchedAiPhonetic;

        if (!audioData || isAiPhoneticDiffThanLastFetched) {
            console.log("UNABLE TO PLAY PRONUNCIATION, EITHER AUDIO IS NOT PRESENT OR AI PHONETIC CHANGED. AGAIN STARTING FETCHING.");

            startFetchingAudio(pron.pron, true);

            return;
        }

        playAudioData(pron.audio!);
    };

    const playAudioData = (audioData: Blob) => {
        const audio = new Audio(window.URL.createObjectURL(audioData));
        audio.load();
        audio.play();
    };

    const changePronAiPhonetic = (pronId: number, value: string) => {
        setChecklist((prev) => {
            const checklistItemIndex = prev.findIndex((item) => item.originalPron.pronunciationId === pronId);

            if (checklistItemIndex === -1) {
                console.log("CAN'T UPDATE PRON AI PHONETIC, DIDN'T FIND IN THE CHECKLIST.");
                return prev;
            }

            const origItem = prev[checklistItemIndex];
            const checklistItem: IPronAudioChecklistItem = { ...origItem, pron: { ...origItem.pron, aiPhonetic: value } };

            const prevCloned = [...prev];

            prevCloned[checklistItemIndex] = checklistItem;

            return prevCloned;
        });
    };

    const changeTextPronAiPhonetic = (pronId: string, value: string) => {
        setTextChecklist((prev) => {
            const checklistItemIndex = prev.findIndex((item) => item.id === pronId);

            if (checklistItemIndex === -1) {
                console.log("CAN'T UPDATE TEXT PRON AI PHONETIC, DIDN'T FIND IN THE CHECKLIST.");
                return prev;
            }

            const origItem = prev[checklistItemIndex];
            const checklistItem: IPronAudioTextChecklistItem = { ...origItem, aiPhonetic: value };

            const prevCloned = [...prev];

            prevCloned[checklistItemIndex] = checklistItem;

            return prevCloned;
        });
    };

    const saveAiPhonetic = (pronId: number) => {
        const pron = checklist.find((item) => item.originalPron.pronunciationId === pronId);

        if (!pron) {
            console.log("UNABLE TO SAVE PRON, DIDN'T FIND IN THE CHECKLIST.");
            return;
        }

        const aiPhonetic = pron.pron.aiPhonetic?.trim();
        const origAiPhonetic = pron.originalPron.aiPhonetic;

        if (aiPhonetic === origAiPhonetic) {
            console.log("NO NEED TO SAVE, PRONS ARE SAME");
            return;
        }

        if (!aiPhonetic) {
            console.log("CAN'T SAVE, AI PHONETIC IS EMPTY STRING.");
            return;
        }

        const pronToEdit = { ...pron.pron, aiPhonetic };

        (editPronunciation as any)(pronToEdit, undefined, undefined, undefined, { hideToast: true })
            .then(() => {
                setChecklist((prev) => {
                    const checklistItemIndex = prev.findIndex((item) => item.originalPron.pronunciationId === pronId);

                    const checklistItem: IPronAudioChecklistItem = { ...pron, pron: pronToEdit, originalPron: pronToEdit };

                    const prevCloned = [...prev];

                    prevCloned[checklistItemIndex] = checklistItem;

                    return prevCloned;
                });

                toast.success("Pronunciation Saved.");
            })
            .catch(() => {
                toast.error("Failed to save pronunciation.");
            });
    };

    const updatePron = (pron: IPronunciation) => {
        const checklistItemIndex = checklist.findIndex((item) => item.originalPron.pronunciationId === pron.pronunciationId);

        if (checklistItemIndex === -1) {
            console.log("CAN'T UPDATE PRON, DIDN'T FIND IN THE CHECKLIST.");
            return;
        }

        setChecklist((prev) => {
            const checklistItem: IPronAudioChecklistItem = { pron, originalPron: pron, audio: null, status: "loading", lastFetchedAiPhonetic: null };

            const prevCloned = [...prev];

            prevCloned[checklistItemIndex] = checklistItem;

            return prevCloned;
        });

        startFetchingAudio(pron);
    };

    const removePron = (pronId: number, noToast?: boolean) => {
        const checklistItemIndex = checklist.findIndex((item) => item.originalPron.pronunciationId === pronId);

        if (checklistItemIndex === -1) {
            console.log("CAN'T REMOVE PRON, DIDN'T FIND IN THE CHECKLIST.");
            return;
        }

        setChecklist((prev) => {
            const prevCloned = [...prev];

            prevCloned.splice(checklistItemIndex, 1);

            return prevCloned;
        });

        if (!noToast) {
            toast.success("Pronunciation removed from checklist.");
        }
    };

    const addTextToChecklist = (text: string) => {
        const cleanText = text.trim();

        if (!cleanText.length) {
            console.log("CAN'T ADD PRON TO TEXT CHECKLIST, TEXT IS EMPTY STRING");
            return;
        }

        const isAlreadyInChecklist = textChecklist.some((item) => item.pronunciationName === cleanText);

        if (isAlreadyInChecklist) {
            console.log("CAN'T ADD PRON TO TEXT CHECKLIST, TEXT ALREADY EXISTS");
            return;
        }

        const checklistItem: IPronAudioTextChecklistItem = {
            id: uniqueId(),
            pronunciationName: cleanText,
            aiPhonetic: cleanText,
            audio: null,
            status: "loading",
            lastFetchedAiPhonetic: null,
        };

        setTextChecklist((prev) => [...prev, checklistItem]);
        startFetchingTextPronAudio(checklistItem);
    };

    const setOnTextPronAudioFetched = (pronId: string, audioData: Blob | null, lastFetchedAiPhonetic?: string) => {
        setTextChecklist((prev) => {
            const checklistItemIndex = prev.findIndex((item) => item.id === pronId);

            if (checklistItemIndex === -1) {
                console.log("CAN'T UPDATE PRON AUDIO, DIDN'T FIND IN THE CHECKLIST.");
                return prev;
            }

            const origItem = prev[checklistItemIndex];

            const checklistItem: IPronAudioTextChecklistItem = { ...origItem, audio: audioData, status: "loaded", lastFetchedAiPhonetic: lastFetchedAiPhonetic || null };

            const prevCloned = [...prev];

            prevCloned[checklistItemIndex] = checklistItem;

            return prevCloned;
        });
    };

    const startFetchingTextPronAudio = async (pron: IPronAudioTextChecklistItem, shouldPlay?: boolean) => {
        try {
            const aiPhoneticToFetch = pron.aiPhonetic?.trim();

            if (!aiPhoneticToFetch) {
                toast.error("AI Phonetic can't be empty to fetch the audio.");
                console.log("CAN'T START TEXT PRON AUDIO FETCHING, aiPhonetic IS EMPTY.");

                return;
            }

            const pronIndex = textChecklist.findIndex((item) => item.id === pron.id);

            if (pronIndex !== -1) {
                // changing the status to loading
                setTextChecklist((prev) => {
                    const checklistItem: IPronAudioTextChecklistItem = { ...prev[pronIndex], status: "loading" };

                    const prevCloned = [...prev];

                    prevCloned[pronIndex] = checklistItem;

                    return prevCloned;
                });
            }

            const data = await fetchPronunciation(aiPhoneticToFetch!, voiceId, modelId);

            setOnTextPronAudioFetched(pron.id, data, aiPhoneticToFetch);

            if (shouldPlay) {
                playAudioData(data);
            }
        } catch (e) {
            setOnTextPronAudioFetched(pron.id, null);
            toast.error("Failed to load pronunciation audio.");
        }
    };

    const playTextPron = (pronId: string) => {
        const pron = textChecklist.find((item) => item.id === pronId);

        if (!pron) {
            console.log("UNABLE TO PLAY TEXT PRON, DIDN'T FIND IN THE CHECKLIST.");
            return;
        }

        const audioData = pron.audio;
        const isAiPhoneticDiffThanLastFetched = pron.aiPhonetic !== pron.lastFetchedAiPhonetic;

        if (!audioData || isAiPhoneticDiffThanLastFetched) {
            console.log("UNABLE TO PLAY TEXT PRON, EITHER AUDIO IS NOT PRESENT OR AI PHONETIC CHANGED. AGAIN STARTING FETCHING.");

            startFetchingTextPronAudio(pron, true);

            return;
        }

        playAudioData(pron.audio!);
    };
    const removeTextPronFromChecklist = (pronId: string, noToast?: boolean) => {
        const checklistItemIndex = textChecklist.findIndex((item) => item.id === pronId);

        if (checklistItemIndex === -1) {
            console.log("CAN'T REMOVE TEXT PRON, DIDN'T FIND IN THE TEXT CHECKLIST.");
            return;
        }

        setTextChecklist((prev) => {
            const prevCloned = [...prev];

            prevCloned.splice(checklistItemIndex, 1);

            return prevCloned;
        });

        if (!noToast) {
            toast.success("Pronunciation removed from checklist.");
        }
    };
    const saveTextPronAiPhonetic = (pronId: string) => {
        const pron = textChecklist.find((item) => item.id === pronId);

        if (!pron) {
            console.log("UNABLE TO SAVE TEXT PRON, DIDN'T FIND IN THE TEXT CHECKLIST.");
            return;
        }

        const aiPhonetic = pron.aiPhonetic?.trim();

        if (!aiPhonetic) {
            console.log("CAN'T SAVE TEXT PRON, AI PHONETIC IS EMPTY STRING.");
            return;
        }

        const pronToCreate: Partial<IPronunciation> = { pronunciationName: pron.pronunciationName, aiPhonetic };

        (createPronunciation as any)(pronToCreate, undefined, { hideToast: true })
            .then((data: { type: string; pronunciation: IPronunciation }) => {
                removeTextPronFromChecklist(pron.id, true);
                onCreatePronunciation(addToChecklist.bind(null, data.pronunciation));
                toast.success("Pronunciation created.");
            })
            .catch(() => {
                toast.error("Failed to create pronunciation.");
            });
    };

    const getChecklist = () => {
        return checklist.map((item) => {
            return {
                ...item,
                isLoading: item.status === "loading",
                canPlay: item.status !== "loading",
                canSave:
                    !!item.pron.aiPhonetic?.trim() &&
                    item.status !== "loading" &&
                    !!item.audio &&
                    item.pron.aiPhonetic?.trim() !== item.originalPron.aiPhonetic &&
                    item.pron.aiPhonetic?.trim() === item.lastFetchedAiPhonetic,
                canRemove: item.pron.aiPhonetic?.trim() === item.originalPron.aiPhonetic && item.status !== "loading" && !!item.audio && item.originalPron.aiPhonetic === item.lastFetchedAiPhonetic,
            };
        });
    };

    const getTextChecklist = () => {
        return textChecklist.map((item) => {
            return {
                ...item,
                isLoading: item.status === "loading",
                canPlay: item.status !== "loading",
                canSave: !!item.aiPhonetic?.trim() && item.status !== "loading" && !!item.audio && item.aiPhonetic?.trim() === item.lastFetchedAiPhonetic,
            };
        });
    };

    return {
        addToChecklist,
        playPron,
        changePronAiPhonetic,
        getChecklist,
        saveAiPhonetic,
        updatePron,
        removePron,
        addTextToChecklist,
        getTextChecklist,
        changeTextPronAiPhonetic,
        playTextPron,
        removeTextPronFromChecklist,
        saveTextPronAiPhonetic,
    };
}
