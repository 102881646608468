import { ChangeEvent, FC } from "react";
import { styled } from "styled-components";

interface IProps {
    checked: boolean;
    onChange: (status: boolean) => void;
    disabled?: boolean;
}

const ToggleSwitch: FC<IProps> = ({ checked, onChange, disabled }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!!disabled) return;

        onChange(e.target.checked);
    };

    return (
        <Label>
            <Input checked={checked} type="checkbox" onChange={handleChange} />
            <Switch />
        </Label>
    );
};

const Label = styled.label`
    cursor: pointer;
    display: inline-block;
`;

const Switch = styled.div`
    position: relative;
    width: 38px;
    height: 20px;
    background: #6b778c;
    border-radius: 32px;
    transition: 0.1s ease all;

    &:before {
        transition: 0.1s ease all;
        content: "";
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: 100px;
        top: 50%;
        left: 0px;
        background: white;
        transform: translate(4px, -50%);
    }
`;

const Input = styled.input`
    display: none;

    &:checked + ${Switch} {
        background: #007fbc;

        &:before {
            transform: translate(20px, -50%);
        }
    }
`;

export default ToggleSwitch;
