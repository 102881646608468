import { FC, useEffect } from "react";
import { styled } from "styled-components";

interface IProps {
    options: { text: string; value: string | number }[];
    onSelect: (value: string | number) => void;
    selected: string | number;
}

const RadioGroup: FC<IProps> = ({ options, onSelect, selected }) => {
    const onRadioSelect = (e: any) => {
        const value = e.target.value;
        const option = options.find((option) => option.value.toString() === value);

        if (option) {
            onSelect(option.value);
        }
    };

    useEffect(() => {
        onSelect(selected);
    }, []);

    return (
        <Wrapper onChange={onRadioSelect}>
            {options.map(({ text, value }) => (
                <label>
                    <input type="radio" value={value} name={text} checked={selected.toString() === value.toString()} /> <p>{text}</p>
                </label>
            ))}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    margin-left: 4px;

    label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        margin-bottom: 6px;

        user-select: none;
    }

    p {
        color: #091e42;
        font-size: 14px !important;

        margin: 0;
        padding: 0;
        margin-left: 8px;
    }

    input[type="radio"] {
        opacity: 1 !important;
        position: initial !important;
        pointer-events: auto !important;
        cursor: pointer;

        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        /* For iOS < 15 */
        background-color: var(--form-background);
        /* Not removed via appearance */
        margin: 0 !important;

        font: inherit;
        color: #007fbc;
        width: 16px;
        height: 16px;
        box-shadow: inset 0 0 0 2px #dfe1e6;
        border-radius: 50%;

        transition: 0.1s ease box-shadow;
    }

    input[type="radio"]:checked {
        box-shadow: inset 0 0 0 5.5px #007fbc;
    }
`;

export default RadioGroup;
