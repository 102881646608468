import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { ButtonFlat, Clickable, Col, Icon, ModalConfirm, Table, Td, Th } from "styleguide";

import { getArticleAudioTypeText, getNarrationType, missingFieldsToPublish } from "../services/article";
import IArticle from "../types/article";
import { ddmmyy } from "../utils/date";
import { downloadArticleAudioUrl } from "../utils/file";
import ModalGeneric from "./ModalGeneric";
import { canProjectLatestSnapshotBeFetched, fetchLatestAiAudioVersion } from "./ArticleFormEditor/utils";
import ArticleTranscriptGenerationStatus from "src/types/article-transcript-generation-status";
import ArticleAudioType from "src/types/article-audio-type";

interface IProps {
    title: string;
    articles?: IArticle[];
    goToArticleEdit: (articleId: number) => void;
    goToArticleView: (articleId: number) => void;
    goToAudio: (filename?: string) => void;
    togglePublishArticle: (article: IArticle) => void;
}

interface ILocalState {
    latestAudioFetchingStartedArticleIds: number[];
    activeEllipsisArticleId?: number;
}

export class ArticleListForEditors extends React.Component<IProps, ILocalState> {
    constructor(props: any) {
        super(props);
        this.state = { latestAudioFetchingStartedArticleIds: [], activeEllipsisArticleId: undefined };
    }

    public isLatestAudioFetchingStartedForArticleId = (articleId: number) => {
        return this.state.latestAudioFetchingStartedArticleIds.some((aid) => aid === articleId);
    };

    public setLatestAudioFetchingStartedForArticleId = (articleId: number) => {
        this.setState((prev) => ({ ...prev, latestAudioFetchingStartedArticleIds: [...prev.latestAudioFetchingStartedArticleIds, articleId] }));
    };

    public removeLatestAudioFetchingStartedForArticleId = (articleId: number) => {
        this.setState((prev) => ({ ...prev, latestAudioFetchingStartedArticleIds: [...prev.latestAudioFetchingStartedArticleIds.filter((aid) => aid !== articleId)] }));
    };

    public onFetchLatestAudio = (article: IArticle) => {
        this.setLatestAudioFetchingStartedForArticleId(article.articleID);

        fetchLatestAiAudioVersion(article, undefined, () => this.removeLatestAudioFetchingStartedForArticleId(article.articleID));
    };

    public tooglePublishArticle = (article: IArticle): boolean => {
        if (article.articleIsPublished === 1) {
            return true;
        }

        const missingFields = missingFieldsToPublish(article);
        if (missingFields.length > 0) {
            return false;
        }

        return true;
    };

    public performAudioAction = (article: IArticle) => {
        this.props.goToAudio(article.articleAudioFileName);
    };

    public renderListenToAudioModalContents = (article: IArticle) => {
        return (
            <div>
                {article.articleAudioFileName && article.articleAudioType !== 1 && (
                    <>
                        <br />
                        <ButtonFlat text="On-Platform Audio" onClick={() => this.props.goToAudio(article.articleAudioFileName)}></ButtonFlat>
                        <br />
                    </>
                )}
                {article.articleExternalAudioFileName && article.articleAudioType !== 0 && (
                    <>
                        <br />
                        <ButtonFlat text="Off-Platform Audio" onClick={() => this.props.goToAudio(article.articleExternalAudioFileName)}></ButtonFlat>
                    </>
                )}
            </div>
        );
    };

    public renderDownloadAudioModalContents = (article: IArticle) => {
        return (
            <div>
                {article.articleAudioFileName && article.articleAudioType !== 1 && (
                    <>
                        <br />
                        <ButtonFlat text="On-Pjatform Audio" onClick={() => downloadArticleAudioUrl(article.articleAudioFileName, article.audioUrl)}></ButtonFlat>
                        <br />
                    </>
                )}
                {article.articleExternalAudioFileName && article.articleAudioType !== 0 && (
                    <>
                        <br />
                        <ButtonFlat text="Off-Platform Audio" onClick={() => downloadArticleAudioUrl(article.articleExternalAudioFileName, article.externalAudioUrl)}></ButtonFlat>
                    </>
                )}
            </div>
        );
    };

    public renderListenToAudioButton = (article: IArticle) => {
        const hasAudio = article.articleAudioFileName || article.articleExternalAudioFileName;
        return (
            <ModalGeneric title="Choose an audio file to listen to" contents={this.renderListenToAudioModalContents(article)}>
                {(confirm: any) => (
                    <Clickable className={`${css(styles.icon)} hide-on-med-and-down`} onClick={confirm(() => this.performAudioAction(article))}>
                        <Icon icon="audiotrack" tooltip="Listen to audio" className={hasAudio ? "blue-text" : "black-text"} />
                    </Clickable>
                )}
            </ModalGeneric>
        );
    };

    public renderDownloadAudioButton = (article: IArticle) => {
        return (
            <ModalGeneric title="Choose an audio file to download" contents={this.renderDownloadAudioModalContents(article)}>
                {(confirm: any) => (
                    <Clickable className={`${css(styles.icon)} hide-on-med-and-down`} onClick={confirm(() => null)}>
                        <Icon icon="file_download" tooltip="Download audio" className={article.articleAudioFileName ? "blue-text" : "black-text"} />
                    </Clickable>
                )}
            </ModalGeneric>
        );
    };

    public onClickEllipsis = (articleId: number) => {
        if (articleId === this.state.activeEllipsisArticleId) return this.setState((prev) => ({ ...prev, activeEllipsisArticleId: undefined }));

        this.setState((prev) => ({ ...prev, activeEllipsisArticleId: articleId }));
    };

    public render() {
        const { articles, togglePublishArticle, title } = this.props;

        return (
            <Col s={12}>
                <Table title={title}>
                    {Array.isArray(articles) && articles.length > 0 && (
                        <thead>
                            <tr>
                                <Th hide="tablet">Id</Th>
                                <Th size="big">Name</Th>
                                <Th hide="mobile" size="small">
                                    Publisher
                                </Th>
                                <Th hide="tablet" size="small">
                                    Narrator
                                </Th>
                                <Th hide="tablet">Voice</Th>
                                <Th hide="tablet">Word Count</Th>
                                <Th hide="tablet">Units</Th>
                                <Th hide="tablet">Narration</Th>
                                <Th hide="tablet">Platform</Th>
                                <Th hide="mobile">Created At</Th>
                                <Th hide="mobile" size="medium">
                                    Audio Updated At
                                </Th>

                                <Th align="right">Actions</Th>
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        {Array.isArray(articles) &&
                            articles.length > 0 &&
                            articles.map((article) => {
                                const articlePath = `/articles/${article.articleID}`;
                                const articleViewPath = `/articles/${article.articleID}/view`;
                                const articleQaPath = `/articles/${article.articleID}/qa`;

                                const canFetchSnapshot = !this.isLatestAudioFetchingStartedForArticleId(article?.articleID) && canProjectLatestSnapshotBeFetched(article);
                                const isQaReportAvailable = !!article?.transcriptFileName && article?.transcriptGenerationStatus === ArticleTranscriptGenerationStatus.SUCCESS;

                                const audioDateTime = (() => {
                                    try {
                                        const articleAudioAddedDateTime = article?.articleAudioAddedDateTime;
                                        const articleExternalAudioAddedDateTime = article?.articleExternalAudioAddedDateTime;

                                        if (!articleAudioAddedDateTime && !articleExternalAudioAddedDateTime) return null;

                                        if (article?.articleAudioType === ArticleAudioType.BOTH && articleAudioAddedDateTime && articleExternalAudioAddedDateTime) {
                                            return new Date(Math.max(new Date(articleAudioAddedDateTime).getTime(), new Date(articleExternalAudioAddedDateTime).getTime()));
                                        }

                                        if (article?.articleAudioType === ArticleAudioType.INTERNAL && articleAudioAddedDateTime) {
                                            return new Date(articleAudioAddedDateTime);
                                        }

                                        if (article?.articleAudioType === ArticleAudioType.EXTERNAL && articleExternalAudioAddedDateTime) {
                                            return new Date(articleExternalAudioAddedDateTime);
                                        }
                                    } catch {
                                        return null;
                                    }

                                    return null;
                                })();

                                return (
                                    <tr key={article.articleID}>
                                        <Td hide="tablet">{article.articleID}</Td>
                                        <Td>{article.articleName}</Td>
                                        <Td hide="mobile">{article.newspaper && article.newspaper.newspaperName}</Td>
                                        <Td hide="tablet">{article.articleReader && article.articleReader.userFirstName}</Td>
                                        <Td hide="tablet">{article.voiceRequirements && article.voiceRequirements.length ? article.voiceRequirements.map((vr) => vr.abbreviation).join(", ") : "-"}</Td>
                                        <Td hide="tablet">{article.articleWordCount}</Td>
                                        <Td hide="tablet">{article.articleValue || "-"}</Td>
                                        <Td hide="mobile">{getNarrationType(article)}</Td>
                                        <Td hide="mobile">{getArticleAudioTypeText(article)}</Td>
                                        <Td hide="mobile">{article.articleAddedDateTime ? ddmmyy(new Date(article.articleAddedDateTime)) : "-"}</Td>
                                        <Td hide="mobile">
                                            <span
                                                className={audioDateTime ? "tooltipped" : ""}
                                                data-tooltip={
                                                    audioDateTime
                                                        ? new Intl.DateTimeFormat("en-US", {
                                                              hour: "numeric",
                                                              minute: "numeric",
                                                              second: "numeric",
                                                              hour12: true,
                                                          }).format(audioDateTime)
                                                        : "-"
                                                }
                                            >
                                                {audioDateTime ? ddmmyy(new Date(audioDateTime)) : "-"}
                                            </span>
                                        </Td>

                                        <Td align="right">
                                            <a className={css(styles.icon)} href={articlePath}>
                                                <Icon icon="edit" tooltip="Edit article" />
                                            </a>

                                            {isQaReportAvailable ? (
                                                <a className={`${css(styles.icon)} hide-on-med-and-down`} href={articleQaPath}>
                                                    <Icon icon="task" tooltip="QA report" />
                                                </a>
                                            ) : (
                                                <Clickable onClick={() => null} className={`${css(styles.icon)} hide-on-med-and-down`}>
                                                    <Icon icon="insert_page_break" className="grey-text" tooltip="QA report is unavailable" />
                                                </Clickable>
                                            )}

                                            {canFetchSnapshot ? (
                                                <ModalConfirm title="Are you sure?" description={`You are going to fetch a new audio file and replace the current audio file.`}>
                                                    {(confirm: any) => (
                                                        <Clickable className={`${css(styles.icon)} hide-on-med-and-down`} onClick={confirm(() => this.onFetchLatestAudio(article))}>
                                                            <Icon icon="sync" tooltip="Fetch latest audio" />
                                                        </Clickable>
                                                    )}
                                                </ModalConfirm>
                                            ) : (
                                                <Clickable onClick={() => null} className={`${css(styles.icon)} hide-on-med-and-down`}>
                                                    <Icon icon="sync_disabled" className="grey-text" tooltip="No audio" />
                                                </Clickable>
                                            )}

                                            {this.tooglePublishArticle(article) ? (
                                                <ModalConfirm
                                                    title="Confirmation"
                                                    description={`Are you sure that you want ${article.articleIsPublished === 1 ? "unpublish" : "publish"} this article?`}
                                                >
                                                    {(confirm: any) => (
                                                        <Clickable className={css(styles.icon)} onClick={confirm(() => togglePublishArticle(article))}>
                                                            {article.articleIsPublished === 1 ? (
                                                                <Icon icon="cloud_done" className="green-text" tooltip="Unpublish" />
                                                            ) : (
                                                                <Icon icon="cloud_queue" className="blue-text" tooltip="Publish" />
                                                            )}
                                                        </Clickable>
                                                    )}
                                                </ModalConfirm>
                                            ) : (
                                                <Clickable onClick={() => null} className={css(styles.icon)}>
                                                    <Icon icon="cloud_off" className="grey-text" tooltip="Required fields missing" />
                                                </Clickable>
                                            )}
                                            <Clickable onClick={() => this.onClickEllipsis(article.articleID)} className={`${css(styles.icon)} hide-on-med-and-down`}>
                                                <Icon icon="more_horiz" className="grey-text" />
                                            </Clickable>
                                            <div style={{ position: "relative" }}>
                                                <div
                                                    style={{
                                                        display: this.state.activeEllipsisArticleId === article.articleID ? "block" : "none",
                                                        position: "absolute",
                                                        top: "-36px",
                                                        right: "40px",
                                                        background: "#ffffff",
                                                        borderRadius: "5px",
                                                        padding: "8px 15px",
                                                        paddingBottom: "3px",
                                                        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                                                    }}
                                                >
                                                    <a className={`${css(styles.icon)} hide-on-med-and-down`} href={articleViewPath}>
                                                        <Icon icon="pageview" tooltip="View article script" />
                                                    </a>
                                                    {this.renderListenToAudioButton(article)}
                                                    {this.renderDownloadAudioButton(article)}
                                                </div>
                                            </div>
                                        </Td>
                                    </tr>
                                );
                            })}
                        {Array.isArray(articles) && articles.length === 0 && (
                            <tr>
                                <td className="center" colSpan={10}>
                                    EMPTY
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Col>
        );
    }
}

const styles = StyleSheet.create({
    icon: {
        "@media (max-width: 600px)": {
            paddingLeft: 5,
            paddingRight: 5,
        },
        paddingLeft: 6,
        paddingRight: 6,
        userSelect: "none",
    },
});

export default ArticleListForEditors;
