import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ButtonFixed, Col, Icon, Loading, Row, Table, Td, Th } from "styleguide";

import { fetchCompanies } from "../actions/company";
import config from "../config";
import { IState } from "../reducers";
import ICompany from "../types/company";
import * as errors from "../types/errors";
import { Helmet } from "react-helmet";
import { requestAllCompanies } from "../services/company";

interface IPropsFromState {
    companies: ICompany[];
    error?: errors.HttpError;
    isLoading: boolean;
}

interface IPropsFromDispatch {
    fetchCompanies: typeof fetchCompanies;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public renderListCompanies = () => {
        const { companies, history } = this.props;

        return (
            <>
                <Col s={12}>
                    <Table title="Companies">
                        <thead>
                            <tr>
                                <Th size="small">Id</Th>
                                <Th>Name</Th>
                                <Th>Domain</Th>
                                <Th>Slug URL</Th>
                                <Th>Advertiser?</Th>
                                <Th align="right">Actions</Th>
                            </tr>
                        </thead>
                        <tbody>
                            {companies.map((company) => (
                                <tr key={company.companyId}>
                                    <Td>{company.companyId}</Td>
                                    <Td>{company.companyName}</Td>
                                    <Td>{company.companyDomain}</Td>
                                    <Td>{company.slugUrl}</Td>
                                    <Td>{company.isAdvertisingEnabled === 1 ? "Yes" : "No"}</Td>
                                    <Td align="right">
                                        <a href={`/companies/${company.companyId}`}>
                                            <Icon icon="edit" />
                                        </a>
                                    </Td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.company}+`)} />
                </Col>
                <Row>
                    <Col s={12} />
                </Row>
            </>
        );
    };

    public componentDidMount() {
        this.props.fetchCompanies(requestAllCompanies());
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Companies</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderListCompanies()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        companies: state.company.companies,
        error: state.company.error,
        isLoading: state.company.isLoading,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            fetchCompanies,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
