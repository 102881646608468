import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ButtonFixed, Col, Loading, Table, Td, Th } from "styleguide";

import { deletePronunciation, fetchPronunciations, editPronunciation, verifyPronunciation } from "../actions/pronunciation";
import { clearSearch, doSearch } from "../actions/search";
import SearchForm from "../components/SearchForm";
import config from "../config";
import { IState } from "../reducers";
import { requestPronunciationsSearched } from "../services/pronunciation";
import { createDefaultSearch } from "../services/search";
import * as errors from "../types/errors";
import IPronunciation from "../types/pronunciation";
import ISearch from "../types/search";
import debounce from "../utils/debounce";
import PronunciationVerifTd from "../components/pronunciation/PronunciationVerifTd";
import IUser from "../types/user";
import PronunciationEditButtons from "../components/pronunciation/PronunciationEditButtons";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    pronunciations: IPronunciation[];
    error?: errors.HttpError;
    isLoading: boolean;
    offset: number;
    search: ISearch;
    user?: IUser;
}

interface IPropsFromDispatch {
    deletePronunciation: typeof deletePronunciation;
    fetchPronunciations: typeof fetchPronunciations;
    editPronunciation: typeof editPronunciation;
    verifyPronunciation: typeof verifyPronunciation;
    doSearch: typeof doSearch;
    clearSearch: typeof clearSearch;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public deletePronunciation = (pronunciation: IPronunciation) => {
        this.props.deletePronunciation(pronunciation);
    };

    public renderListPronunciations = () => {
        const { history, pronunciations, user, editPronunciation, verifyPronunciation, deletePronunciation, fetchPronunciations, search } = this.props;

        return (
            <Col s={12}>
                <Table title="Pronunciations">
                    <thead>
                        <tr>
                            <Th size="small">Id</Th>
                            <Th>Name</Th>
                            <Th>Phonetics</Th>
                            <Th>AI Phonetics</Th>
                            <Th>Link</Th>
                            <Th>Verify</Th>
                            <Th align="right">Actions</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {pronunciations.map((pronunciation) => (
                            <tr key={pronunciation.pronunciationId}>
                                <Td>{pronunciation.pronunciationId}</Td>
                                <Td>{pronunciation.pronunciationName}</Td>
                                <Td>{pronunciation.pronunciationComment}</Td>
                                <Td>{pronunciation.aiPhonetic}</Td>
                                <Td>
                                    <a href={pronunciation.pronunciationLink} target="_blank">
                                        {pronunciation.pronunciationLink}
                                    </a>
                                </Td>
                                <PronunciationVerifTd user={user} pronunciation={pronunciation} verifyPronunciation={verifyPronunciation} />
                                <Td align="right">
                                    <PronunciationEditButtons
                                        user={user}
                                        pronunciation={pronunciation}
                                        deletePronunciation={deletePronunciation}
                                        editPronunciation={editPronunciation}
                                        fetchPronunciations={fetchPronunciations}
                                        search={search}
                                        history={history}
                                    />
                                </Td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.pronunciation}+`)} />
            </Col>
        );
    };

    public renderSearch = () => {
        return <SearchForm onSubmit={this.submitForm} />;
    };

    public submitForm = (search: ISearch | any) => {
        this.props.doSearch(search);

        this.props.fetchPronunciations(requestPronunciationsSearched(search), true);
    };

    public componentDidMount() {
        window.onscroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                debounce(() => {
                    this.props.fetchPronunciations(requestPronunciationsSearched(this.props.search), false);
                });
            }
        };

        this.props.fetchPronunciations(requestPronunciationsSearched(createDefaultSearch()), true);
    }

    public componentWillUnmount() {
        this.props.clearSearch();
        window.onscroll = null;
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Pronunciations</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderSearch()}
                {this.renderListPronunciations()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        error: state.pronunciation.error,
        isLoading: state.pronunciation.isLoading,
        offset: state.journalist.offset,
        pronunciations: state.pronunciation.pronunciations,
        search: state.search,
        user: state.auth.user as IUser,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearSearch,
            deletePronunciation,
            doSearch,
            fetchPronunciations,
            editPronunciation,
            verifyPronunciation,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
