import { FC, useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { fetchExternalPublisherArticles, submitAudioToExternalPublisherArticle } from "src/actions/article";
import { TextButton } from "src/styles/TextButton";
import { IExternalPublisherArticle } from "src/types/external-publishers-articles";
import { OuterWrapper, Table, TableWrapper, Wrapper } from "./styles";
import toast from "react-hot-toast";
import ManualSubmission from "./ManualSubmission";
import { ButtonV2 } from "src/styles/ButtonV2";
import { useHistory } from "react-router-dom";

const ExternalPublishersArticles: FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isFailed, setIsFailed] = useState(false);
    const [articles, setArticles] = useState<IExternalPublisherArticle[]>([]);
    const [loadingArticlesIds, setLoadingArticlesIds] = useState<number[]>([]);
    const [isManualSubmission, setIsManualSubmission] = useState(false);
    const [nextPageToFetch, setNextPageToFetch] = useState<number | null>(null);
    const [isAutoFetchLoading, setIsAutoFetchLoading] = useState(false);
    const [isAutoFetchFailed, setIsAutoFetchFailed] = useState(false);

    const { push: redirectTo } = useHistory();

    const toggleManualSubmission = () => setIsManualSubmission(!isManualSubmission);

    const isSubmittingAudio = (articleId?: number) => {
        if (!articleId) return false;

        return loadingArticlesIds.some((aid) => aid === articleId);
    };

    const onSubmitAudio = (articleId?: number) => () => {
        if (!articleId) return;

        setLoadingArticlesIds((prev) => [...prev, articleId]);
        submitAudioToExternalPublisherArticle(articleId)
            .then(() => {
                setArticles((prev) => prev.filter((item) => item.article?.articleID !== articleId));

                toast.success("Audio submitted successfully.");
            })
            .catch(() => toast.error("Failed to submit audio."))
            .finally(() => setLoadingArticlesIds((prev) => [...prev].filter((aid) => aid !== articleId)));
    };

    const onAddArticle = (a: IExternalPublisherArticle) => {
        const articleText = a.articleText
            .replaceAll(/<p\sclass="c-recirculation-link"(?:(?!\n).|\n)*?<\/p>/g, "")
            .replaceAll("<p></p>", "")
            .split("\n")
            .filter((i) => !!i.trim().length)
            .join("<br>");

        redirectTo("/articles+", {
            epaData: { newspaperId: a.newspaper.newspaperID, title: a.title, url: a.publisherUrl, publisherArticleId: a.publisherArticleId, articleText },
        });
    };

    useEffect(() => {
        fetchExternalPublisherArticles(1)
            .then((eas) => {
                setArticles(eas.results);
                setNextPageToFetch(eas.hasNext ? eas.nextPage : null);
            })
            .catch(() => setIsFailed(true))
            .finally(() => setIsLoading(false));
    }, []);

    const fetchMoreArticles = () => {
        if (nextPageToFetch) {
            setIsAutoFetchLoading(true);
            fetchExternalPublisherArticles(nextPageToFetch)
                .then((eas) => {
                    setArticles((prev) => [...prev, ...eas.results]);
                    setNextPageToFetch(eas.hasNext ? eas.nextPage : null);
                })
                .catch(() => setIsAutoFetchFailed(true))
                .finally(() => setIsAutoFetchLoading(false));
        }
    };

    const onClickRestartAutoFetching = () => {
        setIsAutoFetchFailed(false);
        fetchMoreArticles();
    };

    useEffect(() => {
        fetchMoreArticles();
    }, [nextPageToFetch]);

    if (isLoading) return <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>Loading...</h4>;
    if (isFailed) return <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>Failed to fetch articles.</h4>;
    if (articles.length === 0) return <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>There is no articles available.</h4>;

    return (
        <OuterWrapper>
            <Wrapper>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>External Publishers Articles</title>
                </Helmet>
                {isManualSubmission && <ManualSubmission />}
                <ButtonV2 onClick={toggleManualSubmission} style={{ marginBottom: "30px" }}>
                    {isManualSubmission ? "Close" : "Manual Submission"}
                </ButtonV2>
                <TableWrapper>
                    <Table>
                        <thead>
                            <tr style={{ background: "none" }}>
                                <th>Publisher Article ID</th>
                                <th style={{ minWidth: "120px" }}>Publisher</th>
                                <th>Title</th>
                                <th>Publisher Date Modified</th>
                                <th>Audio Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {articles.map((a) => {
                                const audioStatus = a.article?.articleExternalAudioFileName?.trim()?.length ? "Has Audio" : "No Audio";
                                const dateModified = a.dateModified ? new Date(a.dateModified).toLocaleDateString() : "-";
                                const originalUrl = a.publisherUrl;
                                const articleUrl = a.article ? `/articles/${a.article.articleID}` : null;

                                const tdStyles: any = {};

                                if (!a.article) tdStyles.color = "red";

                                const isSubmitting = isSubmittingAudio(a?.article?.articleID);

                                return (
                                    <tr key={a.publisherArticleId}>
                                        <td>
                                            <span
                                                onClick={() => originalUrl && window.open(originalUrl, "_blank")}
                                                style={{ ...tdStyles, textDecoration: originalUrl ? "underline" : "none", cursor: originalUrl ? "pointer" : "default" }}
                                            >
                                                {a.publisherArticleId}
                                            </span>
                                        </td>
                                        <td>{a.newspaper.newspaperName}</td>
                                        <td
                                            onClick={() => articleUrl && window.open(articleUrl, "_blank")}
                                            style={{ textDecoration: articleUrl ? "underline" : "none", cursor: articleUrl ? "pointer" : "default" }}
                                        >
                                            {a.title || a.article?.articleName || "-"}
                                        </td>
                                        <td>{dateModified}</td>
                                        <td>{audioStatus}</td>
                                        <td>
                                            {audioStatus === "Has Audio" ? (
                                                <TextButton disabled={isSubmitting} onClick={onSubmitAudio(a.article?.articleID)}>
                                                    {isSubmitting ? <span style={{ filter: "grayscale(1)" }}>Submitting...</span> : "Submit Audio"}
                                                </TextButton>
                                            ) : (
                                                ""
                                            )}
                                            {!a.article && (
                                                <TextButton style={{ color: "#172b4d" }} onClick={() => onAddArticle(a)}>
                                                    + add
                                                </TextButton>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </TableWrapper>

                {isAutoFetchLoading && <p style={{ textAlign: "center", fontSize: "16px", marginTop: "50px" }}>Loading more...</p>}
                {isAutoFetchFailed && (
                    <p style={{ textAlign: "center", fontSize: "18px", marginTop: "50px" }}>
                        <span>Auto fetching failed.</span> <br /> <TextButton onClick={onClickRestartAutoFetching}>Restart auto fetching</TextButton>
                    </p>
                )}
            </Wrapper>
        </OuterWrapper>
    );
};

export default ExternalPublishersArticles;
