import styled from "styled-components";

export const OuterWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 30px;
    font-family: Poppins, sans-serif;

    .EPA_V2__article-name,
    .EPA_V2__publisher-article-id,
    .EPA_V2__article-comments {
        cursor: pointer;
        transition: 0.1s ease all;

        &:hover {
            color: #007fbc;
            text-decoration: underline;
        }
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1600px;
    padding: 20px;
    margin-top: 25px;
    margin-right: 0;
    margin-left: 0;
    background: #ffffff;
    border-radius: 1px;
`;

export const TableWrapper = styled.div`
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #dfe1e6;
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        cursor: pointer;
    }
`;

export const Table = styled.table`
    tr {
        border-bottom: none;
        &:nth-child(even) {
            background: #ffffff;
        }
        &:nth-child(odd) {
            background: rgba(242, 242, 242, 0.55);
        }
    }
    td {
        font-size: 14px;
        color: #172b4d;
        padding: 16px 0px;
        padding-right: 15px;

        &:first-child {
            padding-left: 10px;
        }
    }
    thead {
        border-bottom: 2px solid #dfe1e6;

        th {
            font-size: 13px;
            font-weight: 600;
            color: #6b778c;
            padding: 0;
            padding-bottom: 7px;
            padding-right: 10px;
        }
    }
`;
