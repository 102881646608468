import { FC, useEffect, useState } from "react";
import TranscriptionQa from "./TranscriptionQa";
import { fetchArticle } from "src/actions/article";
import { ILineBreak, IPronSelection, ISelection, ITranscript, ITranscriptExtended } from "./types";
import {
    addTimestampsToDiff,
    detectGroupsAndAddIdsToDiff,
    exportCsvReport,
    findLineBreaksInTranscript,
    formatTime,
    isAiGenerationType,
    isTranscriptAvailable,
    isTranscriptFailed,
    isTranscriptPending,
    jsonToCsv,
    mergeDiffItemsWithByGroupId,
    mergeDiffWithLineBreaksAsNoise,
    truncateTheWordsText,
} from "./utils";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import IArticle from "src/types/article";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { IState } from "src/reducers";
import { generateRandomString } from "src/utils/string";
import PronunciationsQa from "./PronunciationsQa";
import { fetchPronunciations } from "src/actions/pronunciation";
import { ALL_PRONUNCIATIONS_LIMIT } from "../ArticleForm";
import QaCompleted from "./QaCompleted";

interface IPropsFromState {
    isLoading: boolean;
    article?: IArticle;
}

interface IPropsFromDispatch {
    fetchArticle: typeof fetchArticle;
    fetchPronunciations: typeof fetchPronunciations;
}

interface IProps {
    setPageTitle: (title: string) => void;
}

const QaMain: FC<IProps & IPropsFromState & IPropsFromDispatch> = ({ article, isLoading, fetchArticle: fetchArticleFn, fetchPronunciations: fetchPronunciationsFn, setPageTitle }) => {
    const [qaType, setQaType] = useState<"TRANSCRIPTION" | "PRONUNCIATIONS" | "COMPLETED">("TRANSCRIPTION");
    const [transcript, setTranscript] = useState<ITranscriptExtended>();
    const [transcriptionSelections, setTranscriptionSelections] = useState<ISelection[]>([]);
    const [pronunciationsSelections, setPronunciationsSelections] = useState<IPronSelection[]>([]);

    const [lineBreaks, setLineBreaks] = useState<ILineBreak[]>();

    const { id: articleId } = useParams<{ id: string }>();

    const downloadReport = () => {
        const merged = mergeDiffItemsWithByGroupId<ISelection>(transcriptionSelections.filter((item) => item.selectionType === "PICKUP"));

        const transcriptSelectionsTransformed = merged.map((item) => ({
            type: item.type,
            timestamp: item.startTime ? formatTime(item.startTime * 1000) : "--",
            "original text": item.reference && truncateTheWordsText(item.type === "NOISE" ? `After "${item.reference}"` : item.reference, 50),
            "transcripted text": item.hypothesis && truncateTheWordsText(item.hypothesis, 50),
            word: "",
            phonetics: "",
            paragraph: item.line,
        }));
        const pronsSelectionsTransformed = pronunciationsSelections
            .filter((item) => item.selectionType === "PICKUP")
            .map((item) => ({
                type: "PRONUNCIATION",
                timestamp: item.diffItems?.[0]?.startTime ? formatTime(item.diffItems[0].startTime * 1000) : "--",
                "original text": "",
                "transcripted text": "",
                word: item.word,
                phonetics: isAiGenerationType(article) ? item.pron.aiPhonetic || "" : item.pron.pronunciationComment || "",
                paragraph: item.line,
            }));

        const pickups = [...transcriptSelectionsTransformed, ...pronsSelectionsTransformed];

        if (!pickups.length) {
            // it means all the issues were ignored
            return;
        }

        const csvText = jsonToCsv(pickups);

        const title = `NOA-QA-A${articleId}-T${new Date().getTime()}`;
        exportCsvReport(title, csvText);
    };

    const goToPronunciationsQa = () => setQaType("PRONUNCIATIONS");
    const goToCompletedQa = () => setQaType("COMPLETED");

    const saveTranscriptionSelections = (selections: ISelection[]) => {
        setTranscriptionSelections(selections);
        goToPronunciationsQa();
    };

    const savePronunciationsSelections = (selections: IPronSelection[]) => {
        setPronunciationsSelections(selections);
        goToCompletedQa();
    };

    const transcriptUrl = article?.transcriptUrl;

    useEffect(() => {
        fetchArticleFn(+articleId);
        fetchPronunciationsFn({ limit: ALL_PRONUNCIATIONS_LIMIT });

        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    useEffect(() => {
        const isAvailable = isTranscriptAvailable(article?.transcriptGenerationStatus);
        if (transcriptUrl && isAvailable) {
            fetch(transcriptUrl)
                .then((r) => r.text())
                .then((r) => {
                    const parsed = JSON.parse(r) as ITranscript;

                    // splitting the text into single individual words
                    const diffSplit = parsed.diff
                        .map((item) => {
                            if (item.type === "INSERTION") {
                                const hypothesisSplit = item.hypothesis?.trim()?.split(" ");
                                return hypothesisSplit?.map((t) => ({ ...item, hypothesis: t })) || [];
                            }

                            return item;
                        })
                        .flat();

                    // setting the pos and updating the id in mergeDiffWithLineBreaks
                    const diffExtended = diffSplit.map((item, index) => ({ ...item, pos: 0, id: generateRandomString(), startTime: null, endTime: null }));
                    const diffWithTimestamps = addTimestampsToDiff(diffExtended, parsed.transcript);
                    const diffWithGroupIds = detectGroupsAndAddIdsToDiff(diffWithTimestamps);

                    const paraBreaks = findLineBreaksInTranscript(article?.articleText || "", diffWithGroupIds);
                    const diffWithGroupIdsMergedWithLineBreaks = mergeDiffWithLineBreaksAsNoise(diffWithGroupIds, paraBreaks);

                    const diffItemUpdated = diffWithGroupIdsMergedWithLineBreaks.map((item, i) => ({ ...item, pos: i, id: `${i}-${item.id}` }));

                    setLineBreaks(paraBreaks);
                    setTranscript({ ...parsed, diff: diffItemUpdated });
                });
        }
    }, [transcriptUrl]);

    useEffect(() => {
        if (article?.articleID) {
            const title = `QA | ${article && article.articleID > 0 ? article?.articleName : ""}`;
            setPageTitle(title);
        }
    }, [article?.articleID]);

    const isFailed = isTranscriptFailed(article?.transcriptGenerationStatus);
    const isPending = isTranscriptPending(article?.transcriptGenerationStatus);

    if (isLoading) return <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>Loading...</h4>;
    if (isFailed) return <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>Transcription Failed.</h4>;
    if (isPending)
        return <h4 style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>Transcript is being generated. Please check in few mins.</h4>;
    if (!transcript || !lineBreaks) return null;

    if (qaType === "TRANSCRIPTION") return <TranscriptionQa transcript={transcript} article={article} lineBreaks={lineBreaks} saveTranscriptionSelections={saveTranscriptionSelections} />;

    if (qaType === "PRONUNCIATIONS")
        return <PronunciationsQa transcript={transcript} article={article} savePronunciationsSelections={savePronunciationsSelections} defaultSelections={pronunciationsSelections} />;

    if (qaType === "COMPLETED") return <QaCompleted pronunciationsSelections={pronunciationsSelections} transcriptionSelections={transcriptionSelections} downloadReport={downloadReport} />;

    return null;
};

function mapStateToProps(state: IState): IPropsFromState {
    return {
        article: state.article.article,
        isLoading: state.article.isLoading,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            fetchArticle,
            fetchPronunciations,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(QaMain);
