import { FC, RefObject, useEffect } from "react";
import { IDiffExtendedItemWithGroup, ISelection } from "./types";

interface IProps {
    sttEngine: string;
    transcriptId: string;
    diff: IDiffExtendedItemWithGroup[];
    showSubstitutions: boolean;
    showInsertions: boolean;
    showDeletions: boolean;
    showLineBreaks: boolean;
    isSensitive: boolean;
    qaTextWrapperRef: RefObject<HTMLDivElement>;
    isGroupActive: (item: IDiffExtendedItemWithGroup) => boolean;
    getActiveItem: () => { index: number; item: IDiffExtendedItemWithGroup } | undefined;
    isSubstitutionAllowed: (item: IDiffExtendedItemWithGroup, nextItem?: IDiffExtendedItemWithGroup) => boolean;
    getSelectionItemInfo: (item: IDiffExtendedItemWithGroup) => {
        selectionItem: ISelection | undefined;
        isIgnored: boolean;
        isPickedUp: boolean;
        isDisabled: boolean;
        isSelected: boolean;
        isActive: boolean;
    };
}

const ArticleQaText: FC<IProps> = ({
    sttEngine,
    transcriptId,
    diff,
    showSubstitutions,
    showInsertions,
    showDeletions,
    isSensitive,
    showLineBreaks,
    getSelectionItemInfo,
    isGroupActive,
    qaTextWrapperRef,
    getActiveItem,
    isSubstitutionAllowed,
}) => {
    const activeItem = getActiveItem();

    const checkSensitivity = (item: IDiffExtendedItemWithGroup) => {
        if (isSensitive || !!item.groupId) return true;

        let value: string | null = null;

        if (item.type === "DELETION") {
            value = item.reference || null;
        } else if (item.type === "INSERTION") {
            value = item.hypothesis || null;
        }

        if (!value || value.trim().split(" ").length > 1) return true;

        return false;
    };

    useEffect(() => {
        const wrapperItem = qaTextWrapperRef.current;
        const item = wrapperItem?.querySelector(".QA_WORD__active");
        if (wrapperItem && item) {
            wrapperItem.scrollTo({ top: (item as any).offsetTop + 20 - (wrapperItem.getBoundingClientRect().height - item.getBoundingClientRect().height) / 2, behavior: "smooth" });
        }
    }, [activeItem]);

    return (
        <p
            style={{
                color: "#000000",
                fontSize: "16px",
                lineHeight: "24px",
                background: "#fff",
                boxShadow: "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)",
                padding: "30px",
                paddingTop: "20px",
                fontFamily: "Poppins",
                maxWidth: "700px",
                margin: "0",
                borderRadius: "5px",
                width: "100%",
            }}
        >
            <span style={{ fontSize: "12px", color: "#727984" }}>
                <span style={{ fontWeight: 700, textTransform: "uppercase", marginRight: "12px" }}>{sttEngine}</span>
                <span style={{ fontWeight: 500 }}>{transcriptId}</span>
            </span>
            <br />
            <br />

            {diff.map((item, itemIndex) => {
                const itemType = item.type;
                const { isActive } = getSelectionItemInfo(item);
                const groupActiveStatus = isGroupActive(item);

                let activeStyles: (color?: string) => { [key: string]: any } = () => ({});

                const activeStatus = isActive || groupActiveStatus;

                if (activeStatus) {
                    // activeWrapperStyles = { background: "#202020", display: "inline-block", borderRadius: "6px", marginLeft: "4px" };
                    activeStyles = (color?: string) => ({ color, background: "#202020" });
                }

                if (itemType === "INSERTION" && !!showInsertions && checkSensitivity(item))
                    return (
                        <span
                            className={activeStatus ? "QA_WORD__active" : ""}
                            style={{ color: "#000000a8", fontWeight: 500, background: "rgb(206, 234, 214)", ...activeStyles("rgb(206, 234, 214)") }}
                        >
                            {" "}
                            <s>{item.hypothesis}</s>{" "}
                        </span>
                    );
                if (itemType === "DELETION" && !!showDeletions && checkSensitivity(item))
                    return (
                        <span
                            className={activeStatus ? "QA_WORD__active" : ""}
                            style={{ color: "#000000a8", fontWeight: 500, background: "rgb(250, 210, 207)", ...activeStyles("rgb(250, 210, 207)") }}
                        >
                            {" "}
                            {item.reference}{" "}
                        </span>
                    );
                if (itemType === "SUBSTITUTION" && !!showSubstitutions && (isSensitive ? true : isSubstitutionAllowed(item, diff?.[itemIndex + 1])))
                    return (
                        <span
                            className={activeStatus ? "QA_WORD__active" : ""}
                            style={{ color: "#000000a8", fontWeight: 500, background: "rgb(254, 239, 195)", ...activeStyles("rgb(254, 239, 195)") }}
                        >
                            {" "}
                            <u>{item.reference}</u>
                            <s>{item.hypothesis}</s>{" "}
                        </span>
                    );

                if (itemType === "NOISE") {
                    if (item.lineBreakDiffItem) {
                        if (showLineBreaks) {
                            return (
                                <>
                                    <br />
                                    <br />
                                    <span className={activeStatus ? "QA_WORD__active" : ""} style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                        <span style={{ marginBottom: "-2px", marginRight: "4px" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                                <path d="m560-120-57-57 144-143H200v-480h80v400h367L503-544l56-57 241 241-240 240Z" />
                                            </svg>
                                        </span>
                                        <span style={{ color: "000000a8", fontWeight: 500, ...activeStyles("rgb(225, 226, 255)") }}>Line Break</span>
                                    </span>
                                    <br />
                                </>
                            );
                        }

                        return (
                            <>
                                <br />
                                <br />
                            </>
                        );
                    }

                    if (showLineBreaks) {
                        return (
                            <span
                                className={activeStatus ? "QA_WORD__active" : ""}
                                style={{ color: "000000a8", fontWeight: 500, background: "rgb(225, 226, 255)", ...activeStyles("rgb(225, 226, 255)") }}
                            >
                                {" "}
                                {item.reference}{" "}
                            </span>
                        );
                    }
                    return "";
                }

                return ` ${item.reference || ""}`;
            })}
        </p>
    );
};

export default ArticleQaText;
