import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Col, InputField, Row, Tooltipped } from "styleguide";

import { renderInput } from "../utils/input";
import { required } from "../utils/validations";
import { ButtonV2 } from "src/styles/ButtonV2";
import { fetchPronunciation } from "src/actions/article";
import toast from "react-hot-toast";

interface IProps {
    small?: boolean;
}
export class PronunciationForm extends React.Component<InjectedFormProps<IProps> & IProps> {
    private aiPhoneticRef: any;

    constructor(props: any) {
        super(props);
        this.aiPhoneticRef = React.createRef();
    }

    playPronunciation = async (e: any) => {
        e.preventDefault();

        const text = this.aiPhoneticRef?.current?.value?.trim();

        if (!text) {
            return toast.error("Invalid AI Phonetic!");
        }

        try {
            const data = await fetchPronunciation(text);

            const audio = new Audio(window.URL.createObjectURL(data));
            audio.load();
            audio.play();
        } catch (e) {
            toast.error("Failed to play pronunciation.");
        }

        return;
    };

    public render() {
        const { small, handleSubmit, valid } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col s={12}>
                        {small && (
                            <i className={`${css(styles.icon)} material-icons postfix blue-text`} onClick={handleSubmit}>
                                add
                            </i>
                        )}

                        <Row>
                            <div className={css(small && styles.form)}>
                                <Col s={12} m={6}>
                                    <InputField>
                                        <label htmlFor="pronunciationName">
                                            <Tooltipped tooltip="If you want a pronunciation to only show for certain instances of a word, add a tilde (~) in front of it.">Text (?) *</Tooltipped>
                                        </label>

                                        <Field name="pronunciationName" type="text" component={renderInput} validate={required} />
                                    </InputField>
                                </Col>
                                <Col s={12} m={6}>
                                    <InputField>
                                        <label htmlFor="pronunciationComment">Phonetic</label>
                                        <Field name="pronunciationComment" type="text" component={renderInput} />
                                    </InputField>
                                </Col>
                                <Col s={12}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%" }}>
                                            <InputField>
                                                <label htmlFor="aiPhonetic">AI Phonetic</label>
                                                <Field ref={this.aiPhoneticRef} name="aiPhonetic" type="text" component={renderInput} />
                                            </InputField>
                                        </div>
                                        <ButtonV2 onClick={this.playPronunciation} style={{ marginLeft: "20px", marginBottom: "-15px" }}>
                                            Play
                                        </ButtonV2>
                                    </div>
                                </Col>
                                <Col s={12} m={12}>
                                    <InputField>
                                        <label htmlFor="pronunciationLink">Link</label>
                                        <Field name="pronunciationLink" type="text" component={renderInput} />
                                    </InputField>
                                </Col>
                            </div>
                        </Row>
                    </Col>

                    {!small && (
                        <Col s={12}>
                            <div className="center">
                                <Button text="save" icon="save" submit={true} disable={!valid} />
                            </div>
                        </Col>
                    )}
                </Row>
            </form>
        );
    }
}

const styles = StyleSheet.create({
    form: {
        marginBottom: 0,
    },
    icon: {
        cursor: "pointer",
        float: "right",
        marginTop: "-42px",
    },
});

export default reduxForm<{}, IProps>({
    enableReinitialize: true,
    form: "PronunciationForm",
})(PronunciationForm);
