import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";
import { Button, tooltip } from "styleguide";

import config from "../config";
import IArticle from "../types/article";
import IJournalist from "../types/journalist";
import INewspaper from "../types/newspaper";
import IPronunciation from "../types/pronunciation";
import ITemplate from "../types/template";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";
import { countAllWordsInArticleScript } from "../utils/count-words";
import PronunciationVerifTd from "./pronunciation/PronunciationVerifTd";
import PronunciationEditButtons from "./pronunciation/PronunciationEditButtons";
import ISearch from "../types/search";
import TemplateType from "src/types/template-types";

interface IProp {
    article?: IArticle;
    newspapers?: INewspaper[];
    journalists?: IJournalist[];
    templates?: ITemplate[];
    narrators?: IUser[];
    pronunciations?: IPronunciation[];
    dynamic: boolean;
    getTemplate?: (type: TemplateType) => string;
    user?: IUser;
    print: boolean;
    history: any;
    search: ISearch;
    deletePronunciation: any;
    fetchPronunciations: any;
    editPronunciation: any;
    verifyPronunciation: any;
}

interface IState {
    text: string;
    footer: string;
    header: string;
    numWords: number;
    highlightWholeTextDebounced: (props: IProp) => void;
    loading: boolean;
}

const DYNAMIC_DEBOUNCE_TIME = 1000;
const DEFAULT_DEBOUNCE_TIME = 10;

export class ArticleScript extends React.Component<IProp, IState> {
    constructor(props: IProp) {
        super(props);

        const debounceTime = props.dynamic ? DYNAMIC_DEBOUNCE_TIME : DEFAULT_DEBOUNCE_TIME;

        const highlightWholeTextDebounced = _.debounce(this.highlightWholeText, debounceTime);

        if (props.user?.userRoleIdList === UserRoles.EDITOR || props.user?.userRoleIdList === UserRoles.SUPER_USER) {
            highlightWholeTextDebounced(props);
        }

        this.state = {
            footer: "",
            header: "",
            highlightWholeTextDebounced,
            loading: false,
            numWords: 0,
            text: "",
        };
    }

    public getPronunciation = (word: string, pronunciationName: string) => {
        const r = new RegExp(_.escapeRegExp(pronunciationName), "gi");
        const splittedWord = _.split(word, r, 2);

        if (splittedWord && splittedWord.length > 1) {
            const returnWord = _.replace(word.substring(1, word.length - 1), "~", "");

            return {
                prefix: splittedWord[0],
                suffix: splittedWord[1],
                word: returnWord,
            };
        }
        return { prefix: "", suffix: "", word };
    };

    public createPronunciationTooltip = (pron: IPronunciation, fullWord: string) => {
        /*const { prefix, suffix, word } = this.getPronunciation(
        fullWord,
        pron.pronunciationName
    );*/
        const link = pron.pronunciationLink ? `href="${pron.pronunciationLink}"` : "";
        const className = pron.pronunciationComment ? 'class="tooltipped"' : "";
        const style = pron.pronunciationLink ? 'style="text-decoration: underline;"' : "";
        const tooltipAtt = pron.pronunciationComment ? `data-tooltip="${_.escape(pron.pronunciationComment)}"` : "";

        return `<a ${className} ${link} ${tooltipAtt} ${style} pronunciation-id="${pron.pronunciationId}" target="_blank"  >${fullWord}</a>`;
    };

    public createWarningTooltip = (fullWord: string) => {
        return `<a class="red-text tooltipped" target="_blank" data-tooltip="Word could be wrong" >${fullWord}</a>`;
    };

    public highlight = (text: string): string => {
        const { pronunciations, user } = this.props;

        let textUpdated = _.clone(text);

        if (user && (user.userRoleIdList === UserRoles.EDITOR || user.userRoleIdList === UserRoles.SUPER_USER)) {
            const warnWords = this.getWarnWordsToInsert(textUpdated);

            warnWords.forEach((warnWord: string) => {
                const warnTooltip = this.createWarningTooltip(warnWord);
                textUpdated = textUpdated.replace(new RegExp("\\b" + _.escapeRegExp(warnWord) + "\\b", "gi"), warnTooltip);
            });
        }

        const pronunciationsToHighlight = this.getPronunciationsToInsert(text, pronunciations);

        pronunciationsToHighlight.forEach((pronToHighlight) => {
            const pronTooltip = this.createPronunciationTooltip(pronToHighlight.pron, pronToHighlight.word);
            textUpdated = textUpdated.replace(new RegExp("\\b" + _.escapeRegExp(pronToHighlight.word) + "(?![^<>&]*[>])\\b", "g"), pronTooltip);
        });

        return textUpdated;
    };

    public getPronunciationsToInsert = (text: string, pronunciations?: IPronunciation[]) => {
        if (!pronunciations) {
            return [];
        }

        const pronunciationsToHighlight: Array<{ pron: IPronunciation; word: string }> = [];

        // First, do an inaccurate but cheap filter of pronuncations to reduce the list size
        const pronsContained = pronunciations.filter((pron) => text.indexOf(pron.pronunciationName) !== -1);

        // Then do the expensive matching
        pronsContained.forEach((p) => {
            const regex = new RegExp("\\b" + _.escapeRegExp(p.pronunciationName) + "\\b", "g");
            const match = text.match(regex);

            if (Array.isArray(match) && match.length > 0) {
                const word = match[0];
                pronunciationsToHighlight.push({ pron: p, word });
            }
        });

        return pronunciationsToHighlight;
    };

    public getWarnWordsToInsert = (text: string) => {
        const warnWordsContained = config.warnKeywords.filter((word: string) => text.indexOf(word) !== -1);

        const wordsToInsert: string[] = [];

        warnWordsContained.forEach((warnWord: string) => {
            const regex = new RegExp("\\b" + _.escapeRegExp(warnWord.toLowerCase()) + "\\b", "gi");
            const match = text.match(regex);

            if (Array.isArray(match) && match.length > 0) {
                const word = match[0];
                wordsToInsert.push(word);
            }
        });

        return wordsToInsert;
    };

    public highlightWholeText = (props: IProp) => {
        const { article } = props;

        const newArticleText = (article && article.articleText) || "";

        const footerText = this.props.getTemplate ? this.props.getTemplate(TemplateType.OUTRO) : "";
        const headerText = this.props.getTemplate ? this.props.getTemplate(TemplateType.INTRO) : "";

        const articleTextHighlighted = this.highlight(newArticleText);
        const footerTextHighlighted = this.highlight(footerText);
        const headerTextHighlighted = this.highlight(headerText);

        const numWords = countAllWordsInArticleScript(article);

        this.setState({
            footer: footerTextHighlighted,
            header: headerTextHighlighted,
            loading: false,
            numWords,
            text: articleTextHighlighted,
        });

        tooltip();
    };

    public componentDidUpdate = () => {
        this.state.highlightWholeTextDebounced(this.props);
    };

    public componentWillReceiveProps = (nextProps: IProp) => {
        const { user } = nextProps;
        if (user && (user.userRoleIdList === UserRoles.EDITOR || user.userRoleIdList === UserRoles.SUPER_USER)) {
            this.setState({ loading: true });
        }
    };

    public shouldComponentUpdate(nextProps: IProp, nextState: IState) {
        if (!_.isEqual(this.props.pronunciations, nextProps.pronunciations)) {
            return true;
        }

        if (this.props.journalists !== nextProps.journalists || (this.props.journalists && nextProps.journalists && this.props.journalists.length !== nextProps.journalists.length)) {
            return true;
        }

        if (this.props.narrators !== nextProps.narrators || (this.props.narrators && nextProps.narrators && this.props.narrators.length !== nextProps.narrators.length)) {
            return true;
        }

        if (this.props.newspapers !== nextProps.newspapers || (this.props.newspapers && nextProps.newspapers && this.props.newspapers.length !== nextProps.newspapers.length)) {
            return true;
        }

        if (this.props.pronunciations !== nextProps.pronunciations || (this.props.pronunciations && nextProps.pronunciations && this.props.pronunciations.length !== nextProps.pronunciations.length)) {
            return true;
        }

        if (this.props.templates !== nextProps.templates || (this.props.templates && nextProps.templates && this.props.templates.length !== nextProps.templates.length)) {
            return true;
        }

        if (
            this.props.article &&
            nextProps.article &&
            (this.props.article.articleText !== nextProps.article.articleText ||
                this.props.article.articleComments !== nextProps.article.articleComments ||
                this.props.article.articleName !== nextProps.article.articleName ||
                this.props.article.altNameForNarration !== nextProps.article.altNameForNarration ||
                this.props.article.articleSection !== nextProps.article.articleSection ||
                this.props.article.newspaperId !== nextProps.article.newspaperId ||
                this.props.article.journalistsId !== nextProps.article.journalistsId ||
                this.props.article.articleAudioRecorderID !== nextProps.article.articleAudioRecorderID ||
                this.props.article.introId !== nextProps.article.introId ||
                this.props.article.outroId !== nextProps.article.outroId)
        ) {
            return true;
        }

        if (this.state.text !== nextState.text) {
            return true;
        }

        if (this.state.footer !== nextState.footer) {
            return true;
        }

        if (this.state.loading !== nextState.loading) {
            return true;
        }

        return false;
    }

    public getPronunciationsUsed = () => {
        const { footer, text } = this.state;
        const { pronunciations } = this.props;
        const pattern = /pronunciation\-id=\"(.*?)\"/g;
        const matchText = text.match(pattern);
        const matchFooter = footer.match(pattern);
        const pronunciationsId: string[] = [];

        if (matchText && matchText.length > 0) {
            const prons = matchText.map((t) => t.replace('pronunciation-id="', "").replace('"', ""));
            pronunciationsId.push(...prons);
        }

        if (matchFooter && matchFooter.length > 0) {
            const prons = matchFooter.map((t) => t.replace('pronunciation-id="', "").replace('"', ""));

            pronunciationsId.push(...prons);
        }

        const finalProns = _.filter(pronunciations, (p) => _.includes(pronunciationsId, `${p.pronunciationId}`));

        return finalProns;
    };

    public renderPronunciationVerification = (p: IPronunciation) => {
        return <PronunciationVerifTd user={this.props.user} pronunciation={p} verifyPronunciation={this.props.verifyPronunciation} />;
    };

    public render() {
        const { user, article, journalists, narrators, newspapers, print, history, search, deletePronunciation, fetchPronunciations, editPronunciation } = this.props;
        const { text, header, footer, numWords, loading } = this.state;

        if (!article || !newspapers || !journalists || !narrators) {
            return null;
        }

        const textWithBreakLines = _.chain(text)
            .replace(/<p><\/p>/g, "<p><br/></p>")
            .replace(/\n\n/g, "<p><br/></p>")
            .value();

        const commentsWithBreakLines = _.replace(article.articleComments || "", /\n/g, "<br/>");
        const prons = this.props.user?.userRoleIdList === UserRoles.NARRATOR ? this.getPronunciationsUsed() : [];

        return (
            <div>
                {loading && <h5 className={css(styles.refresh)}>Refreshing...</h5>}
                {prons && prons.length > 0 && (
                    <table className="responsive-table">
                        <thead>
                            <tr>
                                <th>Pronunciation</th>
                                <th>Comment</th>
                                <th>Link</th>
                                <th>Verified?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {prons.map((p) => (
                                <tr key={p.pronunciationId}>
                                    <td>{_.replace(p.pronunciationName, "~", "")}</td>
                                    <td>{p.pronunciationComment}</td>
                                    <td>
                                        {p.pronunciationLink && (
                                            <a href={p.pronunciationLink} target="_blank" rel="noreferrer">
                                                link
                                            </a>
                                        )}
                                    </td>
                                    <PronunciationVerifTd user={user} pronunciation={p} verifyPronunciation={this.props.verifyPronunciation} />
                                    <td>
                                        <PronunciationEditButtons
                                            user={user}
                                            pronunciation={p}
                                            deletePronunciation={deletePronunciation}
                                            editPronunciation={editPronunciation}
                                            fetchPronunciations={fetchPronunciations}
                                            search={search}
                                            history={history}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                <br />
                {commentsWithBreakLines && (
                    <>
                        <h5>Comments</h5>
                        <span
                            className={css(styles.overflow)}
                            dangerouslySetInnerHTML={{
                                __html: commentsWithBreakLines || "",
                            }}
                        />
                    </>
                )}
                <br />
                <div>
                    <h4 className={css(styles.overflow)}>{article.altNameForNarration ? article.altNameForNarration : article.articleName}</h4>
                    <br />
                    <p
                        className={css(styles.overflow)}
                        dangerouslySetInnerHTML={{
                            __html: header || "",
                        }}
                    />
                    <br />
                    <br />
                    <p className={css(styles.overflow)} dangerouslySetInnerHTML={{ __html: textWithBreakLines || "" }} />
                    <p className={css(styles.overflow)} dangerouslySetInnerHTML={{ __html: footer || "" }} />
                </div>
                <div className="row noprint" />
                {!!print && (
                    <div className="center">
                        <Button className="noprint" onClick={() => window.print()} text="Print Article" />
                    </div>
                )}
                <p id="article-word-count-text" className={css(styles.wordsCount)}>
                    Word count: {numWords.toLocaleString()}
                </p>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    footer: {
        fontStyle: "italic",
        fontWeight: "bold",
    },
    overflow: {
        overflowWrap: "break-word",
    },
    refresh: {
        background: "#2196f3",
        borderRadius: 30,
        color: "white",
        left: "35%",
        padding: 10,
        position: "absolute",
        top: 0,
    },
    wordsCount: {
        float: "left",
        fontSize: 12,
        height: 1,
        marginTop: 0,
    },
});

export default ArticleScript;
