import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ButtonFixed, Col, Collapsible, CollapsibleItem, Icon, Loading, Row, Table, Td } from "styleguide";

import { clearSectionState, fetchSections } from "../actions/section";
import config from "../config";
import { IState } from "../reducers";
import * as errors from "../types/errors";
import ISection from "../types/section";
import SectionTypes from "../types/section-types";
import { Helmet } from "react-helmet";
import { css, StyleSheet } from "aphrodite";

interface IPropsFromState {
    sections: ISection[];
    error?: errors.HttpError;
    isLoading: boolean;
}

interface IPropsFromDispatch {
    fetchSections: typeof fetchSections;
    clearSectionState: typeof clearSectionState;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public renderListSections = () => {
        const { history, sections } = this.props;

        return (
            <>
                <Col s={12}>
                    <div className="collapsible-table-wrapper">
                        <Collapsible title="Categories" accordion={true}>
                            <div className={css(styles.header)}>
                                <p className={css(styles.headerItem)}>Id - Name</p>
                                <p className={css(styles.headerItem)}>Is Published?</p>
                                <p className={css(styles.headerItem)}>Actions</p>
                            </div>

                            {sections
                                .filter((section) => !section.parentId)
                                .map((section) => {
                                    const subSections = sections.filter((subSection) => subSection.parentId === section.sectionId);

                                    return (
                                        <CollapsibleItem
                                            text={`${section.sectionId} - ${section.sectionName}`}
                                            key={section.sectionId}
                                            renderRight={
                                                <>
                                                    <p className={css(styles.sectionIsPublished)}>{section.sectionIsPublished === 1 ? "true" : "false"}</p>
                                                    <a href={`/categories/${section.sectionId}`}>
                                                        <Icon icon="edit" />
                                                    </a>
                                                </>
                                            }
                                        >
                                            {!subSections.length ? (
                                                <h2 className={css(styles.subSectionsNotFound)}>No Sub-categories found</h2>
                                            ) : (
                                                <Table title="Sub-categories">
                                                    {/* <thead>
                                                <tr>
                                                    <Th>Name</Th>
                                                    <Th align="right">
                                                        <span className={css(styles.sectionIsPublishedHeaderInner)}>Is Published?</span>Actions
                                                    </Th>
                                                </tr>
                                            </thead> */}
                                                    <div className={css(styles.separator)} />
                                                    <tbody>
                                                        {subSections.map((subSection) => {
                                                            return (
                                                                <tr key={subSection.sectionId}>
                                                                    <Td>{subSection.sectionId}</Td>
                                                                    <Td>{subSection.sectionName}</Td>
                                                                    <Td align="right">
                                                                        <span className={css(styles.sectionIsPublishedInner)}>{subSection.sectionIsPublished === 1 ? "true" : "false"}</span>
                                                                        <a href={`/categories/${subSection.sectionId}`}>
                                                                            <Icon icon="edit" />
                                                                        </a>
                                                                    </Td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </Table>
                                            )}
                                        </CollapsibleItem>
                                    );
                                })}
                        </Collapsible>
                    </div>
                </Col>
                <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.section}+`)} />
                <Row>
                    <Col s={12} />
                </Row>
            </>
        );
    };

    public componentDidMount() {
        this.props.fetchSections({
            filter: [{ name: "sectionType", value: SectionTypes.Regular }],
        });
    }

    public componentWillUnmount() {
        this.props.clearSectionState();
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Categories</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderListSections()}
            </React.Fragment>
        );
    }
}

const styles = StyleSheet.create({
    header: {
        display: "flex",
        alignItem: "center",
        justifyContent: "flex-start",
        paddingLeft: 45,
        paddingRight: 27,
    },
    headerItem: {
        fontSize: 15,
        fontWeight: "bold",
        color: "#000000",
        padding: "15px 5px",
        ":nth-child(2)": {
            marginLeft: "auto",
            marginRight: 80,

            "@media(max-width: 500px)": {
                marginRight: 0,
            },
        },
    },
    sectionIsPublished: {
        "@media(max-width: 500px)": {
            marginRight: 50,
        },
        marginRight: 170,
    },

    // sectionIsPublishedHeaderInner: {
    //     "@media(max-width: 500px)": {
    //         marginRight: 10,
    //     },
    //     marginRight: 60,
    // },

    sectionIsPublishedInner: {
        "@media(max-width: 500px)": {
            marginRight: 40,
        },
        marginRight: 145,
    },

    separator: {
        height: 1,
        position: "absolute",
        width: "100% !important",
        transform: "translateX(-24px)",
        background: "rgb(82 82 82 / 8%)",
    },

    subSectionsNotFound: {
        fontSize: "20px",
        color: "rgba(0, 0, 0, 0.9)",
        textAlign: "center",
        margin: "auto",
    },

    collapsibleWrapper: {
        li: {
            background: "red !important",
        },
    },
});

function mapStateToProps(state: IState): IPropsFromState {
    return {
        error: state.section.error,
        isLoading: state.section.isLoading,
        sections: state.section.sections,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearSectionState,
            fetchSections,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
