const common = {
    api: {
        paths: {
            advert: "/adverts",
            article: "/articles",
            articletemplate: "/articletemplates",
            audience: "/audiences",
            audio: "/audios",
            category: "/categories",
            company: "/companies",
            image: "/images",
            journalist: "/journalists",
            login: "/login",
            metric: "/metrics",
            newspaper: "/newspapers",
            playlist: "/playlists",
            pronunciation: "/pronunciations",
            section: "/sections",
            suggestedPlaylists: "/suggestions/playlists",
            suggestedTags: "/discover/tags",
            tag: "/tags",
            template: "/templates",
            user: "/users",
            country: "/country",
            setting: "/settings",
            specialsections: "/specialsections",
            voicerequirements: "/voicerequirements",
            podcastplatforms: "/podcastplatforms",
            elevenlabs: "/elevenlabs",
            externalPublishersArticles: "/external-publishers-articles",
            externalPublishersArticlesV2: "/external-publishers-articles",
        },
    },
    paths: {
        advert: "/adverts",
        article: "/articles",
        audience: "/audiences",
        category: "/categories",
        company: "/companies",
        dashboard: "/",
        journalist: "/journalists",
        login: "/",
        newspaper: "/newspapers",
        playlist: "/playlists",
        pronunciation: "/pronunciations",
        section: "/categories",
        specialsections: "/specialsections",
        tag: "/tags",
        template: "/templates",
        user: "/users",
        setting: "/settings",
        editorpicks: "/editorpicks",
        epa: "/epa",
        epaV2: "/epa-v2",
    },
    ravenUrl: "https://070fcdfa6c9844d2b9c89d76d5e3bafc@sentry.io/1283304",
    rules: {
        playlist: {
            maxDescriptionFirstParagraphLength: 161,
            maxCuratorQuoteLength: 700,
            maxNameLength: 52,
            minDescriptionLength: 124,
        },
    },
    warnKeywords: [
        "today",
        "yesterday",
        "tomorrow",
        "this week",
        "last week",
        "next week",
        "this month",
        "last month",
        "next month",
        "this year",
        "last year",
        "next year",
        "see below",
        "see above",
        "graph",
        "getty",
        "click here",
        "chart",
        "figure",
        "read more",
        "evening",
        "diagram",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
        "pictured",
        "tonight",
        "this evening",
        "plc",
        "ag",
        "gmbh",
        "kgaa",
        "inc.",
        "ltd.",
        "lp",
        "llp",
        "slp",
        "bvba",
        "nv",
        "sa",
        "snc",
        "scs",
        "corp.",
        "© afp",
        "© bloomberg",
        "prof.",
        "prof",
        `last night's`,
        "last night",
        "plc",
        "ag",
        "here",
        "[sic]",
        "A/S",
        "Co",
        "Co.",
        "Corp",
        "Ltd",
        "My boyfriend",
        "My girlfriend",
        "My husband",
        "My wife",
        "SpA",
        "Text above",
        "Text below",
        "See above",
        "detail below",
        "detailed above",
        "detailed below",
        "explain below",
        "explained above",
        "explained below",
        "our website",
        "this morning",
        "this afternoon",
        "getty images",
        "Readers",
        "Reader",
        "I wrote",
        "one of us",
        "discuss below",
        "discussed above",
        "discussed below",
        "With additional reporting by",
        "see this",
        "last year",
        "disclosure",
        "disclosure:",
        "outlined above",
        "outlined below",
        "–",
        "—",
        "described above",
        "described below",
        "our shores",
        "our country",
        "our people",
        "our government",
        "our culture",
        "our identity",
        "our laws",
        "our election",
        "our president",
        "our leader",
        "our borders",
        "our democracy",
        "our cities",
        "our home",
        "our vote",
        "following pages",
        "noted above",
        "noted below",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
        "Gov",
        "Rep",
        "Sen",
        "Ave",
        "Sr",
        "Jr",
        "Rd",
        "Dr",
        "Prof",
        "Rev",
        "Del",
    ],
    heteronyms: [
        "read",
        "tear",
        "lead",
        "bass",
        "row",
        "bow",
        "close",
        "wind",
        "desert",
        "content",
        "permit",
        "invalid",
        "refuse",
        "project",
        "wound",
        "dove",
        "affect",
        "alternate",
        "attribute",
        "axes",
        "bowed",
        "conduct",
        "console",
        "moderate",
        "perfect",
        "polish",
        "record",
        "separate",
    ],
};

const local = {
    ...common,
    api: {
        ...common.api,
        getArticleAudio: "https://s3-eu-west-1.amazonaws.com/noa-dev-audios/articles/medium/",
        getAudio: "http://localhost:3002/cms/files/audios",
        getImage: "http://localhost:3002/cms/files/images",
        uploadFile: "http://localhost:3002/cms/files",
        url: "http://localhost:3002/cms",
    },
};

const development = {
    ...common,
    api: {
        ...common.api,
        getArticleAudio: "https://s3-eu-west-1.amazonaws.com/noa-dev-audios/articles/medium/",
        getAudio: "https://internal-dev.newsoveraudio.com/cms/files/audios",
        getImage: "https://internal-dev.newsoveraudio.com/cms/files/images",
        uploadFile: "https://internal-dev.newsoveraudio.com/cms/files",
        url: "https://internal-dev.newsoveraudio.com/cms",
    },
};

const production = {
    ...common,
    api: {
        ...common.api,
        getArticleAudio: "https://s3-eu-west-1.amazonaws.com/noa-prod-audios/articles/medium/",
        getAudio: "https://internal.newsoveraudio.com/cms/files/audios",
        getImage: "https://internal.newsoveraudio.com/cms/files/images",
        uploadFile: "https://internal.newsoveraudio.com/cms/files",
        url: "https://internal.newsoveraudio.com/cms",
    },
};

const config = {
    development,
    local,
    production,
    test: common,
};

const configToExport = config[process.env.REACT_APP_ENV || "development"] as typeof production;

export default configToExport;
