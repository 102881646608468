import { styled } from "styled-components";

export const TextButton = styled.button<{ isRed?: boolean }>`
    font-family: Poppins, sans-serif;
    font-size: 14px;
    color: ${(p) => (p.isRed ? "#DE350B" : "#007fbc")};
    font-weight: 500;
    background: none;
    border: none;
    cursor: pointer;
    padding: 2px 4px;

    &:focus {
        background: none !important;
    }
    &:active {
        background: none;
    }

    &:hover {
        filter: brightness(0.8);
    }
`;
