import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ButtonFixed, Col, Icon, Loading, Row, Table, Td, Th } from "styleguide";

import { fetchNewspapers } from "../actions/newspaper";
import config from "../config";
import { IState } from "../reducers";
import * as errors from "../types/errors";
import INewspaper from "../types/newspaper";
import countries from "../utils/countries";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    newspapers: INewspaper[];
    error?: errors.HttpError;
    isLoading: boolean;
}

interface IPropsFromDispatch {
    fetchNewspapers: typeof fetchNewspapers;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public renderListNewspapers = () => {
        const { history } = this.props;
        const newspapers = this.props.newspapers.map((n) => {
            const country = countries.find((c) => c.id === n.geographyID);
            n.country = country && country.name;
            return n;
        });

        return (
            <>
                <Col s={12}>
                    <Table title="Newspapers">
                        <thead>
                            <tr>
                                <Th>Id</Th>
                                <Th>Name</Th>
                                <Th>Geography</Th>
                                <Th align="right">Actions</Th>
                            </tr>
                        </thead>
                        <tbody>
                            {newspapers.map((newspaper) => (
                                <tr key={newspaper.newspaperID}>
                                    <Td>{newspaper.newspaperID}</Td>
                                    <Td>{newspaper.newspaperName}</Td>
                                    <Td>{newspaper.country}</Td>
                                    <Td align="right">
                                        <a href={`/newspapers/${newspaper.newspaperID}`}>
                                            <Icon icon="edit" />
                                        </a>
                                    </Td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.newspaper}+`)} />
                </Col>
                <Row>
                    <Col s={12} />
                </Row>
            </>
        );
    };

    public componentDidMount() {
        this.props.fetchNewspapers();
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Newspapers</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderListNewspapers()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        error: state.newspaper.error,
        isLoading: state.newspaper.isLoading,
        newspapers: state.newspaper.newspapers,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            fetchNewspapers,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
