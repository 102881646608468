import { FC, RefObject, useEffect } from "react";
import { IDiffExtendedItemWithGroup, IQaPron } from "./types";
interface IProps {
    sttEngine: string;
    transcriptId: string;
    diff: IDiffExtendedItemWithGroup[];
    prons: IQaPron[];
    getActiveItem: () => IQaPron | undefined;
    qaTextWrapperRef: RefObject<HTMLDivElement>;
}

const activeQaPronStyles = {
    color: "#ffffff",
    background: "#202020",
    fontWeight: "500",
};

const ArticlePronunciationsQaText: FC<IProps> = ({ sttEngine, transcriptId, diff, prons, getActiveItem, qaTextWrapperRef }) => {
    const activeItem = getActiveItem();

    useEffect(() => {
        const wrapperItem = qaTextWrapperRef.current;
        const item = wrapperItem?.querySelector(".QA_PRON__active");
        if (wrapperItem && item) {
            wrapperItem.scrollTo({ top: (item as any).offsetTop + 20 - (wrapperItem.getBoundingClientRect().height - item.getBoundingClientRect().height) / 2, behavior: "smooth" });
        }
    }, [activeItem]);

    return (
        <p
            style={{
                color: "#000000",
                fontSize: "16px",
                lineHeight: "24px",
                background: "#fff",
                boxShadow: "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)",
                padding: "30px",
                paddingTop: "20px",
                fontFamily: "Poppins",
                maxWidth: "700px",
                margin: "0",
                borderRadius: "5px",
                width: "100%",
            }}
        >
            <span style={{ fontSize: "12px", color: "#727984" }}>
                <span style={{ fontWeight: 700, textTransform: "uppercase", marginRight: "12px" }}>{sttEngine}</span>
                <span style={{ fontWeight: 500 }}>{transcriptId}</span>
            </span>
            <br />
            <br />
            {diff.map((item) => {
                if (item.type === "INSERTION") return null;
                if (item.type === "NOISE") {
                    if (item.lineBreakDiffItem) {
                        return (
                            <>
                                <br />
                                <br />
                            </>
                        );
                    }

                    return null;
                }
                const isItemActive = !!activeItem && activeItem.diffItems.some((di) => di.id === item.id);

                const isItemPron = prons.some((p) => p.diffItems.some((di) => di.id === item.id));

                if (isItemPron) {
                    return (
                        <span key={item.id} className={`${isItemActive ? "QA_PRON__active" : ""}`} style={{ fontWeight: "700", ...(isItemActive ? activeQaPronStyles : {}) }}>
                            {" "}
                            {item.reference}{" "}
                        </span>
                    );
                }

                return <span key={item.id}> {item.reference}</span>;
            })}
        </p>
    );
};

export default ArticlePronunciationsQaText;
