import _ from "lodash";
import IPronunciation from "../types/pronunciation";
import IUser from "../types/user";
import config from "src/config";

export const canVerifyPronuncation = (p: IPronunciation, user?: IUser): boolean => {
    if (!user) {
        return false;
    }

    const lastEditor = p.editedBy ? p.editedBy : p.createdBy;

    if (lastEditor && lastEditor === user.userId) {
        return false;
    }

    return true;
};

export function getPronunciationsFromText(
    text: string,
    pronunciations?: IPronunciation[],
    transformToLowercase?: boolean,
    includeAllMatches?: boolean,
    transformPronWord?: (word: string) => string,
    customRegexFn?: (pronName: string, plainRegex?: boolean) => RegExp | string,
) {
    if (!pronunciations) {
        return [];
    }

    const pronunciationsToHighlight: Array<{ pron: IPronunciation; word: string }> = [];

    // First, do an inaccurate but cheap filter of prons to reduce the list size
    const pronsContained = pronunciations.filter((pron) => {
        let pronName = pron.pronunciationName;

        if (transformToLowercase) pronName = pronName.toLowerCase();

        if (transformPronWord) {
            pronName = transformPronWord(pronName);
        }

        if (customRegexFn) {
            return new RegExp(
                pronName
                    .split(" ")
                    .filter((promName) => !!promName.trim().length)
                    .map((promName) => customRegexFn(promName, true))
                    .join(" "),
            ).test(text);
        }

        return text.indexOf(pronName) !== -1;
    });

    // Then do the expensive matching
    pronsContained.forEach((p) => {
        let pronName = p.pronunciationName;

        if (transformToLowercase) pronName = pronName.toLowerCase();
        if (transformPronWord) pronName = transformPronWord(pronName);

        const regex = customRegexFn ? customRegexFn(pronName) : new RegExp("(?<=\\s|^|[^\\w])" + _.escapeRegExp(pronName) + "(?=\\s|$|[^\\w])", "g");
        const match = text.match(regex);

        if (Array.isArray(match) && match.length > 0) {
            const matches = includeAllMatches ? match : [match[0]];
            matches.forEach((m) => {
                if (!!m.trim().length) {
                    pronunciationsToHighlight.push({ pron: p, word: m });
                }
            });
        }
    });

    return pronunciationsToHighlight;
}

export function getWarnWordFromText(text: string) {
    const warnWordsContained = config.warnKeywords.filter((word: string) => text.indexOf(word) !== -1);

    const wordsToInsert: string[] = [];

    warnWordsContained.forEach((warnWord: string) => {
        const regex = new RegExp("\\b" + _.escapeRegExp(warnWord.toLowerCase()) + "\\b", "gi");
        const match = text.match(regex);

        if (Array.isArray(match) && match.length > 0) {
            const word = match[0];
            wordsToInsert.push(word);
        }
    });

    return wordsToInsert;
}
