import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ButtonFixed, InputSelect, Loading } from "styleguide";
import ReactDatePicker from "react-datepicker";

import { clearArticleState, fetchArticles, togglePublishArticle } from "../actions/article";
import { fetchSections } from "../actions/section";
import { fetchJournalists } from "../actions/journalist";
import { fetchNewspapers } from "../actions/newspaper";
import { fetchPlaylists } from "../actions/playlist";
import { clearSearch, doSearch } from "../actions/search";
import { fetchUsers } from "../actions/user";
import ArticleListForEditors from "../components/ArticleListForEditors";
import ArticleListForNarrators from "../components/ArticleListForNarrators";
import ArticleListForNewspapers from "../components/ArticleListForNewspapers";
import DynamicSearchForm, { IOption } from "../components/DynamicSearchForm";
import config from "../config";
import { IState } from "../reducers";
import { goToArticleEdit, goToArticleView, goToAudio, requestArticlesDynamicSearched } from "../services/article";
import { requestAllJournalists } from "../services/journalist";
import { createDefaultSearch } from "../services/search";
import { requestNarrators } from "../services/user";
import IArticle from "../types/article";
import * as errors from "../types/errors";
import IJournalist from "../types/journalist";
import INewspaper from "../types/newspaper";
import IPlaylist from "../types/playlist";
import ISearch from "../types/search";
import SearchOptions from "../types/search-options";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";
// import { countAllWordsInArticleScript } from "../utils/count-words";
import debounce from "../utils/debounce";
import { Helmet } from "react-helmet";
import { IVoiceRequirement } from "src/types/voice-requirements";
import { fetchVoiceRequirements } from "src/actions/voicerequirements";
import ISection from "src/types/section";
import SectionTypes from "src/types/section-types";
import InputText from "src/components/InputText";

interface IPropsFromState {
    articles: IArticle[];
    error?: errors.HttpError;
    isLoading: boolean;
    user: IUser;
    offset: number;
    search: ISearch;
    narrators: IUser[];
    newspapers: INewspaper[];
    categories: ISection[];
    playlists: IPlaylist[];
    journalists: IJournalist[];
    voiceRequirements: IVoiceRequirement[];
}

interface IPropsFromDispatch {
    fetchArticles: typeof fetchArticles;
    togglePublishArticle: typeof togglePublishArticle;
    clearArticleState: typeof clearArticleState;
    doSearch: typeof doSearch;
    clearSearch: typeof clearSearch;
    fetchUsers: typeof fetchUsers;
    fetchNewspapers: typeof fetchNewspapers;
    fetchSections: typeof fetchSections;
    fetchJournalists: typeof fetchJournalists;
    fetchPlaylists: typeof fetchPlaylists;
    fetchVoiceRequirements: typeof fetchVoiceRequirements;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public togglePublishArticle = (article: IArticle) => {
        this.props.togglePublishArticle(article);
    };

    public fetchArticlesFilter = (articles: IArticle[]) => {
        const { search } = this.props;

        if (search && search.option === SearchOptions.VOICE_REQUIREMENTS) {
            return articles.filter((a) => a.voiceRequirements?.some((vr) => vr.id === Number(search.text)));
        }
        return articles;
    };

    public submitForm = (search: ISearch | any) => {
        this.props.doSearch(search);

        this.props.fetchArticles(requestArticlesDynamicSearched(search), true, this.fetchArticlesFilter);
    };

    public renderSearch = () => {
        const { categories, journalists, narrators, newspapers, playlists, search, user, voiceRequirements } = this.props;

        if (!user) {
            return null;
        }

        if (user.userRoleIdList === UserRoles.NARRATOR) {
            const searchOptions = [
                { value: SearchOptions.TITLE, text: "Title" },
                { value: SearchOptions.ID, text: "Id" },
            ];

            const searchProps = {
                initialValues: { option: SearchOptions.TITLE },
                onSubmit: this.submitForm,
                options: searchOptions,
                search,
            };

            return <DynamicSearchForm {...searchProps} />;
        }

        if (user.userRoleIdList === UserRoles.NEWSPAPER_ADMIN) {
            const searchOptions = [{ value: SearchOptions.TITLE, text: "Title" }];

            const searchProps = {
                initialValues: { option: SearchOptions.TITLE },
                onSubmit: this.submitForm,
                options: searchOptions,
                search,
            };

            return <DynamicSearchForm {...searchProps} />;
        }

        if (user.userRoleIdList === UserRoles.EDITOR || user.userRoleIdList === UserRoles.SUPER_USER) {
            const narratorsParsed = [
                { value: "", text: "UNASSIGNED" },
                ...narrators.map((n) => ({
                    text: `${n.userFirstName} ${n.userLastName}`,
                    value: n.userId.toString(),
                })),
            ];

            const publishedNewspapersParsed = newspapers
                .filter((np) => !!np.newspaperIsPublished)
                .map((n) => ({
                    text: n.newspaperName,
                    value: n.newspaperID.toString(),
                }));
            const unpublishedNewspapersParsed = newspapers
                .filter((np) => !np.newspaperIsPublished)
                .map((n) => ({
                    text: n.newspaperName,
                    value: n.newspaperID.toString(),
                }));

            const categoriesParsed = categories.map((c) => ({
                text: c.sectionName,
                value: c.sectionId.toString(),
            }));

            const categoriesParsedForSubcategories = categories
                .filter((c) => !c.parentId)
                .map((c) => ({
                    text: c.sectionName,
                    value: c.sectionId.toString(),
                }));

            const playlistsParsed = playlists.map((p) => ({
                text: `${p.playlistName} [${Array.isArray(p.sections) && p.sections.length > 0 && p.sections[0].sectionName}]`,
                value: p.playlistId.toString(),
            }));

            const journalistsParsed = journalists.map((j) => ({
                text: j.journalistName,
                value: j.journalistId.toString(),
            }));

            const voiceRequirementsParsed = voiceRequirements.map((vr) => ({
                text: vr.name,
                value: vr.id.toString(),
            }));

            const publishedUnpublishedParsed = [
                {
                    text: "Published",
                    value: "1",
                },
                {
                    text: "Unpublished",
                    value: "0",
                },
            ];

            const subcategoryOptionsParsed = (selectedCategory: string) => {
                return categories
                    .filter((c) => c.parentId === Number(selectedCategory))
                    .map((c) => ({
                        text: c.sectionName,
                        value: c.sectionId.toString(),
                    }));
            };

            const wordCountParsed = [
                {
                    text: "Greater Than",
                    value: "1",
                },
                {
                    text: "Less Than",
                    value: "2",
                },
                {
                    text: "Between",
                    value: "3",
                },
            ];

            const articleUnitsParsed = [
                {
                    text: "Greater Than",
                    value: "1",
                },
                {
                    text: "Less Than",
                    value: "2",
                },
                {
                    text: "Between",
                    value: "3",
                },
            ];

            const options: IOption[] = [
                {
                    text: "Article Units",
                    value: SearchOptions.ARTICLE_UNITS,
                    values: articleUnitsParsed,
                    customComponents: (change, search) => {
                        if (search.text === "3") {
                            return [
                                <InputText type="text" name="articleUnitsFrom" change={change} placeholder="From" />,
                                <InputText type="text" name="articleUnitsTo" change={change} placeholder="To" />,
                            ];
                        } else if (search.text === "1" || search.text === "2") {
                            return [<InputText type="text" name="articleUnits" change={change} placeholder="Article Units" />];
                        } else return [];
                    },
                },
                {
                    text: "Category",
                    value: SearchOptions.CATEGORY,
                    values: categoriesParsed,
                },
                {
                    text: "Date published",
                    value: SearchOptions.DATE_PUBLISHED,
                    skipValues: true,
                    customComponents: (change, search) => [
                        <ReactDatePicker
                            selectsRange
                            placeholderText="Select a date"
                            onChange={(date) => change("datePublished", date)}
                            selected={search?.datePublished?.[0] || null}
                            startDate={search?.datePublished?.[0] || null}
                            endDate={search?.datePublished?.[1] || null}
                            maxDate={new Date()}
                        />,
                    ],
                },
                { value: SearchOptions.ID, text: "Id" },
                {
                    text: "Journalist",
                    value: SearchOptions.JOURNALIST,
                    values: journalistsParsed,
                },
                {
                    text: "Narrator",
                    value: SearchOptions.NARRATOR,
                    values: narratorsParsed,
                },

                {
                    text: "Newspaper",
                    value: SearchOptions.NEWSPAPER,
                    values: publishedNewspapersParsed,
                },

                {
                    text: "Newspaper (Unpublished)",
                    value: SearchOptions.NEWSPAPER_UNPUBLISHED,
                    values: unpublishedNewspapersParsed,
                },

                {
                    text: "Playlist",
                    value: SearchOptions.PLAYLIST,
                    values: playlistsParsed,
                },
                {
                    text: "Published/Unpublished",
                    value: SearchOptions.PUBLISHED_UNPUBLISHED,
                    values: publishedUnpublishedParsed,
                },
                {
                    text: "Subcategory",
                    value: SearchOptions.SUBCATEGORY,
                    values: categoriesParsedForSubcategories,
                    placeholder: "Select Category",
                    customComponents: (change, search) => [
                        <InputSelect placeholder="Select Subcategory" rows={subcategoryOptionsParsed(search.text ? search.text.toString() : "")} name="subcategory" change={change} />,
                    ],
                },

                { value: SearchOptions.TITLE, text: "Title" },
                {
                    text: "Voice Requirement",
                    value: SearchOptions.VOICE_REQUIREMENTS,
                    values: voiceRequirementsParsed,
                },

                {
                    text: "Word Count",
                    value: SearchOptions.WORD_COUNT,
                    values: wordCountParsed,
                    customComponents: (change, search) => {
                        if (search.text === "3") {
                            return [<InputText type="text" name="wordCountFrom" change={change} placeholder="From" />, <InputText type="text" name="wordCountTo" change={change} placeholder="To" />];
                        } else if (search.text === "1" || search.text === "2") {
                            return [<InputText type="text" name="wordCount" change={change} placeholder="Word Count" />];
                        } else return [];
                    },
                },
            ];

            const props = {
                initialValues: { option: SearchOptions.TITLE },
                onSubmit: this.submitForm,
                options,
                search,
            };

            return <DynamicSearchForm {...props} />;
        }

        return null;
    };

    public renderListArticlesForNewspaper = () => {
        return <ArticleListForNewspapers title="Articles" articles={this.props.articles} goToAudio={goToAudio} togglePublishArticle={this.togglePublishArticle} />;
    };

    public renderListArticlesForNarrator = () => {
        // const articles = this.props.articles.map((a) => {
        //     a.articleWordCount = countAllWordsInArticleScript(a);
        //     return a;
        // });

        return (
            <ArticleListForNarrators
                title="Articles"
                articles={this.props.articles}
                goToArticleEdit={goToArticleEdit}
                goToArticleView={goToArticleView}
                goToAudio={goToAudio}
                togglePublishArticle={this.togglePublishArticle}
            />
        );
    };

    public renderListArticlesEditor = () => {
        const { history } = this.props;
        // let articles = this.props.articles.map((a) => {
        //     a.articleWordCount = countAllWordsInArticleScript(a);
        //     return a;
        // });

        return (
            <>
                <ArticleListForEditors
                    title="Articles"
                    articles={this.props.articles}
                    goToArticleEdit={goToArticleEdit}
                    goToArticleView={goToArticleView}
                    goToAudio={goToAudio}
                    togglePublishArticle={this.togglePublishArticle}
                />
                <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.article}+`)} />
            </>
        );
    };

    public renderListArticles = () => {
        const { user } = this.props;

        if (!user) {
            return null;
        }

        return (
            <>
                {user.userRoleIdList === UserRoles.EDITOR && this.renderListArticlesEditor()}
                {user.userRoleIdList === UserRoles.SUPER_USER && this.renderListArticlesEditor()}
                {user.userRoleIdList === UserRoles.NEWSPAPER_ADMIN && this.renderListArticlesForNewspaper()}
                {user.userRoleIdList === UserRoles.NARRATOR && this.renderListArticlesForNarrator()}
            </>
        );
    };

    public componentWillUnmount() {
        this.props.clearArticleState();
        this.props.clearSearch();
        window.onscroll = null;
    }

    public componentDidMount() {
        const { user } = this.props;

        window.onscroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                debounce(() => {
                    this.props.fetchArticles(requestArticlesDynamicSearched(this.props.search), false);
                });
            }
        };

        this.props.fetchArticles(requestArticlesDynamicSearched(createDefaultSearch()), true);

        if (user.userRoleIdList === UserRoles.EDITOR || user.userRoleIdList === UserRoles.SUPER_USER) {
            this.props.fetchUsers(requestNarrators(), UserRoles.NARRATOR);
            this.props.fetchNewspapers();
            this.props.fetchSections({
                filter: [{ name: "sectionType", value: SectionTypes.Regular }],
            });
            this.props.fetchVoiceRequirements();
            this.props.fetchPlaylists({ include: ["sections"] });
            this.props.fetchJournalists(requestAllJournalists(), true);
        }
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Articles</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderSearch()}
                {this.renderListArticles()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { DynamicSearchForm: searchForm },
    } = state;

    const searchValues = searchForm && searchForm.values;

    return {
        articles: state.article.articles,
        categories: state.section.sections,
        error: state.article.error,
        isLoading: state.article.isLoading,
        journalists: state.journalist.journalists,
        narrators: state.user.narrators,
        newspapers: state.newspaper.newspapers,
        offset: state.article.offset,
        playlists: state.playlist.playlists,
        voiceRequirements: state.voiceRequirements.voiceRequirements,
        search: searchValues,
        user: state.auth.user as IUser,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearArticleState,
            clearSearch,
            doSearch,
            fetchArticles,
            fetchSections,
            fetchJournalists,
            fetchNewspapers,
            fetchPlaylists,
            fetchUsers,
            togglePublishArticle,
            fetchVoiceRequirements,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
