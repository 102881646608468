import styled from "styled-components";

export const PronunciationButtonPopup = styled.div<{ position: { x: number; y: number } }>`
    position: fixed;
    top: ${(p) => p.position.y - 38}px;
    left: ${(p) => p.position.x}px;

    z-index: 9999;

    padding: 10px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.51), 0px 18px 28px 0px rgba(9, 30, 66, 0.25);

    display: flex;
    align-items: center;
    justify-content: center;

    button {
        padding: 0 !important;
        margin: 0px 6px !important;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 15px;
        color: #007fbc !important;
        font-weight: 600;

        cursor: pointer;

        &:disabled {
            color: grey !important;
        }

        &,
        &:hover,
        &:active {
            outline: none !important;
            border: none !important;
            background: none !important;
        }
    }
`;

export const TextPopupWrapper = styled.div`
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.51), 0px 18px 28px 0px rgba(9, 30, 66, 0.25);

    display: flex;
    align-items: center;
    justify-content: center;

    button {
        padding: 0 !important;
        margin: 0px 6px !important;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 15px;
        color: #007fbc !important;
        font-weight: 600;

        cursor: pointer;

        &,
        &:hover,
        &:active {
            outline: none !important;
            border: none !important;
            background: none !important;
        }
    }
`;

export const PreviewText = styled.p<{ isPageNonScrollable: boolean }>`
    font-size: ${(p) => (!p.isPageNonScrollable ? "18px" : "16px")};
    padding-right: 10px;
    color: #000000;

    span {
        font-size: inherit;
        font-family: inherit;
        font-weight: inherit;
        cursor: pointer;
    }
`;

export const PreviewScreenTextWrapper = styled.div<{ isPageNonScrollable: boolean }>`
    width: 100%;
    padding: 0px 20px;

    ${(p) => (!p.isPageNonScrollable ? "max-width: 760px;overflow-y: scroll;height: 100vh;padding-top: 65px;" : "max-width: 1200px;")}
`;

export const PreviewScreenSplit = styled.div<{ isPageNonScrollable: boolean }>`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    ${(p) => (!p.isPageNonScrollable ? "margin-top: -65px;overflow-y: hidden;" : "")}
`;

export const PreviewScreenChecklist = styled.div`
    width: 400px;
    min-width: 400px;
    max-width: 400px;
    padding-left: 15px;
    padding-right: 10px;

    // **
    height: 100vh;
    padding-top: 65px;
    overflow-y: auto;

    & > p {
        margin: 0;
        margin-bottom: 5px;
        margin-left: 53px;
        margin-top: 20px;

        font-size: 14px;
        font-weight: 500;
        color: #6b778c;
    }

    & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        button {
            padding: 0 !important;
            margin: 0px;
            margin-left: 10px;

            &:first-child {
                margin-left: 0px;
                margin-right: 15px;
            }

            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 14px;
            color: #007fbc !important;
            font-weight: 600;

            cursor: pointer;

            &:disabled {
                color: grey !important;
            }

            &,
            &:hover,
            &:active {
                outline: none !important;
                border: none !important;
                background: none !important;
            }
        }
    }

    & > h2 {
        font-size: 16px;
        font-weight: 600;
        color: #505f79;
        margin-bottom: 30px;
    }
`;
